import {
  selectFieldTheme,
  setupDetailCartFormStyle,
  tableHeadTheme,
} from '_assets/projectSpecific/jss/components/Forms/setupDetailCartFormStyle';
import {FormCheckbox, FormDateField, FormSelectField} from '_components/global/Form/FormFields';
import GridItem from '_components/global/Grid/GridItem';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const SetupDetailCartForm = ({
  handleSubmit,
  rows2,
  key,
  totalCustomerPrice,
  classes,
  owner,
  shipmentAddress,
  deliveryOptions,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className={cx(classes.tableWrapper, classes.marginBottom)}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <MuiThemeProvider theme={tableHeadTheme}>
            <SimpleTableHead rows={rows2} />
          </MuiThemeProvider>
          <TableBody>
            <TableRow hover tabIndex={-1} key={key}>
              <TableCell classes={{root: classes.cell}}>
                {shipmentAddress ? (
                  <div>
                    <p>
                      {shipmentAddress && shipmentAddress.street}{' '}
                      {shipmentAddress && shipmentAddress.house_no}
                    </p>
                    <p>{shipmentAddress && shipmentAddress.city}</p>
                    <p>{shipmentAddress && shipmentAddress.post_code}</p>
                  </div>
                ) : (
                  <div>
                    <p>
                      {owner.customer && owner.customer.address_1}
                      {owner.customer && owner.customer.address_2}
                      {owner.customer && owner.customer.address_3}
                    </p>
                    <p>{owner.customer && owner.customer.city}</p>
                    <p>{owner.customer && owner.customer.post_code}</p>
                  </div>
                )}
              </TableCell>
              <TableCell classes={{root: classes.cell}}>
                <GridItem xs={6}>
                  <div className={classes.dateField}>
                    <Field
                      name="delivery_dates_all"
                      dateLabel={<Trans>Delivery date</Trans>}
                      id="delivery_dates_all"
                      component={FormDateField}
                    />
                  </div>
                </GridItem>
                <GridItem xs={6}>
                  <MuiThemeProvider theme={selectFieldTheme}>
                    <Field
                      name="delivery_options_all"
                      label={<Trans>Delivery option</Trans>}
                      id="delivery_options_all"
                      type="select"
                      component={FormSelectField}
                      options={deliveryOptions}
                    />
                  </MuiThemeProvider>
                </GridItem>
              </TableCell>
              <TableCell classes={{root: classes.cell}}>
                <Field
                  name="use_coco_shipment_address"
                  component={FormCheckbox}
                  label={<Trans>Customer address</Trans>}
                  helpText="Allowed for CoCo customers only"
                  disabled={!owner.customer.is_coco}
                />
              </TableCell>
              <TableCell classes={{root: classes.cell}}>
                <div className={classes.totalPrice}>
                  {totalCustomerPrice ? totalCustomerPrice : <strong>&mdash;</strong>}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </form>
  );
};

export default compose(
  withStyles(setupDetailCartFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(SetupDetailCartForm);
