import commonTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/commonTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Trans} from '@lingui/macro';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';

class DiscountLimitCodesTableToolbar extends Component {
  render() {
    const {classes, handleEdit, handleSave, inputLocked} = this.props;

    return (
      <Toolbar className={cx(classes.root)}>
        {inputLocked ? (
          <Tooltip title={<Trans>Edit discount group</Trans>}>
            <Button aria-label="EditDiscountGroup" onClick={handleEdit}>
              <Trans>Edit</Trans>
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title={<Trans>Save discount group</Trans>}>
            <Button aria-label="SaveDiscountGroup" onClick={handleSave} color="primary">
              <SaveIcon /> <Trans>Save</Trans>
            </Button>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
}

DiscountLimitCodesTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(commonTableToolbarStyle)(DiscountLimitCodesTableToolbar);
