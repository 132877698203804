import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_FILTERS_STATE} from '_actions/global/types';
import {
  FETCH_CUSTOMERS_DELIVERY_OPTIONS_REQUEST,
  FETCH_CUSTOMERS_DELIVERY_OPTIONS_SUCCESS,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_REQUEST,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_SUCCESS,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_REQUEST,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_SUCCESS,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/global/functions';
import {SubmissionError} from 'redux-form';

export function getCustomers(offset = 0, limit = 20, orderBy = null, CustomerNameNo = null) {
  return function (dispatch) {
    const customerFilters = {
      customersListFilter: {
        orderBy,
        CustomerNameNo,
      },
    };
    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: customerFilters,
    });
    dispatch({type: FETCH_CUSTOMERS_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.customers, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
      q: CustomerNameNo ? CustomerNameNo : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_CUSTOMERS_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function getCustomersDeliveryTerms(
  offset = 0,
  limit = 20,
  orderBy = null,
  CustomerNameNo = null,
  deliveryOptionId = null
) {
  return function (dispatch) {
    const customerDeliveryTermsFilters = {
      customersDeliveryOptionsListFilter: {
        orderBy,
        CustomerNameNo,
        deliveryOptionId,
      },
    };
    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: customerDeliveryTermsFilters,
    });
    dispatch({type: FETCH_CUSTOMERS_DELIVERY_OPTIONS_REQUEST});
    if (deliveryOptionId === 'all') {
      deliveryOptionId = null;
    } else if (deliveryOptionId === 'none') {
      deliveryOptionId = 'null';
    }
    return HttpClient.get(API_ENDPOINT_MAP.customers, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
      q: CustomerNameNo ? CustomerNameNo : null,
      delivery_option__id: deliveryOptionId ? deliveryOptionId : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_CUSTOMERS_DELIVERY_OPTIONS_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function getCustomersInDiscountGroups(
  offset = 0,
  limit = 20,
  orderBy = null,
  discountGroupId = null
) {
  return function (dispatch) {
    const customersInDiscountGroupsFilters = {
      customersInDiscountGroupsListFilter: {
        orderBy,
        discountGroupId,
      },
    };
    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: customersInDiscountGroupsFilters,
    });
    dispatch({type: FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.customers, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
      discount_group__id: discountGroupId ? discountGroupId : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function getCustomersInDiscountGroupsInsert(discountGroupId = null) {
  return function (dispatch) {
    dispatch({type: FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.customers, {
      discount_group__id: discountGroupId,
    })
      .then((response) => {
        dispatch({
          type: FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function changeCustomersInDiscountGroups(customerIds, discountGroupIds, isDelete) {
  return function () {
    return HttpClient.post(API_ENDPOINT_MAP.customersDiscountGroupMembership, {
      customer_ids: customerIds,
      discount_group_ids: discountGroupIds,
      is_delete: isDelete,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function getCustomer(customer_id) {
  return function (dispatch) {
    dispatch({type: FETCH_CUSTOMER_REQUEST});
    return HttpClient.get(`${API_ENDPOINT_MAP.customers}${customer_id}/`)
      .then((response) => {
        dispatch({
          type: FETCH_CUSTOMER_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function deleteCustomer(customer_id) {
  return function (dispatch) {
    return HttpClient.del(`${API_ENDPOINT_MAP.customers}${customer_id}/`)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function createCustomer(formParams) {
  return function (dispatch) {
    return HttpClient.post(API_ENDPOINT_MAP.customers, {...formParams})
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function editCustomer(id, formParams) {
  return function (dispatch) {
    return HttpClient.patch(`${API_ENDPOINT_MAP.customers}${id}/`, {
      ...formParams,
    })
      .then((response) => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function impersonateCustomer(id) {
  return function () {
    return HttpClient.put(`${API_ENDPOINT_MAP.customers}${id}/impersonate/`)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}
