import React from 'react';
import {TextField} from '@material-ui/core';

const NumberField = (props) => {
  const {
    type,
    id,
    label,
    help_text,
    input,
    meta: {touched, invalid, error},
    ...rest
  } = props;
  return (
    <TextField
      fullWidth
      type="number"
      label={label}
      inputProps={{value: input.value, onChange: input.onChange}}
      error={touched && invalid}
      helperText={touched && error ? error : help_text}
      {...input}
      {...rest}
    />
  );
};

export default NumberField;
