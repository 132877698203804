import {ENVIRONMENT, FORCED_API_SERVER} from '_config/configConstants';
import store from 'App/store';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';

// This is the API. The backend root URL can be set from here.

const resolveApiServer = () => {
  const byLocationApiServer = window.location.protocol + '//' + window.location.hostname;
  if (ENVIRONMENT === 'production') {
    return window._env_.API_URL ? window._env_.API_URL : byLocationApiServer;
  }
  return FORCED_API_SERVER ? FORCED_API_SERVER : byLocationApiServer;
};
const API_SERVER = resolveApiServer();

//Create a Http Client using Axios. Further modifications in this layer can be done later like Authorization.

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['uuid'] = uuidv4();

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    config.headers.Authorization = 'token ' + token;
  }
  //
  const state = store.getState();
  config.headers['Accept-Language'] = state.GlobalSettings.currentLanguage;

  return config;
});

const post = (
  url,
  data = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  return axios.post(`${API_SERVER}${url}`, data, config);
};

const get = (
  url,
  params = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  config.params = params;
  return axios.get(`${API_SERVER}${url}`, config);
};

const put = (url, data = {}, config = {}) => {
  return axios.put(`${API_SERVER}${url}`, data, config);
};

const patch = (url, data = {}, config = {}) => {
  return axios.patch(`${API_SERVER}${url}`, data, config);
};

//Cannot contain a delete method - Cause delete is a keyword.

const del = (url, config = {}) => {
  return axios.delete(`${API_SERVER}${url}`, config);
};

//Encapsulating in a JSON object

const HttpClient = {
  post,
  get,
  put,
  del,
  patch,
};

export {HttpClient};
