import {
  blueColor,
  dangerColor,
  grayColor,
  successColor,
  whiteColor,
} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const setupDetailCartFormStyle = (theme) => ({
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
  marginBottom: {
    marginBottom: '60px',
  },
  totalPrice: {
    backgroundColor: blueColor[0],
    color: whiteColor,
    fontWeight: 'bold',
    height: '40px',
    width: '80%',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateField: {
    margin: '-5px 10px 0 -10px',
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  noteCell: {
    padding: '0px 5px',
    whiteSpace: 'normal',
    maxWidth: 170,
    minWidth: 80,
  },
  successTextColor: {
    color: successColor[0],
    fontWeight: 500,
  },
  dangerTextColor: {
    color: dangerColor[1],
    fontWeight: 500,
  },
});

export const selectFieldTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormControl: {
      root: {
        margin: '-10px 0px 20px -10px !important',
        paddingLeft: '0px',
      },
    },
  },
});

export const tableHeadTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: `${grayColor[0]} !important`,
      },
    },
  },
});
