import {cardTitle, grayColor} from '_assets/global/jss/styleHelpers';

export const companySettingsPageStyle = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  tabBar: {
    marginTop: '30px',
  },
  step: {
    background: grayColor[13],
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.3)',
    padding: '0px 20px 0px 20px',
  },
  step2: {
    padding: '50px 50px 40px 50px',
    background: grayColor[13],
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.3)',
  },
  formActions: {
    padding: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'center',
  },
  center: {
    justifyContent: 'center',
    display: 'flex',
  },
});
