import {
  dateFieldTheme,
  filterCartFormStyle,
} from '_assets/projectSpecific/jss/components/Forms/filterCartFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormDateField, FormSelectField, FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {STATUS_2} from '_config/configConstants';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Replay, Search} from '@material-ui/icons';

const FilterCartForm = ({classes, handleSubmit, form, handleReset, reset}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.flex}>
      <GridContainer className={classes.cartFormFields}>
        <GridItem xs={12} md={3} xl={3}>
          <Field
            name="customerNo"
            reduxName="customerNo"
            label={<Trans>Customer ID</Trans>}
            id="customerNo"
            component={FormTextField}
            formName={form}
            clearButton={true}
          />
        </GridItem>
        <MuiThemeProvider theme={dateFieldTheme}>
          <GridItem xs={12} md={3} xl={3}>
            <Field
              name="from"
              dateLabel={<Trans>From date</Trans>}
              id="from"
              component={FormDateField}
              clearButton={true}
              reduxName="from"
              formName={form}
            />
          </GridItem>
          <GridItem xs={12} md={3} xl={3}>
            <Field
              name="to"
              dateLabel={<Trans>To date</Trans>}
              id="to"
              component={FormDateField}
              reduxName="to"
              formName={form}
              clearButton={true}
            />
          </GridItem>
        </MuiThemeProvider>
        <GridItem xs={12} md={3} xl={3}>
          <Field
            name="status"
            label={<Trans>Status</Trans>}
            id="status"
            type="select"
            component={FormSelectField}
            options={STATUS_2}
            clearButton={true}
            reduxName="status"
            formName={form}
          />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.cartButton}>
        <Tooltip title={<Trans>Find</Trans>}>
          <Button color="primary" type="submit">
            <Search />
          </Button>
        </Tooltip>
        <Tooltip title={<Trans>Reset filter</Trans>}>
          <Button
            onClick={() => {
              reset();
              handleReset();
            }}
            color="primary"
          >
            <Replay />
          </Button>
        </Tooltip>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(filterCartFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(FilterCartForm);
