import {FormCustomSelectField} from '_components/global/Form/FormFields';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';

const CustomersDeliveryOptionsForm = ({
  classes,
  handleSubmit,
  pristine,
  submitting,
  options,
  form,
}) => {
  return (
    <form>
      <Field
        name="term"
        label={<Trans>Add delivery terms</Trans>}
        type="select"
        component={FormCustomSelectField}
        options={options}
      />
    </form>
  );
};

export default compose(
  reduxForm({
    enableReinitialize: true,
  })
)(CustomersDeliveryOptionsForm);
