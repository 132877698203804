import {
  CustomChipSelectFieldStyle,
  SelectField,
} from '_assets/global/jss/components/Fields/CustomChipSelectFieldStyle';
import ClearButton from '_components/global/Buttons/ClearButton';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {MuiThemeProvider} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';

const CustomChipMultipleSelect = ({
  required,
  htmlFor,
  label,
  selectedValues,
  handleSelect,
  MenuProps,
  classes,
  options,
  id,
  onChange,
  helpText,
  typeHeight,
  clearButton,
  formName,
  reduxName,
  dispatch,
}) => {
  return (
    <FormControl className={classes.formControl} required={required}>
      <MuiThemeProvider theme={SelectField}>
        <InputLabel htmlFor={htmlFor}>{label}</InputLabel>
        <Select
          className={classes.multipleSelectIcon}
          multiple
          value={selectedValues}
          onChange={handleSelect}
          input={<Input id={id} placement="bottom" onChange={onChange} />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((item) =>
                item.label ? (
                  <Chip key={item.value} label={item.label} className={classes.chip} />
                ) : (
                  <Chip key={item} label={item} className={classes.chip} />
                )
              )}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {options.map((item) =>
            item.label ? (
              <MenuItem key={item.value} value={item}>
                {item.label}
              </MenuItem>
            ) : (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            )
          )}
        </Select>
        {clearButton === true && (
          <ClearButton formName={formName} reduxName={reduxName} dispatch={dispatch} />
        )}
        {helpText !== undefined ? (
          <FormHelperText id={id + '-text'}>{helpText}</FormHelperText>
        ) : null}
      </MuiThemeProvider>
    </FormControl>
  );
};

CustomChipMultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(CustomChipSelectFieldStyle), connect())(CustomChipMultipleSelect);
