import {updateLoader} from '_actions/global/loaderActions';
import {exportCartToXLS, getCart, lockCarts, sendCart} from '_actions/projectSpecific/cartsActions';
import {postWaitingOrders} from '_actions/projectSpecific/configActions';
import {getDeliveryOptions} from '_actions/projectSpecific/deliveryOptionsActions';
import {detailCartDialogStyle} from '_assets/projectSpecific/jss/components/Dialogs/detailCartDialogStyle';
import DialogTitle from '_components/global/Dialogs/DialogTitle';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import SetAsOrderedDialog from '_components/projectSpecific/Dialogs/SetAsOrderedDialog';
import DetailOrderForm from '_components/projectSpecific/Forms/DetailOrderForm';
import SetupDetailOrderForm from '_components/projectSpecific/Forms/SetupDetailOrderForm';
import {
  actualUserSelector,
  fireErrorToast,
  fireSuccessToast,
  fireWarningToast,
} from '_helpers/global/functions';
import download from 'downloadjs';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {formValueSelector} from 'redux-form';
import {Trans} from '@lingui/macro';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const rows = [
  {
    id: 'product_no',
    label: <Trans>Product No.</Trans>,
    label2: <Trans>Description</Trans>,
    sortable: false,
  },
  {
    id: 'customer_delivery_date',
    label: <Trans>Customer delivery date</Trans>,
    label2: <Trans>Customer delivery option</Trans>,
    sortable: false,
  },
  {
    id: 'homologation',
    label: <Trans>Homologation</Trans>,
    label2: <Trans>Vehicle</Trans>,
    sortable: false,
  },
  {
    id: 'retail_price',
    label: <Trans>Retail Price (No VAT)</Trans>,
    label2: <Trans>Customer price (No VAT)</Trans>,
    sortable: false,
  },
  {
    id: 'reference',
    label: <Trans>Reference</Trans>,
    label2: <Trans>Ordered</Trans>,
    sortable: false,
  },
];

const rows2 = [
  {
    id: 'delivery_address',
    label: <Trans>Delivery address</Trans>,
    sortable: false,
  },
  {
    id: 'customer_info',
    label: <Trans>Customer info</Trans>,
    sortable: false,
  },
  {
    id: 'ordered',
    label: <Trans>Ordered in Rhiag</Trans>,
    sortable: false,
  },
  {
    id: 'note',
    label: <Trans>Note</Trans>,
    sortable: false,
  },
  {
    id: 'totalCustomerPrice',
    label: <Trans>Total customer price</Trans>,
    sortable: false,
  },
];

class DetailOrderDialog extends Component {
  toastId = null;

  state = {
    page: 0,
    rowsPerPage: 10,
    loading: true,
    loadingDeliveryOptions: true,
    openDialogSendOrder: false,
    openSetAsOrderedDialog: false,
  };

  componentDidMount = () => {
    this.fetchCart();
    this.fetchDeliveryOptions();
  };

  fetchCart = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props.getCart(this.props.id).then(() => {
      this.setState({
        loading: false,
      });
      this.props.updateLoader({globalLoading: false});
    });
  };

  fetchDeliveryOptions = () => {
    return this.props.getDeliveryOptions().then(() => {
      this.setState({
        loadingDeliveryOptions: false,
      });
    });
  };

  getInitValues = (items) => {
    const initValues = {items: []};

    items &&
      items.forEach((item, index) => {
        initValues.items[index] = {};
        initValues.items[index].pk = item.id;
        initValues.items[index].delivery_date = item.delivery_date;
        if (item.delivery_option_id) {
          initValues.items[index].delivery_option_id = item.delivery_option_id;
        }
        initValues.items[index].deliver_qty = item.deliver_qty;
        initValues.items[index].order_qty = item.order_qty;
        initValues.items[index].status = item.status;
        if (this.props.deliveryDatesAll) {
          initValues.items[index].delivery_date = this.props.deliveryDatesAll;
        }
        if (this.props.deliveryOptionsAll) {
          initValues.items[index].delivery_option_id = this.props.deliveryOptionsAll;
        }
      });
    return initValues;
  };

  getOptions = (options) => {
    const listOptions = [];
    options.forEach((option) => {
      const o = {};
      o.value = option.id;
      o.label = option.name;
      listOptions.push(o);
    });
    return listOptions;
  };

  warningDeliver = () => {
    this.toastId = fireWarningToast(
      <Trans>At least one value of Deliver qty must be greater than 0.</Trans>
    );
  };

  warningOrder = () => {
    this.toastId = fireWarningToast(
      <Trans>At least one value of Order qty must be greater than 0.</Trans>
    );
  };

  handleDownloadXLS = () => {
    this.props.updateLoader({globalLoading: true});
    this.props
      .exportCartToXLS(this.props.cartDetail.id)
      .then((res) => {
        download(
          res.data,
          res.headers['x-filename'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Successfully downloaded.</Trans>);
      })
      .catch((err) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(err.errors._error, {autoClose: false});
      });
  };

  handleCloseSetAsOrderedDialog = () => {
    this.setState({openSetAsOrderedDialog: false});
  };

  handleOpenSetAsOrderedDialog = () => {
    this.setState({openSetAsOrderedDialog: true});
  };

  handleSetAsOrdered = (e) => {
    e.preventDefault();
    this.props.updateLoader({globalLoading: true});
    return this.props
      .sendCart(this.props.cartDetail.id, {
        ordered: true,
        ref_number: this.props.ref_number ? this.props.ref_number : '',
      })
      .then(() => {
        this.toastId = fireSuccessToast(<Trans>Order was set as ordered.</Trans>);
        this.props
          .lockCarts([this.props.cartDetail.id], false)
          .then(() => {
            this.toastId = fireSuccessToast(<Trans>Basket is unlocked.</Trans>);
            this.setState({
              openDetailCartDialog: false,
            });
            this.props.updateLoader({globalLoading: false});
          })
          .catch((error) => {
            this.toastId = fireErrorToast(error?.errors?._error, {autoClose: false});
          });
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error?.errors?._error, {autoClose: false});
      })
      .finally(() => {
        this.handleCloseSetAsOrderedDialog();
        this.props.handleCloseDetailOrderDialog();
      });
  };

  handleSendOrderToRhiag = () => {
    this.props.updateLoader({globalLoading: true});
    this.props
      .postWaitingOrders({cart_ids: [this.props.id]})
      .then((res) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Order set as ordered</Trans>);
      })
      .catch((err) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(err.errors._error, {autoClose: false});
      });
  };

  render() {
    const {
      handleClose,
      handleCloseWithUnlock,
      open,
      classes,
      fullScreen,
      currentUserId,
      cartDetail,
      waitingOrders,
    } = this.props;
    const {id, total_customer_price, items, owner, shipment_address, operator_id, ordered, note} =
      this.props.cart;

    const cartItems = cartDetail && cartDetail.items ? cartDetail.items : items;
    const handleCloseFunction = currentUserId === operator_id ? handleCloseWithUnlock : handleClose;
    const actionsCondition = currentUserId === operator_id;

    return (
      <Dialog
        fullScreen={fullScreen}
        onClose={handleCloseFunction}
        aria-labelledby="detailCart"
        open={open}
        classes={{paperFullScreen: classes.paperFullScreen}}
      >
        <DialogTitle id="detailCart" onClose={handleCloseFunction}>
          <Trans>Order detail</Trans>
          <span>
            {cartDetail.ref_number && (
              <>
                <Trans>Ref. no.:</Trans> {cartDetail?.ref_number}
              </>
            )}
          </span>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <GridContainer>
            <GridItem xs={12}>
              {this.state.openSetAsOrderedDialog && (
                <SetAsOrderedDialog
                  open={this.state.openSetAsOrderedDialog}
                  handleClose={this.handleCloseSetAsOrderedDialog}
                  onSubmit={(values) => {
                    return this.handleSetAsOrdered(values);
                  }}
                  form="setAsOrdered"
                />
              )}
              {!this.state.loading && !this.state.loadingDeliveryOptions && (
                <>
                  <SetupDetailOrderForm
                    form="SetupDetailOrderForm"
                    rows2={rows2}
                    totalCustomerPrice={total_customer_price}
                    owner={owner}
                    shipmentAddress={shipment_address}
                    ordered={ordered}
                    note={note}
                  />
                  <DetailOrderForm
                    submitName={<Trans>Save</Trans>}
                    form="detailCartForm"
                    initialValues={this.getInitValues(cartItems)}
                    rows1={rows}
                    key={id}
                    items={cartItems}
                    deliveryOptions={this.getOptions(this.props.deliveryTerms)}
                    handleSendOrderToRhiag={this.handleSendOrderToRhiag}
                    handleDownloadXLS={this.handleDownloadXLS}
                    handleSetAsOrdered={this.handleOpenSetAsOrderedDialog}
                    handleClose={handleCloseFunction}
                    condition={actionsCondition}
                    canBeSentToOracle={waitingOrders?.can_be_sent_to_oracle}
                    ordered={ordered}
                  />
                </>
              )}
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

DetailOrderDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const selector = formValueSelector('SetupDetailOrderForm');
const selector2 = formValueSelector('setAsOrdered');
const selector3 = formValueSelector('detailCartForm');

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      getCart,
      getDeliveryOptions,
      postWaitingOrders,
      exportCartToXLS,
      sendCart,
      lockCarts,
    },
    dispatch
  );
};

export default compose(
  withMobileDialog(),
  withStyles(detailCartDialogStyle),
  connect((store) => {
    return {
      cart: store.Cart.data,
      deliveryDatesAll: selector(store, 'delivery_dates_all'),
      deliveryOptionsAll: selector(store, 'delivery_options_all'),
      useCocoAddress: selector(store, 'use_coco_shipment_address'),
      fromItems: selector3(store, 'items'),
      // orderQTY: selector3(store, "order_qty"),
      deliveryTerms: store.DeliveryOptions.data,
      ref_number: selector2(store, 'ref_number'),
      currentUser: actualUserSelector(store.Auth),
      waitingOrders: store.Config.waitingOrders,
    };
  }, mapDispatchToProps)
)(DetailOrderDialog);
