import {updateLoader} from '_actions/global/loaderActions';
import {getBranches} from '_actions/projectSpecific/branchesActions';
import {getGroups} from '_actions/projectSpecific/groupsActions';
import {createUser} from '_actions/projectSpecific/usersActions';
import {createUserPageStyle} from '_assets/projectSpecific/jss/views/createUserPageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card.jsx';
import CardBody from '_components/global/Card/CardBody.jsx';
import CardHeader from '_components/global/Card/CardHeader.jsx';
import CardIcon from '_components/global/Card/CardIcon.jsx';
import GridContainer from '_components/global/Grid/GridContainer.jsx';
import GridItem from '_components/global/Grid/GridItem.jsx';
import UserForm from '_components/projectSpecific/Forms/UserForm';
import authorizedAbility from '_helpers/global/Authorization';
import {fireErrorToast, fireSuccessToast} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {formValueSelector} from 'redux-form';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {ArrowBack, PersonAdd} from '@material-ui/icons';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createUser,
      updateLoader,
      getBranches,
      getGroups,
    },
    dispatch
  );
};

class CreateUserPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
    };
    if (authorizedAbility.cannot('create', 'Users')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchBranches();
    this.fetchGroups();
  };

  fetchBranches = () => {
    this.props.getBranches();
  };

  fetchGroups = () => {
    this.props.getGroups();
  };

  getOptions = (data) => {
    const listOptions = [];
    data.forEach((obj) => {
      const objectOption = {};
      objectOption.value = obj.id;
      objectOption.label = obj.name;
      listOptions.push(objectOption);
    });
    listOptions.unshift({value: 'empty', label: '----------'});
    return listOptions;
  };

  addUser = (values) => {
    delete values.repeatPassword;
    if (values.branch) {
      values.branch = {
        pk: values.branch,
      };
    }
    if (values.groups) {
      if (values.groups === 'empty') {
        values.groups = null;
      } else {
        values.groups = [{pk: values.groups}];
      }
    }
    if (values.auth_type === true) {
      values.auth_type = 'oracle';
    } else {
      values.auth_type = 'local';
    }

    this.props.updateLoader({globalLoading: true});
    return this.props
      .createUser(values)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>User successfully created.</Trans>);
        browserHistoryAccessor.push('/admin/users');
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  render() {
    const {classes} = this.props;
    return (
      <GridContainer justify="center">
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon className={classes.root}>
              <CardIcon color="blue">
                <PersonAdd />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Insert user</h4>
              <div className={classes.backBtn}>
                <Tooltip title={<Trans>Back to list</Trans>}>
                  <Button
                    className={classes.btnIcon}
                    onClick={() => browserHistoryAccessor.goBack()}
                  >
                    <ArrowBack />
                  </Button>
                </Tooltip>
              </div>
            </CardHeader>
            <CardBody>
              <div className={classes.background}>
                <UserForm
                  form="createUser"
                  onSubmit={this.addUser}
                  submitName={<Trans>Insert user</Trans>}
                  initialValues={{groups: 'empty'}}
                  branchesOptions={this.getOptions(this.props.branches)}
                  roleOptions={this.getOptions(this.props.groups)}
                  authType={this.props.authType && this.props.authType}
                />
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const selector = formValueSelector('createUser');

export default compose(
  withStyles(createUserPageStyle),
  connect((store) => {
    return {
      branches: store.Branches.data,
      groups: store.Groups.data,
      authType: selector(store, 'auth_type'),
    };
  }, mapDispatchToProps)
)(CreateUserPage);
