import {UPDATE_LOADER_SUCCESS, UPDATE_LOCAL_LOADER_SUCCESS} from '_actions/global/types';

const initState = {
  globalLoading: false,
};

const localInitState = {
  localLoading: false,
  importLoading: false,
};

export const LoaderReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_LOADER_SUCCESS) {
    return {
      ...state,
      ...action.data,
    };
  }
  return state;
};

export const LocalLoaderReducer = (state = localInitState, action = null) => {
  if (action.type === UPDATE_LOCAL_LOADER_SUCCESS) {
    return {
      ...state,
      ...action.data,
    };
  }
  return state;
};
