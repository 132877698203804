import {
  FETCH_CARTS_FAIL,
  FETCH_CARTS_REQUEST,
  FETCH_CARTS_SUCCESS,
  FETCH_CART_FAIL,
  FETCH_CART_LOCK_INFO_FAIL,
  FETCH_CART_LOCK_INFO_REQUEST,
  FETCH_CART_LOCK_INFO_SUCCESS,
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
} from '_actions/projectSpecific/types';

const initCartsState = {
  data: [],
  total: 0,
};

const initCartState = {
  data: {},
};

const initCartLockInfoState = {
  data: {},
};

export const CartsReducer = (state = initCartsState, action = null) => {
  switch (action.type) {
    case FETCH_CARTS_REQUEST:
      return {
        ...state,
      };

    case FETCH_CARTS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
      };
    case FETCH_CARTS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export const CartReducer = (state = initCartState, action = null) => {
  switch (action.type) {
    case FETCH_CART_REQUEST:
      return {
        ...state,
      };

    case FETCH_CART_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case FETCH_CART_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export const CartLockInfoReducer = (state = initCartLockInfoState, action = null) => {
  switch (action.type) {
    case FETCH_CART_LOCK_INFO_REQUEST:
      return {
        ...state,
      };

    case FETCH_CART_LOCK_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case FETCH_CART_LOCK_INFO_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
