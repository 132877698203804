import {resolveSiteKey} from '_api/recaptcha';
import {forgottenPassword} from '_actions/global/authActions';
import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {ReactComponent as Logo} from '_assets/projectSpecific/img/ec24-logo-dark.svg';
import RegularButton from '_components/global/Buttons/Button';
import Footer from '_components/global/Footer/Footer';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import activationStyle from '_components/projectSpecific/Activation/activationStyle';
import {AVAILABLE_LANGUAGES} from '_config/configConstants';
import i18nProvider from '_helpers/global/i18n';
import {parse} from 'query-string';
import React, {useEffect, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {Trans, t} from '@lingui/macro';
import {InputBase} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';

const ForgottenPasswordPage = (props) => {
  const {classes, updateGlobalSettings, forgottenPassword} = props;
  const {language} = parse(window.location.search);
  const recaptchaRef = useRef();

  const [submitting, setSubmitting] = useState(false);
  const [username, setUsername] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');

  function handleUsernameChange(value) {
    setUsername(value);
  }

  function handleRecaptchaChange(value) {
    setRecaptchaToken(value);
  }

  function handleSubmit() {
    if (username) {
      setSubmitting(true);

      forgottenPassword(username)
        .catch(() => {
          recaptchaRef.current.reset();
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  }

  useEffect(() => {
    if (AVAILABLE_LANGUAGES.includes(language)) {
      updateGlobalSettings({currentLanguage: language});
    } else {
      // if not specified, set language as 'de'
      updateGlobalSettings({currentLanguage: 'de'});
    }
  }, [language, updateGlobalSettings]);

  return (
    <GridContainer
      direction={'column'}
      className={classes.activationContainer}
      alignContent={'center'}
      alignItems={'center'}
      justify={'center'}
    >
      <GridContainer
        direction={'row'}
        justify={'center'}
        className={classes.formCard}
        alignContent={'center'}
      >
        <form>
          <GridItem
            noPadding={true}
            container
            xs={12}
            style={{
              padding: '30px',
              border: '1px solid #dddddd',
              boxShadow: '0px 0px 16px 2px rgba(176,176,176,0.8)',
            }}
          >
            <GridItem xs={12} style={{marginBottom: '30px'}}>
              <Logo />
            </GridItem>
            <GridItem
              xs={12}
              style={{
                color: 'black',
                fontSize: '23px',
                fontFamily: 'SharpGrotesk',
                marginBottom: '24px',
              }}
            >
              <Trans>Password Recovery</Trans>
            </GridItem>
            <GridItem
              xs={12}
              style={{
                color: '#aaaaaa',
                fontSize: '14px',
                fontFamily: 'SharpGrotesk',
                marginBottom: '20px',
              }}
            >
              <Trans>
                Please enter your username to receive instructions on resetting your password.
              </Trans>
            </GridItem>
            <GridItem xs={12} noPadding={true} style={{margin: '30px 0'}}>
              <InputBase
                fullWidth={true}
                margin={'dense'}
                className={classes.activationField}
                name={'username'}
                id={'username'}
                value={username}
                onChange={(e) => handleUsernameChange(e.target.value)}
                placeholder={i18nProvider._(t`Username`)}
                disabled={submitting}
              />
            </GridItem>
            <GridItem xs={12} noPadding={true}>
              <ReCAPTCHA
                ref={recaptchaRef}
                style={{display: 'inline-block'}}
                size={'normal'}
                sitekey={resolveSiteKey()}
                onChange={handleRecaptchaChange}
              />
            </GridItem>
            <RegularButton
              disabled={submitting || !username || !recaptchaToken}
              fullWidth={true}
              className={classes.activationButton}
              onClick={handleSubmit}
            >
              {submitting ? (
                <CircularProgress className={classes.activationLoading} color="inherit" />
              ) : (
                <Trans>Submit</Trans>
              )}
            </RegularButton>
          </GridItem>
        </form>
      </GridContainer>
      <GridContainer direction={'row'} className={classes.footerContainer}>
        <Footer />
      </GridContainer>
    </GridContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forgottenPassword,
      updateGlobalSettings,
    },
    dispatch
  );
};

export default compose(
  connect((store) => {
    return {};
  }, mapDispatchToProps),
  withStyles(activationStyle)
)(ForgottenPasswordPage);
