import {
  setupDetailCartFormStyle,
  tableHeadTheme,
} from '_assets/projectSpecific/jss/components/Forms/setupDetailCartFormStyle';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import cx from 'classnames';
import React from 'react';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const SetupDetailOrderForm = ({
  rows2,
  key,
  totalCustomerPrice,
  classes,
  owner,
  shipmentAddress,
  ordered,
  note,
}) => {
  return (
    <div className={cx(classes.tableWrapper, classes.marginBottom)}>
      <Table className={classes.table} aria-labelledby="tableTitle">
        <MuiThemeProvider theme={tableHeadTheme}>
          <SimpleTableHead rows={rows2} />
        </MuiThemeProvider>

        <TableBody>
          <TableRow hover tabIndex={-1} key={key}>
            <TableCell classes={{root: classes.cell}}>
              {shipmentAddress ? (
                <div>
                  <p>
                    {shipmentAddress && shipmentAddress.street}{' '}
                    {shipmentAddress && shipmentAddress.house_no}
                  </p>
                  <p>{shipmentAddress && shipmentAddress.city}</p>
                  <p>{shipmentAddress && shipmentAddress.post_code}</p>
                </div>
              ) : (
                <div>
                  <p>
                    {owner?.customer?.address_1}
                    {owner?.customer?.address_2}
                    {owner?.customer?.address_3}
                  </p>
                  <p>{owner?.customer?.city}</p>
                  <p>{owner?.customer?.post_code}</p>
                </div>
              )}
            </TableCell>
            <TableCell classes={{root: classes.cell}}>
              <p>{owner?.customer?.customer_no}</p>
              <p>{owner?.customer?.name}</p>
            </TableCell>
            <TableCell classes={{root: classes.cell}}>
              {ordered === false ? (
                <div className={classes.dangerTextColor}>
                  <Trans>No</Trans>
                </div>
              ) : (
                <div className={classes.successTextColor}>
                  <Trans>Yes</Trans>
                </div>
              )}
            </TableCell>
            <TableCell classes={{root: classes.noteCell}}>
              <div>{note}</div>
            </TableCell>
            <TableCell classes={{root: classes.cell}}>
              <div className={classes.totalPrice}>
                {totalCustomerPrice ? totalCustomerPrice : <strong>&mdash;</strong>}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(setupDetailCartFormStyle)(SetupDetailOrderForm);
