import {sendOrderDialogStyle} from '_assets/projectSpecific/jss/components/Dialogs/sendOrderDialogStyle';
import ActionFormsButtons from '_components/global/Buttons/ActionFormsButtons';
import DialogTitle from '_components/global/Dialogs/DialogTitle';
import {FormTextField} from '_components/global/Form/FormFields';
import React, {Component} from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Dialog from '@material-ui/core/Dialog';
import {withStyles} from '@material-ui/core/styles';

class SetAsOrderedDialog extends Component {
  render() {
    const {handleClose, open, classes, onSubmit, form} = this.props;

    return (
      <Dialog
        maxWidth={'sm'}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="detailCart"
        open={open}
      >
        <DialogTitle id="detailCart" onClose={handleClose}>
          <Trans>Set order as ordered</Trans>
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <div className={classes.dialogContent}>
            <Field
              name={'ref_number'}
              label={<Trans>Reference number (optional)</Trans>}
              id={'ref_number'}
              component={FormTextField}
            />
          </div>
          <ActionFormsButtons
            closeName={<Trans>Close</Trans>}
            submitName={<Trans>Set as ordered</Trans>}
            handleClose={handleClose}
          />
        </form>
      </Dialog>
    );
  }
}

export default compose(
  withStyles(sendOrderDialogStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(SetAsOrderedDialog);
