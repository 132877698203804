import {
  FETCH_WHOLESALER_HISTORY_FAIL,
  FETCH_WHOLESALER_HISTORY_REQUEST,
  FETCH_WHOLESALER_HISTORY_SUCCESS,
} from '_actions/projectSpecific/types';

const initWholesalerAvailabilityState = {
  data: [],
  total: 0,
  loading: false,
};

export const WholesalerAvailabilityReducer = (
  state = initWholesalerAvailabilityState,
  action = null
) => {
  switch (action.type) {
    case FETCH_WHOLESALER_HISTORY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_WHOLESALER_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
        loading: false,
      };
    }
    case FETCH_WHOLESALER_HISTORY_FAIL: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
