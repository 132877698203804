import {UPDATE_GLOBAL_SETTINGS_SUCCESS} from '_actions/global/types';

export function updateGlobalSettings(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPDATE_GLOBAL_SETTINGS_SUCCESS,
        data: data,
      });
      resolve();
    });
  };
}
