import {
  FETCH_LAST_LOGIN_FAIL,
  FETCH_LAST_LOGIN_REQUEST,
  FETCH_LAST_LOGIN_SUCCESS,
} from '_actions/projectSpecific/types';

const initState = {
  data: [],
  total: 0,
};

export const LastLoginReducer = (state = initState, action = null) => {
  switch (action.type) {
    case FETCH_LAST_LOGIN_REQUEST:
      return {
        ...state,
      };

    case FETCH_LAST_LOGIN_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
      };
    case FETCH_LAST_LOGIN_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
