import {createMuiTheme} from '@material-ui/core';

export const TableHeadStyle = {
  sorting: {
    color: '#222',
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  actions: {
    textAlign: 'right',
  },
};

export const tableSelectTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: '0px',
      },
    },
  },
});
