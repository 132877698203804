import {
  FETCH_DISCOUNT_CATEGORIES_FAIL,
  FETCH_DISCOUNT_CATEGORIES_IMPORT_FAIL,
  FETCH_DISCOUNT_CATEGORIES_IMPORT_REQUEST,
  FETCH_DISCOUNT_CATEGORIES_IMPORT_SUCCESS,
  FETCH_DISCOUNT_CATEGORIES_REQUEST,
  FETCH_DISCOUNT_CATEGORIES_SUCCESS,
} from '_actions/projectSpecific/types';

const initDiscountCategoriesState = {
  data: [],
  initialFormValues: {},
  total: 0,
  importId: null,
  importData: [],
};
const getInitialValuesMap = (data) => {
  let initials = {};
  data.forEach((item) => {
    let initialGroups = {};
    item.discount_groups.forEach((group) => {
      initialGroups[group.id] = group.value;
    });
    initials[item.id] = initialGroups;
  });
  return {items: initials};
};

export const DiscountCategoriesReducer = (state = initDiscountCategoriesState, action = null) => {
  switch (action.type) {
    case FETCH_DISCOUNT_CATEGORIES_REQUEST:
      return {
        ...state,
      };
    case FETCH_DISCOUNT_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        initialFormValues: getInitialValuesMap(action.data.objects),
        total: action.total,
      };
    case FETCH_DISCOUNT_CATEGORIES_FAIL:
      return {
        ...state,
        error: true,
      };
    case FETCH_DISCOUNT_CATEGORIES_IMPORT_REQUEST:
      return {
        ...state,
      };
    case FETCH_DISCOUNT_CATEGORIES_IMPORT_SUCCESS:
      return {
        ...state,
        importId: action.data.id,
        importData: action.data.discount_categories_sample,
      };
    case FETCH_DISCOUNT_CATEGORIES_IMPORT_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
