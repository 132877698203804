import {getMyDeliveryOptions} from '_actions/projectSpecific/deliveryOptionsActions';
import {sendOrderDialogStyle} from '_assets/projectSpecific/jss/components/Dialogs/sendOrderDialogStyle';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import DialogTitle from '_components/global/Dialogs/DialogTitle';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import SendOrderForm from '_components/projectSpecific/Forms/SendOrderForm';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {Send} from '@material-ui/icons';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMyDeliveryOptions,
    },
    dispatch
  );
};

class SendOrderDialog extends Component {
  componentDidMount = () => {
    this.fetchMyDeliveryOptions();
  };

  fetchMyDeliveryOptions = () => {
    return this.props.getMyDeliveryOptions();
  };

  getOptions = (options) => {
    const listOptions = [];
    options.forEach((option) => {
      const o = {};
      o.value = option.id;
      o.label = option.name;
      listOptions.push(o);
    });
    return listOptions;
  };

  render() {
    const {handleClose, open, classes, fullScreen, onSubmit} = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="detailCart"
        open={open}
      >
        <DialogTitle id="detailCart" onClose={handleClose}>
          <Trans>Send order</Trans>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="blue" icon>
                  <CardIcon color="blue">
                    <Send />
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  <SendOrderForm
                    form="sendOrder"
                    onSubmit={onSubmit}
                    deliveryOptions={
                      !this.props.myDeliveryOptions.loading &&
                      this.props.myDeliveryOptions.data.length
                        ? this.getOptions(this.props.myDeliveryOptions.data)
                        : []
                    }
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

SendOrderDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withMobileDialog(),
  withStyles(sendOrderDialogStyle),
  connect((store) => {
    return {
      myDeliveryOptions: store.MyDeliveryOptions,
    };
  }, mapDispatchToProps)
)(SendOrderDialog);
