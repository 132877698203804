import catalogDe from 'locales/de/messages';
import catalogEn from 'locales/en/messages';
import catalogFr from 'locales/fr/messages';
import catalogIt from 'locales/it/messages';
import {setupI18n} from '@lingui/core';

const i18nProvider = setupI18n();

i18nProvider.load({
  fr: catalogFr,
  en: catalogEn,
  de: catalogDe,
  it: catalogIt,
});

// TEXT USE CASE
// i18nProvider._(t`Monday`),

export default i18nProvider;
