import {
  FETCH_USERS_FAIL,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_PERMISSIONS_FAIL,
  FETCH_USER_PERMISSIONS_REQUEST,
  FETCH_USER_PERMISSIONS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
} from '_actions/projectSpecific/types';

const initUsersState = {
  data: [],
  total: 0,
};

const initUserState = {
  data: {},
  loading: true,
};

const initUserPermissionsState = {
  data: {},
};

export const UsersReducer = (state = initUsersState, action = null) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
      };

    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
      };
    case FETCH_USERS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export const UserReducer = (state = initUserState, action = null) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const UserPermissionsReducer = (state = initUserPermissionsState, action = null) => {
  switch (action.type) {
    case FETCH_USER_PERMISSIONS_REQUEST:
      return {
        ...state,
      };

    case FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case FETCH_USER_PERMISSIONS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
