import {FormCustomSelectField} from '_components/global/Form/FormFields';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';

const FormatForm = ({classes, handleSubmit, pristine, submitting, form}) => {
  return (
    <form>
      <Field
        name="export"
        label={<Trans>Choose exported format</Trans>}
        type="select"
        component={FormCustomSelectField}
        options={[
          {value: 'text/csv', label: '.csv'},
          {value: 'text/plain', label: '.txt'},
        ]}
      />
    </form>
  );
};

export default compose(
  reduxForm({
    enableReinitialize: true,
  })
)(FormatForm);
