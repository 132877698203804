import {blueColor, grayColor} from '_assets/global/jss/styleHelpers';

const errorPageStyles = () => ({
  contentCenter: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    zIndex: '3',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    color: blueColor[0],
    padding: '0 15px',
    width: '100%',
    maxWidth: '880px',
  },
  title: {
    textDecoration: 'none',
    marginTop: '30px',
    marginBottom: '25px',
    minHeight: '32px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    '& small': {
      color: grayColor[1],
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
    fontSize: '13.7em',
    color: blueColor[0],
    letterSpacing: '14px',
    fontWeight: '700',
  },
  subTitle: {
    fontSize: '2.25rem',
    marginTop: '0',
    marginBottom: '8px',
  },
  description: {
    fontSize: '1.125rem',
    marginTop: '0',
    marginBottom: '8px',
  },
});

export default errorPageStyles;
