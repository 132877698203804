import {blackColor} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core/index';

export const clearButtonStyle = () => ({
  clearButton: {
    position: 'absolute',
    top: '10px',
    right: '-32px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: blackColor,
    },
  },
});

export const clearButtonTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: '20px !important',
      },
    },
  },
});
