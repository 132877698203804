import {blueColor, cardTitle, grayColor} from '_assets/global/jss/styleHelpers';

export const customerPageStyle = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
  activeIcon: {
    fontSize: '10px',
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  margin: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  customLink: {
    color: blueColor[0],
    textDecoration: 'none',
    '&:hover': {
      color: blueColor[5],
    },
  },
  redRow: {
    backgroundColor: 'rgba(211,47,47, 0.7)',
  },
  smallCard: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  customerPermissions: {
    maxWidth: '150px',
    minWidth: '300px',
  },
  groups: {
    textTransform: 'capitalize',
  },
  disableDelete: {
    color: grayColor[0],
    '&:hover,&:focus': {
      color: grayColor[0],
    },
  },
});
