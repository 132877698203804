import {
  FETCH_CONFIG_FAIL,
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FETCH_WAITING_FAIL,
  FETCH_WAITING_REQUEST,
  FETCH_WAITING_SUCCESS,
} from '_actions/projectSpecific/types';

const initState = {
  loading: false,
  waitingOrders: {},
};

export const ConfigReducer = (state = initState, action = null) => {
  switch (action.type) {
    case FETCH_CONFIG_REQUEST:
      return {...state};
    case FETCH_CONFIG_SUCCESS:
      return {...state, data: action.data};
    case FETCH_CONFIG_FAIL:
      return {...state};

    case FETCH_WAITING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_WAITING_SUCCESS:
      return {
        ...state,
        waitingOrders: action.data,
        loading: false,
      };
    case FETCH_WAITING_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
