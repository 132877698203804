import tabsStyle from '_assets/global/jss/components/Tabs/tabsStyle.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const TabContainer = ({classes, children, dir}) => {
  return (
    <Typography component="div" dir={dir} className={classes.tabs}>
      {children}
    </Typography>
  );
};

TabContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

export default withStyles(tabsStyle)(TabContainer);
