import {dangerColor, infoColor, successColor, warningColor} from '_assets/global/jss/styleHelpers';

export const toastContainerStyle = () => ({
  containerStyle: {
    '& .Toastify__toast--success': {
      backgroundColor: successColor[0],
    },
    '& .Toastify__toast--warning': {
      backgroundColor: warningColor[0],
    },
    '& .Toastify__toast--info': {
      backgroundColor: infoColor[0],
    },
    '& .Toastify__toast--error': {
      backgroundColor: dangerColor[0],
    },
    '& .Toastify__toast': {
      height: 'auto',
      minHeight: '48px',
      borderRadius: '48px',
      padding: '12px 15px',
      fontSize: '12px',
    },
    '& .Toastify__toast-container': {
      minWidth: '400px',
    },
  },
});
