import ActionFormsButtons from '_components/global/Buttons/ActionFormsButtons';
import {FormDateField, FormSelectField} from '_components/global/Form/FormFields';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';

const SendOrderForm = ({
  classes,
  handleSubmit,
  pristine,
  submitting,
  form,
  handleClose,
  deliveryOptions,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="delivery_date"
        dateLabel={<Trans>Choose delivery date</Trans>}
        id="delivery_date"
        component={FormDateField}
      />
      <Field
        name="delivery_option_id"
        label={<Trans>Choose delivery option</Trans>}
        id="delivery_option_id"
        type="select"
        component={FormSelectField}
        options={deliveryOptions}
      />
      <ActionFormsButtons
        closeName={<Trans>Close</Trans>}
        submitName={<Trans>Send order to Rhiag</Trans>}
        handleClose={handleClose}
      />
    </form>
  );
};

export default compose(
  reduxForm({
    enableReinitialize: true,
  })
)(SendOrderForm);
