import {cardTitle} from '_assets/global/jss/styleHelpers';

export const permissionsPageStyle = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  table: {
    minWidth: '100%',
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      minWidth: '180px',
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
});
