import {swipeableViewsStyle} from '_assets/projectSpecific/jss/components/Tabs/swipeableViewsStyle';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import {withStyles} from '@material-ui/core/styles';

const SwipeableView = (props) => {
  const {index, onChangeIndex, classes, theme} = props;

  return (
    <SwipeableViews
      className={classes.scrollSwipeableViews}
      slideClassName={classes.containerSwipeableViews}
      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
      index={index}
      onChangeIndex={onChangeIndex}
    >
      {props.children}
    </SwipeableViews>
  );
};

export default withStyles(swipeableViewsStyle, {withTheme: true})(SwipeableView);
