import {
  blackColor,
  blueColor,
  grayColor,
  hexToRgb,
  whiteColor,
} from '_assets/global/jss/styleHelpers';

export const configurationFormStyle = (theme) => ({
  formActions: {
    padding: theme.spacing.unit * 3,
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    minHeight: 'auto',
    minWidth: 'auto',
    backgroundColor: grayColor[0],
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.12)',
    border: 'none',
    borderRadius: '3px',
    position: 'relative',
    padding: '12px 30px',
    margin: '.3125rem 1px',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '0',
    willChange: 'box-shadow, transform',
    transition:
      'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    '&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: grayColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.2)',
    },
  },
  blue: {
    backgroundColor: blueColor[0],
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(blueColor[0]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(blueColor[0]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(blueColor[0]) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: blueColor[0],
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(blueColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(blueColor[0]) +
        ', 0.2)',
    },
  },
  configuration: {
    backgroundColor: grayColor[12],
    padding: '20px',
    margin: '.3125rem 1px',
    boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 30%)',
  },
  fieldPadding: {
    padding: '0 15px',
  },
  title: {
    padding: '5px 15px',
    fontSize: '16px',
    borderBottom: '2px solid ' + blueColor[6],
    margin: '25px 0 15px 0',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: blueColor[6],
  },

  rootSwitchClass: {
    marginLeft: '-12px',
  },
});
