import {lighten} from '@material-ui/core/styles/colorManipulator';

const customersDeliveryOptionsTableToolbarStyle = (theme) => ({
  root: {
    padding: 0,
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
          paddingLeft: '10px',
          paddingRight: '10px',
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& form': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& >div': {
        margin: '0px',
      },
    },
  },
  buttons: {
    display: 'flex',
  },
  hiddenSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hiddenBig: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
});

export default customersDeliveryOptionsTableToolbarStyle;
