import {updateLoader} from '_actions/global/loaderActions';
import {editConfig, getConfig} from '_actions/projectSpecific/configActions';
import ConfigurationForm from '_components/projectSpecific/Configuration/ConfigurationForm';
import {fireErrorToast, fireSuccessToast} from '_helpers/global/functions';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core/styles';

const ConfigurationTab = (props) => {
  const {configData, editConfig, getConfig, updateLoader} = props;

  useEffect(() => {
    getConfig();
  }, []);

  const handleSubmit = (values) => {
    if (configData) {
      const submitData = [];
      updateLoader({globalLoading: true});

      Object.entries(values.field).forEach((field) => {
        let singleField = configData.find((data) => data.id === Number(field[0]));

        const singleFieldName = 'value_' + singleField.type;

        let submitField = {id: Number(field[0])};

        if (singleFieldName === 'value_int') {
          submitField[singleFieldName] = Number(values.field[Number(field[0])]);
        } else {
          submitField[singleFieldName] = values.field[Number(field[0])];
        }

        submitData.push(submitField);
      });

      editConfig(submitData)
        .then((res) => {
          updateLoader({globalLoading: false});
          fireSuccessToast(<Trans>Configuration successfully updated.</Trans>);
          getConfig();
        })
        .catch((err) => {
          fireErrorToast(<Trans>Something went wrong</Trans>);
          updateLoader({globalLoading: false});
        });
    }
  };

  const getInitialValues = (configData) => {
    let initVal = {field: {}};

    configData.forEach((config) => {
      const fieldName = 'value_' + config.type;

      initVal.field[config.id] = config[fieldName];
    });
    return initVal;
  };

  return (
    <div>
      <ConfigurationForm
        form="configuration"
        onSubmit={handleSubmit}
        submitName={<Trans>Update configuration</Trans>}
        data={configData}
        initialValues={configData && getInitialValues(configData)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      getConfig,
      editConfig,
    },
    dispatch
  );
};

export default compose(
  withStyles({withTheme: true}),
  connect((store) => {
    return {
      configData: store.Config.data,
    };
  }, mapDispatchToProps)
)(ConfigurationTab);
