import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_FILTERS_STATE} from '_actions/global/types';
import {
  FETCH_DISCOUNT_CATEGORIES_IMPORT_FAIL,
  FETCH_DISCOUNT_CATEGORIES_IMPORT_REQUEST,
  FETCH_DISCOUNT_CATEGORIES_IMPORT_SUCCESS,
  FETCH_DISCOUNT_CATEGORIES_REQUEST,
  FETCH_DISCOUNT_CATEGORIES_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/global/functions';

export function getDiscountCategories(offset = 0, limit = 20, orderBy = null, categoryId = null) {
  return function (dispatch) {
    const discountCategoriesFilter = {
      discountCategoriesListFilter: {
        orderBy,
        categoryId,
      },
    };
    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: discountCategoriesFilter,
    });
    dispatch({type: FETCH_DISCOUNT_CATEGORIES_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.discountCategories, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
      q: categoryId ? categoryId : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_DISCOUNT_CATEGORIES_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        // display the import in progress component
        if (error.response?.data === 'An upload is in progress.') {
          return Promise.reject(error.response);
        } else {
          globalApiErrorHandler(error);
        }
      });
  };
}

export function saveDiscountCategoriesChanges(data) {
  return function (dispatch) {
    let newData = [];
    Object.keys(data.items).forEach(function (key) {
      const value = data.items[key];
      let groups = [];

      Object.keys(value).forEach(function (groupKey) {
        groups.push({
          resource_uri: `api/v1/discount-group-mappings/${groupKey}/`,
          pk: parseInt(groupKey),
          value: parseInt(value[groupKey]),
        });
      });

      newData.push({
        id: parseInt(key),
        resource_uri: `api/v1/discount-categories/${key}/`,
        discount_groups: groups,
      });
    });

    return HttpClient.patch(API_ENDPOINT_MAP.discountCategories, {objects: newData}).catch(
      (error) => {
        globalApiErrorHandler(error);
      }
    );
  };
}

export function importDiscountGroup(file) {
  return function (dispatch) {
    dispatch({type: FETCH_DISCOUNT_CATEGORIES_IMPORT_REQUEST});
    let formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    return HttpClient.post(`${API_ENDPOINT_MAP.discountCategories}import/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        dispatch({type: FETCH_DISCOUNT_CATEGORIES_IMPORT_SUCCESS, data: res.data});
        return Promise.resolve(res);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        dispatch({type: FETCH_DISCOUNT_CATEGORIES_IMPORT_FAIL});
        return Promise.reject(error);
      });
  };
}

export function exportDiscountGroup() {
  return function (dispatch) {
    return HttpClient.get(`${API_ENDPOINT_MAP.discountCategories}export/`)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}

export function confirmImportDiscountGroup(id) {
  return function (dispatch) {
    return HttpClient.patch(`${API_ENDPOINT_MAP.discountCategories}import/${id}/start/`)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        globalApiErrorHandler(error);
        return Promise.reject(error);
      });
  };
}
