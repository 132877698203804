import {postWholesalerAvailabilityChange} from '_actions/projectSpecific/wholesalerAvailabilityActions';
import {wholesalerAvailabilityPageStyle} from '_assets/projectSpecific/jss/views/wholesalerAvailabilityPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import Button from '_components/global/Buttons/Button';
import {FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Delete} from '@material-ui/icons';

const WholesalerAvailabilityChangeForm = (props) => {
  const {
    classes,
    data,
    addRowToSelectedTable,
    form,
    formData,
    removeSelectedFromTable,
    pristine,
    submitting,
    postWholesalerAvailabilityChange,
    handleClose,
    fetchWholesalerAvailabilityHistory,
  } = props;

  const [changeLoading, setChangeLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setChangeLoading(true);
    postWholesalerAvailabilityChange(formData)
      .then((res) => {
        fetchWholesalerAvailabilityHistory();
        setFileErrors([]);
        handleClose();
      })
      .catch((err) => {
        if (err?.errors?.data?.wholesaleravailabilitychange) {
          setFileErrors([err.errors.data.wholesaleravailabilitychange]);
        }
      })
      .finally(() => {
        setChangeLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem xs={6}>
          <b>
            <Trans>Product No.</Trans>
          </b>
        </GridItem>
        <GridItem xs={4}>
          <b>
            <Trans>Quantity</Trans>
          </b>
        </GridItem>
      </GridContainer>
      {data.map((row, index) => {
        let hasError = false;
        let errorMessage = '';

        fileErrors.forEach((err) => {
          if (err?.hasOwnProperty(formData?.changes?.[index]?.product_number)) {
            hasError = true;
            errorMessage = err?.[formData?.changes?.[index]?.product_number];
          }
        });

        return (
          <GridContainer key={index}>
            <GridItem xs={6}>
              <Field
                className={classes.tableFormField}
                name={`changes[${index}].product_number`}
                type={'text'}
                component={FormTextField}
                error={hasError}
                helpText={hasError && errorMessage}
              />
            </GridItem>
            <GridItem xs={4}>
              <Field
                className={classes.tableFormField}
                name={`changes[${index}].quantity`}
                type={'number'}
                component={FormTextField}
              />
            </GridItem>
            <GridItem container xs={2} justify={'flex-end'}>
              <BaseActionsButton color="danger" onClick={(e) => removeSelectedFromTable(index)}>
                <Tooltip title={<Trans>Delete account</Trans>}>
                  <Delete />
                </Tooltip>
              </BaseActionsButton>
            </GridItem>
          </GridContainer>
        );
      })}
      <div className={classes.addButton} onClick={addRowToSelectedTable}>
        Add change
      </div>
      <div className={classes.submitChanges}>
        <Button disabled={pristine || submitting || changeLoading} color={'primary'} type="submit">
          Save changes
        </Button>
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postWholesalerAvailabilityChange,
    },
    dispatch
  );
};

export default compose(
  reduxForm({
    enableReinitialize: true,
  }),
  withStyles(wholesalerAvailabilityPageStyle),
  connect((store) => {
    return {
      formData: getFormValues('wholesalerAvailabilityChange')(store),
    };
  }, mapDispatchToProps)
)(WholesalerAvailabilityChangeForm);
