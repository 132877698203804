// LOADER
export const UPDATE_LOADER_SUCCESS = 'UPDATE_LOADER_SUCCESS';
export const UPDATE_LOCAL_LOADER_SUCCESS = 'UPDATE_LOCAL_LOADER_SUCCESS';

// SETTINGS
export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS';
// FILTERS
export const UPDATE_FILTERS_STATE = 'UPDATE_FILTERS_STATE';
export const UPDATE_AUTH = 'UPDATE_AUTH';

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
