import {
  blueColor,
  container,
  containerFluid,
  defaultFont,
  grayColor,
  whiteColor,
} from '_assets/global/jss/styleHelpers';

const footerStyle = (theme) => ({
  block: {},
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  left: {
    float: 'left!important',
    display: 'block',
  },
  right: {
    margin: '0',
    fontSize: '14px',
    float: 'right!important',
    padding: '15px',
  },
  footer: {
    bottom: '0',
    borderTop: '1px solid ' + grayColor[15],
    padding: '15px 0',
    ...defaultFont,
    zIndex: 4,
  },
  container: {
    zIndex: 3,
    ...container,
    position: 'relative',
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: 'relative',
  },
  a: {
    color: blueColor[0],
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      color: blueColor[5],
    },
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0',
    width: 'auto',
  },
  whiteColor: {
    '&,&:hover,&:focus': {
      color: whiteColor,
    },
  },
  footerCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      textAlign: 'center',
      fontSize: 'smaller',
    },
  },
  langSwitch: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'black',
    padding: '5px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  langSwitchActive: {
    textDecoration: 'none !important',
    cursor: 'normal !important',
    color: 'grey',
  },
});

export default footerStyle;
