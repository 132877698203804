import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {globalApiErrorHandler} from '_helpers/global/functions';

export function postStammnummer(data) {
  return HttpClient.post(API_ENDPOINT_MAP.stammnummer, data)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      globalApiErrorHandler(error);
      return Promise.resolve(error);
    });
}

export function postTypenscheinnummer(data) {
  return HttpClient.post(API_ENDPOINT_MAP.typenscheinnummer, data)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      globalApiErrorHandler(error);
      return Promise.resolve(error);
    });
}
