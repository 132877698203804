import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {addUserPermission, getPermissions} from '_actions/projectSpecific/permissionsActions';
import {editUser, getUsers, impersonateUser} from '_actions/projectSpecific/usersActions';
import {iconTheme, usersPageStyle} from '_assets/projectSpecific/jss/views/usersPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card.jsx';
import CardBody from '_components/global/Card/CardBody.jsx';
import CardHeader from '_components/global/Card/CardHeader.jsx';
import CardIcon from '_components/global/Card/CardIcon.jsx';
import Dialog from '_components/global/Dialogs/Dialog';
import GridContainer from '_components/global/Grid/GridContainer.jsx';
import GridItem from '_components/global/Grid/GridItem.jsx';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import Tag from '_components/global/Tags/Tag';
import FilterUserForm from '_components/projectSpecific/Forms/FilterUserForm';
import PermissionsForm from '_components/projectSpecific/Forms/PermissionsForm';
import UserTableToolbar from '_components/projectSpecific/Toolbars/UsersTableToolbar';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
} from '_helpers/global/functions';
import cx from 'classnames';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {
  Block,
  CheckCircleOutline,
  Edit,
  Gamepad,
  Lens,
  Person,
  Visibility,
  VpnKey,
} from '@material-ui/icons';

const rows2 = [
  {
    id: 'empty',
    label: '',
    sortable: false,
  },
  {
    id: 'code',
    label: <Trans>Code</Trans>,
    sortable: false,
  },
  {
    id: 'Name',
    label: <Trans>Name</Trans>,
    sortable: false,
  },
];

const rows = [
  {
    id: 'id',
    label: <Trans>Id</Trans>,
    sortable: true,
  },
  {
    id: 'is_active',
    label: <Trans>Status</Trans>,
  },
  {
    id: 'name',
    label: <Trans>Name</Trans>,
    sortable: true,
  },
  {
    id: 'username',
    label: <Trans>Login name</Trans>,
    sortable: true,
  },
  {
    id: 'customer__customer_no',
    label: <Trans>Customer ID</Trans>,
    sortable: true,
  },
  {
    id: 'webuser_no',
    label: <Trans>Webuser No.</Trans>,
    sortable: true,
  },
  {
    id: 'branch__name',
    label: <Trans>Branch</Trans>,
    sortable: true,
  },
  {
    id: 'groups',
    label: <Trans>Role</Trans>,
    sortable: true,
  },
  {
    id: 'permissions_count',
    label: <Trans>Permissions</Trans>,
    sortable: false,
  },
  {
    id: 'actions',
    label: <Trans>Actions</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUsers,
      updateLoader,
      editUser,
      impersonateUser,
      getPermissions,
      addUserPermission,
      updateGlobalSettings,
    },
    dispatch
  );
};

class UsersPage extends Component {
  state = {
    order: 'asc',
    selected: [],
    page: 0,
    orderBy: this.props.UsersListFilters.orderBy,
    q: this.props.UsersListFilters.q,
    openPermissionsDialog: false,
    userPermissions: [],
    userIds: [],
    userName: '',
    openViewPermissions: false,
  };
  toastId = null;

  componentDidMount = () => {
    this.fetchUsers();
    this.fetchPermission();
  };

  fetchUsers = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getUsers(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order),
        this.state.q
      )
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  fetchPermission = () => {
    return this.props.getPermissions(0, 0);
  };

  handleRequestSort = (event, property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchUsers()
    );
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchUsers());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchUsers());
    });
  };

  handleOpenPermissionsDialog = (event, permissions, user) => {
    const listIds = [];
    listIds.push(user.id);
    event.preventDefault();
    this.setState({
      alert: null,
      openPermissionsDialog: true,
      userPermissions: permissions,
      userIds: listIds,
      userName: user.name,
    });
  };

  handleClosePermissionsDialog = () => {
    this.setState({
      openPermissionsDialog: false,
      userPermissions: [],
      userIds: [],
      userName: '',
    });
  };

  getUserPermissionsDialogInit = (permissions) => {
    const initValues = {};
    initValues.options = {};

    permissions.forEach((item) => {
      initValues.options[item.id] = true;
    });

    return initValues;
  };

  addUserDialogPermission = (values, id) => {
    const listPermissions = Object.keys(values.options)
      .filter((value) => values.options[value] === true)
      .map((value) => parseInt(value));
    this.props.updateLoader({globalLoading: true});
    return this.props
      .addUserPermission(id, listPermissions, true, false)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Permissions were added/edited.</Trans>);
        this.setState(
          {
            openPermissionsDialog: false,
          },
          () => this.fetchUsers()
        );
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
        this.setState({
          openPermissionsDialog: false,
        });
      });
  };

  enableUser = (id, name) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .editUser(id, {is_active: true})
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>User: {name ? name : ''} was enabled.</Trans>);
        this.fetchUsers();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  disableUser = (id, name) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .editUser(id, {is_active: false})
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>User: {name ? name : ''} was disabled.</Trans>);
        this.fetchUsers();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleViewPermissionsDialogOpen = (event, user) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      openViewPermissions: true,
      userPermissions: user.permissions,
      userName: user.name,
    });
  };

  handleViewPermissionsDialogClose = () => {
    this.setState({
      openViewPermissions: false,
      userPermissions: [],
      userName: '',
    });
  };

  filterUsers = (values) => {
    this.setState(
      {
        q: values.q,
      },
      () => this.fetchUsers()
    );
  };

  handleResetUserFiltering = () => {
    this.setState(
      {
        q: null,
      },
      () => this.fetchUsers()
    );
  };

  render() {
    const {classes} = this.props;
    const {order, page, orderBy} = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <b>
                  <Trans>Filter</Trans>
                </b>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <FilterUserForm
                form="filterUserForm"
                initialValues={this.props.UsersListFilters}
                onSubmit={this.filterUsers}
                handleReset={this.handleResetUserFiltering}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <Person />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Users</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <UserTableToolbar />
              <Dialog
                open={this.state.openPermissionsDialog}
                handleClose={this.handleClosePermissionsDialog}
                title={
                  <Trans>
                    Permissions for user
                    {this.state.userName ? this.state.userName : ''}
                  </Trans>
                }
                maxWidth={false}
                content={
                  <PermissionsForm
                    initialValues={this.getUserPermissionsDialogInit(this.state.userPermissions)}
                    form="editUserPermissionsDialog"
                    rows2={rows2}
                    options={this.props.permissions}
                    onSubmit={(values) => {
                      return this.addUserDialogPermission(values, this.state.userIds);
                    }}
                  />
                }
              />
              <Dialog
                open={this.state.openViewPermissions}
                handleClose={this.handleViewPermissionsDialogClose}
                title={<Trans>Permissions of {this.state.userName}</Trans>}
                content={
                  this.state.userPermissions &&
                  Array.isArray(this.state.userPermissions) &&
                  this.state.userPermissions.length &&
                  this.state.userPermissions.map((perm) => (
                    <Tag key={perm.id} content={perm.codename} />
                  ))
                }
              />
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <SimpleTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.users.length}
                    rows={rows}
                  />
                  <TableBody>
                    {this.props.users.map((user) => {
                      const {
                        id,
                        name,
                        customer,
                        username,
                        is_active,
                        oracle_id,
                        permissions,
                        groups,
                        branch,
                      } = user;

                      return (
                        <TableRow hover tabIndex={-1} key={id}>
                          <TableCell classes={{root: classes.cell}}>{id}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {is_active ? (
                              <MuiThemeProvider theme={iconTheme}>
                                <Lens color="secondary" className={classes.activeIcon} />
                              </MuiThemeProvider>
                            ) : (
                              <Lens color="secondary" className={classes.activeIcon} />
                            )}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>{name}</TableCell>
                          <TableCell classes={{root: classes.cell}}>{username}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {customer ? customer.customer_no : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>{oracle_id}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {branch ? branch.name : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {groups && Array.isArray(groups) && groups.length === 1
                              ? groups[0].name
                              : ''}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {permissions && permissions.length ? (
                              <Button
                                size="sm"
                                onClick={(e) => this.handleViewPermissionsDialogOpen(e, user)}
                              >
                                <Trans>View permissions</Trans> ...
                              </Button>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            <div className={cx('actions-right', classes.actions)}>
                              <BaseActionsButton
                                color="info"
                                component={Link}
                                to={`/admin/users/${id}`}
                              >
                                <Tooltip title={<Trans>Detail user</Trans>}>
                                  <Visibility />
                                </Tooltip>
                              </BaseActionsButton>
                              <Can I="update" a="Users">
                                <BaseActionsButton
                                  color="success"
                                  component={Link}
                                  to={`/admin/users/${id}/edit`}
                                >
                                  <Tooltip title={<Trans>Edit user</Trans>}>
                                    <Edit />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                              <Can I="update" a="UserPermissionMembership">
                                <BaseActionsButton
                                  color="primary"
                                  onClick={(event) =>
                                    this.handleOpenPermissionsDialog(event, permissions, user)
                                  }
                                >
                                  <Tooltip title={<Trans>Modify Permissions</Trans>}>
                                    <VpnKey />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                              <Can I="disable" a="Users">
                                {is_active ? (
                                  <BaseActionsButton
                                    color="danger"
                                    onClick={() => this.disableUser(id, name)}
                                  >
                                    <Tooltip title={<Trans>Disable user</Trans>}>
                                      <Block />
                                    </Tooltip>
                                  </BaseActionsButton>
                                ) : (
                                  <BaseActionsButton
                                    color="success"
                                    onClick={() => this.enableUser(id, name)}
                                  >
                                    <Tooltip title={<Trans>Enable user</Trans>}>
                                      <CheckCircleOutline />
                                    </Tooltip>
                                  </BaseActionsButton>
                                )}
                              </Can>
                              <Can I="read" a="ImpersonateUser">
                                <BaseActionsButton
                                  color="warning"
                                  onClick={() => this.props.impersonateUser(id)}
                                >
                                  <Tooltip title={<Trans>Remote control</Trans>}>
                                    <Gamepad />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <CustomTablePagination
                rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                count={this.props.total}
                rowsPerPage={this.props.globalSettings.itemsPerPage}
                page={page}
                changePage={this.handleChangePage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(usersPageStyle),
  connect((store) => {
    return {
      UsersListFilters: store.AppFilterStates.usersListFilter,
      users: store.Users.data,
      total: store.Users.total,
      globalSettings: store.GlobalSettings,
      permissions: store.Permissions.data,
    };
  }, mapDispatchToProps)
)(UsersPage);
