export const userAccountFormStyle = (theme) => ({
  accountForm: {
    margin: '0px 20px',
    paddingTop: '25px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
});
