import {
  dateTimePickerStyle,
  materialDateTimeDesktopTheme,
} from '_assets/global/jss/components/Pickers/dateTimePickerStyle';
import ClearButton from '_components/global/Buttons/ClearButton';
import cx from 'classnames';
import {DateTimePicker} from 'material-ui-pickers';
import moment from 'moment';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {MuiThemeProvider} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles from '@material-ui/core/styles/withStyles';

const CustomDateTimePicker = ({
  classes,
  dateTimeValue,
  onDateTimeChange,
  helpText,
  id,
  clearButton,
  formName,
  reduxName,
  dispatch,
}) => {
  const [selectedDateTime] = useState(moment());

  return (
    <div>
      <MuiThemeProvider theme={materialDateTimeDesktopTheme}>
        <FormControl className={classes.formControl}>
          <div className={cx('picker', classes.dateTimePicker)}>
            <div className={classes.endIcon}>
              <DateTimePicker
                keyboard
                autoOk={true}
                clearable
                format={'DD/MM/YYYY hh:mm'}
                mask={(value) =>
                  // handle clearing outside if value can be changed outside of the component
                  value
                    ? [
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        ' ',
                        /\d/,
                        /\d/,
                        ':',
                        /\d/,
                        /\d/,
                      ]
                    : []
                }
                value={dateTimeValue ? dateTimeValue : selectedDateTime}
                onChange={onDateTimeChange}
                disableOpenOnEnter
                animateYearScrolling={false}
              />
            </div>
            {clearButton === true && (
              <ClearButton formName={formName} reduxName={reduxName} dispatch={dispatch} />
            )}
          </div>
          {helpText !== undefined ? (
            <FormHelperText id={id + '-text'}>{helpText}</FormHelperText>
          ) : null}
        </FormControl>
      </MuiThemeProvider>
    </div>
  );
};

export default compose(withStyles(dateTimePickerStyle), connect())(CustomDateTimePicker);
