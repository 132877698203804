import dialogActionsStyle from '_assets/global/jss/components/Dialogs/dialogActionsStyle';
import cx from 'classnames';
import React from 'react';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {withStyles} from '@material-ui/core/styles';

const DialogActions = ({handleClose, classes, handleSubmit, submitName}) => {
  return (
    <MuiDialogActions className={classes.root}>
      <Button onClick={handleClose} className={cx(classes.button)}>
        Close
      </Button>
      <Button
        onClick={handleSubmit}
        onSubmit={handleSubmit}
        className={cx(classes.button, classes.primary)}
      >
        {submitName}
      </Button>
    </MuiDialogActions>
  );
};

export default withStyles(dialogActionsStyle)(DialogActions);
