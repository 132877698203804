import {
  FETCH_BRANCHES_FAIL,
  FETCH_BRANCHES_REQUEST,
  FETCH_BRANCHES_SUCCESS,
} from '_actions/projectSpecific/types';

const initBranchesState = {
  data: [],
};

export const BranchesReducer = (state = initBranchesState, action = null) => {
  switch (action.type) {
    case FETCH_BRANCHES_REQUEST:
      return {
        ...state,
      };

    case FETCH_BRANCHES_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
      };
    case FETCH_BRANCHES_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
