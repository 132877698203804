import {blueColor} from '_assets/global/jss/styleHelpers';

export const detailCartDialogStyle = (theme) => ({
  dialogContent: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  paperFullScreen: {
    margin: '30px',
    height: 'calc(100vh - 60px)',
    [theme.breakpoints.down('md')]: {
      margin: '25px',
      height: 'calc(100vh - 50px)',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
      height: 'calc(100vh - 20px)',
    },
  },
  customLink: {
    color: blueColor[0],
    textDecoration: 'none',
    '&:hover': {
      color: blueColor[5],
    },
  },
});
