import {HttpClient} from '_api/httpClient';
import {ENVIRONMENT} from '_config/configConstants';
import {loadedFaviconMiddleware} from 'App/faviconSetup';
import rootReducer from '_reducers';
import {applyMiddleware, compose, createStore} from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import thunk from 'redux-thunk';

const composeEnhancers =
  (ENVIRONMENT !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(axiosMiddleware(HttpClient), thunk, loadedFaviconMiddleware))
);

export default store;
