import {UPDATE_AUTH} from '_actions/global/types';
import Toast from '_components/global/Toasts/Toast';
import {browserHistoryAccessor} from 'App/history';
import store from 'App/store';
import React from 'react';
import {toast} from 'react-toastify';
import {Trans} from '@lingui/macro';

export const getComputedOrderBy = (orderBy, order) => {
  if (orderBy === null) {
    return null;
  }
  switch (order) {
    case 'asc':
      return orderBy;
    default:
      return `-${orderBy}`;
  }
};

export const prepareSortingState = (orderBy, order, property, suffix = '') => {
  let stateUpdate = {};
  let orderName = 'order' + suffix.charAt(0).toUpperCase() + suffix.slice(1);
  let orderByName = 'orderBy' + suffix.charAt(0).toUpperCase() + suffix.slice(1);

  if (orderBy === property) {
    if (order === 'asc') {
      stateUpdate = {[orderName]: 'desc', [orderByName]: property};
    } else {
      stateUpdate = {[orderName]: 'asc', [orderByName]: null};
    }
  } else {
    stateUpdate = {[orderName]: 'asc', [orderByName]: property};
  }
  return stateUpdate;
};

export const selectConcat = (id, selected) => {
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  return newSelected;
};

export const fireSuccessToast = (message, options) => {
  return toast.success(<Toast message={message} type="success" />, options);
};

export const fireErrorToast = (message, options = {autoClose: false}) => {
  return toast.error(<Toast message={message} type="error" />, options);
};

export const fireWarningToast = (message, options) => {
  return toast.warn(<Toast message={message} type="warning" />, options);
};

export const parseQueryString = (string) => {
  return string
    .slice(1)
    .split('&')
    .map((queryParam) => {
      let kvp = queryParam.split('=');
      return {key: kvp[0], value: kvp[1]};
    })
    .reduce((query, kvp) => {
      query[kvp.key] = kvp.value;
      return query;
    }, {});
};

export const actualUserSelector = (authReducer) => {
  if (Object.keys(authReducer.impersonated).length !== 0) {
    return authReducer.impersonated;
  }
  return authReducer.logged;
};

export const globalApiErrorHandler = (error) => {
  if (error.response) {
    let response = error.response;
    switch (response.status) {
      case 400:
        console.error(response.data.error_message);
        break;
      case 401:
        localStorage.removeItem('jwtToken');
        store.dispatch({
          type: UPDATE_AUTH,
          data: {
            logged: {},
            impersonated: {},
          },
        });
        browserHistoryAccessor.push('/auth/sign-in');
        break;
      case 403:
        fireErrorToast(<Trans>Not allowed</Trans>);
        break;
      case 404:
        fireErrorToast(<Trans>Not found</Trans>);
        break;
      case 500:
        fireErrorToast(<Trans>Server error</Trans>);
        break;
      default:
        break;
    }
  }
};
