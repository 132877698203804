import {UPDATE_LOADER_SUCCESS, UPDATE_LOCAL_LOADER_SUCCESS} from '_actions/global/types';

export function updateLoader(data) {
  return function (dispatch) {
    return dispatch({
      type: UPDATE_LOADER_SUCCESS,
      data: data,
    });
  };
}

export function updateLocalLoader(data) {
  return function (dispatch) {
    return dispatch({
      type: UPDATE_LOCAL_LOADER_SUCCESS,
      data: data,
    });
  };
}
