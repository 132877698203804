import {dangerColor, successColor} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const dateTimePickerStyle = () => ({
  dateTimePicker: {
    width: '100%',
    position: 'relative',
  },
  helperTextMargin: {
    margin: 0,
  },
  labelRootError: {
    color: dangerColor[0] + ' !important',
  },
  labelRootSuccess: {
    color: successColor[0] + ' !important',
  },
  endIcon: {
    '& svg': {
      position: 'absolute',
      top: 'calc(50% - 12px)',
      right: '0px',
    },
  },
});

export const materialDateTimeDesktopTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiInput: {
      root: {
        fontSize: '14px',
        marginTop: '0',
      },
      formControl: {
        marginTop: '10px !important',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        margin: '10px auto',
      },
    },
    MuiFormLabel: {
      root: {
        top: '-10px !important',
        fontSize: '14px',
      },
    },
  },
});

export const materialDateTimeMobileTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiInput: {
      root: {
        fontSize: '14px',
      },
      formControl: {
        marginTop: '0',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        margin: '10px auto',
      },
    },
    MuiFormLabel: {
      root: {
        top: '10px',
        fontSize: '14px',
      },
    },
  },
});
