import {customSimpleSelectStyle} from '_assets/global/jss/components/Fields/customSimpleSelectStyle';
import ClearButton from '_components/global/Buttons/ClearButton';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';

const CustomSimpleSelect = ({
  classes,
  customSelectClasses,
  label,
  selectedValue,
  handleSelect,
  options,
  htmlFor,
  onChange,
  labelProps,
  required,
  helpText,
  id,
  clearButton,
  formName,
  reduxName,
  dispatch,
}) => {
  return (
    <FormControl className={classes.formControl} required={required}>
      <InputLabel htmlFor={htmlFor} {...labelProps} className={classes.inputLabel}>
        {label}
      </InputLabel>
      <Select
        className={customSelectClasses}
        value={selectedValue}
        onChange={handleSelect}
        input={<Input id={id} placement="bottom" onChange={onChange} />}
      >
        {options.map((item) =>
          item.label ? (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ) : (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          )
        )}
      </Select>
      {clearButton === true && (
        <ClearButton formName={formName} reduxName={reduxName} dispatch={dispatch} />
      )}
      {helpText !== undefined ? (
        <FormHelperText id={id + '-text'}>{helpText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

CustomSimpleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(customSimpleSelectStyle), connect())(CustomSimpleSelect);
