import {detailCartFormStyle} from '_assets/projectSpecific/jss/components/Forms/detailCartFormStyle';
import cx from 'classnames';
import React, {useState} from 'react';
import Popover from '@material-ui/core/Popover';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Launch} from '@material-ui/icons';

const AdditionalCartItemRow = ({cartItem, cartIndex, classes}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElKey, setAnchorElKey] = useState(null);

  const handlePopoverOpen = (event, cartIndex) => {
    setAnchorEl(event.currentTarget);
    setAnchorElKey(cartIndex);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setAnchorElKey(null);
  };

  const {active_no, catalogue_url, description, ordered, price, product_no} = cartItem;

  return (
    <TableRow hover tabIndex={-1} key={`additional-${cartIndex}`}>
      <TableCell classes={{root: classes.cell}}>
        <div className={cx(classes.firstRow, classes.flexSpace)}>
          <Tooltip title={catalogue_url ? catalogue_url : ''}>
            <a
              href={catalogue_url}
              target="blank"
              className={cx(classes.smallIcon, classes.margin)}
            >
              <div className={classes.activeNumber}>
                <span>{active_no}</span>
                <span>{product_no}</span>
              </div>
              <Launch fontSize="small" />
            </a>
          </Tooltip>
        </div>
        <div className={classes.lastRow}>
          {description && description.length > 30 ? (
            <div className={classes.description}>
              <Typography
                aria-owns={
                  cartIndex === anchorElKey ? `mouse-over-popover-${cartIndex}` : undefined
                }
                aria-haspopup="true"
                onMouseEnter={(event) => handlePopoverOpen(event, cartIndex)}
                onMouseLeave={handlePopoverClose}
              >
                {description.slice(0, 30)}...
              </Typography>
              <Popover
                id={`mouse-over-popover-${cartIndex}`}
                className={classes.popover}
                open={cartIndex === anchorElKey}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>{description}</Typography>
              </Popover>
            </div>
          ) : (
            <span>{description}</span>
          )}
        </div>
      </TableCell>
      <TableCell classes={{root: classes.cell}}></TableCell>
      <TableCell classes={{root: classes.cell}}></TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div>{price}</div>
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div>{ordered}</div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(detailCartFormStyle)(AdditionalCartItemRow);
