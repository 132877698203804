import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {
  FETCH_DISCOUNT_GROUPS_REQUEST,
  FETCH_DISCOUNT_GROUPS_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/global/functions';
import {SubmissionError} from 'redux-form';

export function getDiscountGroups(offset = 0, limit = 20) {
  return function (dispatch) {
    dispatch({type: FETCH_DISCOUNT_GROUPS_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.discountGroups, {
      offset: offset,
      limit: limit,
    })
      .then((res) => {
        dispatch({
          type: FETCH_DISCOUNT_GROUPS_SUCCESS,
          data: res.data,
          total: res.data.meta.total_count,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function addDiscountGroup() {
  return function (dispatch) {
    return HttpClient.post(API_ENDPOINT_MAP.discountGroups).catch((error) => {
      globalApiErrorHandler(error);
    });
  };
}

export function deleteDiscountGroup(discount_group_id) {
  return function (dispatch) {
    return HttpClient.del(`${API_ENDPOINT_MAP.discountGroups}${discount_group_id}/`)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}
