const accountsTableToolbarStyle = (theme) => ({
  root: {
    padding: 0,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  customIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  },
});

export default accountsTableToolbarStyle;
