import {createMuiTheme} from '@material-ui/core';

const tablePaginationStyle = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiToolbar: {
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
      },
    },
    MuiTablePagination: {
      toolbar: {
        padding: '0px !important',
      },
    },
  },
});

export default tablePaginationStyle;
