import {successColor} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const customCheckboxStyle = () => ({
  root: {
    '&$checked': {
      color: successColor[0],
    },
  },
  checked: {},
  helperTextMargin: {
    position: 'absolute',
    top: '35px',
    left: '35px',
  },
  noHelperTextMargin: {
    left: '0px',
  },
  formControl: {
    position: 'relative',
    margin: '10px auto',
    width: '100%',
  },
  formControlTable: {
    margin: '0px auto',
  },
});

export const tableSelectTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: '0px',
      },
    },
  },
});
