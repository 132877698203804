import {activationBlue} from '_assets/global/jss/styleHelpers';

const activationStyle = (theme) => ({
  activationContainer: {
    minHeight: '100vh',
    maxWidth: '100vw',
    width: '100%',
    margin: 0,
    padding: 0,
  },
  blackContainer: {
    backgroundColor: 'black',
    height: '220px',
    maxWidth: '100wv',
    paddingLeft: '60px',
    width: '100%',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '40px',
    },
  },
  activationText: {
    lineHeight: 1,
    position: 'absolute',
    width: 'calc(100vw - 150px)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 110px)',
    },
  },
  footerContainer: {
    justifyContent: 'center',
    alignContent: 'flex-end',
    maxWidth: '100wv',
    width: '100%',
    margin: 0,
    padding: 0,
  },
  formContainer: {
    maxWidth: '1000px',
    width: '100%',
    margin: 0,
    padding: '60px 60px 0',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      padding: '40px 40px 0',
    },
  },
  noPadding: {
    grid: {
      padding: 0,
    },
  },
  activationField: {
    borderRadius: 0,
    minWidth: 280,
    border: '2px solid',
    borderColor: '#aaaaaa',
    '&:hover, &:focus, &:focus-within': {
      borderColor: activationBlue,
    },
    '& input': {
      fontFamily: 'Campton',
      fontWeight: 400,
      fontSize: '18px',
      borderRadius: 0,
      height: '50px',
      padding: '12px 14px',
      boxSizing: 'border-box',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 240,
    },
  },
  activationButton: {
    backgroundColor: activationBlue + ' !important',
    borderRadius: 0,
    marginTop: '30px',
    minHeight: '50px',
    minWidth: 280,
    fontFamily: 'Campton',
    fontWeight: 400,
    fontSize: '16px',
    '& svg': {
      margin: 0,
      width: 24,
      height: 24,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 240,
    },
  },
  activationLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formCard: {
    maxWidth: '400px',
    flexGrow: 1,
    padding: 20,
    textAlign: 'center',

    '& svg': {
      maxHeight: 50,
      width: '100%',
    },
  },
  loadingCircle: {
    '& svg': {
      maxHeight: '100px !important',
    },
  },
});

export default activationStyle;
