import {filterCustomersDTMembershipFormStyle} from '_assets/projectSpecific/jss/components/Forms/filterCustomersDTMembershipFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Replay, Search} from '@material-ui/icons';

const FilterCustomersDTMembershipForm = ({
  classes,
  handleSubmit,
  pristine,
  submitting,
  form,
  // deliveryOptions,
  // handleExportFiltered,
  handleReset,
  reset,
}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.flex}>
      <GridContainer className={classes.cartFormFields}>
        <GridItem xs={12} md={6}>
          <Field
            name="CustomerNameNo"
            label={<Trans>Customer ID/name</Trans>}
            id="CustomerNameNo"
            component={FormTextField}
            clearButton={true}
            reduxName="CustomerNameNo"
            formName={form}
          />
        </GridItem>
        {/*<GridItem xs={12} md={6}>*/}
        {/*<Field*/}
        {/*name="deliveryOptionId"*/}
        {/*label={(<Trans>Delivery terms</Trans>)}*/}
        {/*id="deliveryOptionId"*/}
        {/*type="select"*/}
        {/*component={FormSelectField}*/}
        {/*options={deliveryOptions}*/}
        {/*clearButton={true}*/}
        {/*reduxName="deliveryOptionId"*/}
        {/*formName={form}*/}
        {/*/>*/}
        {/*</GridItem>*/}
      </GridContainer>
      <GridContainer className={classes.cartButton}>
        <Tooltip title={<Trans>Find</Trans>}>
          <Button color="primary" type="submit">
            <Search />
          </Button>
        </Tooltip>
        <Tooltip title={<Trans>Reset filter</Trans>}>
          <Button
            onClick={() => {
              reset();
              handleReset();
            }}
            color="primary"
          >
            <Replay />
          </Button>
        </Tooltip>
        {/*<Tooltip title={(<Trans>Download filtered</Trans>)}>*/}
        {/*<Button*/}
        {/*color="primary"*/}
        {/*onClick={handleExportFiltered}*/}
        {/*>*/}
        {/*<CloudDownload/>*/}
        {/*</Button>*/}
        {/*</Tooltip>*/}
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(filterCustomersDTMembershipFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(FilterCustomersDTMembershipForm);
