import React from 'react';
import {TextField} from '@material-ui/core';

const StringField = (props) => {
  const {
    type,
    id,
    label,
    help_text,
    input,
    meta: {touched, invalid, error},
    ...rest
  } = props;

  return (
    <TextField
      fullWidth
      label={label}
      inputProps={{value: input.value, onChange: input.onChange}}
      error={touched && invalid}
      helperText={touched && error ? error : help_text}
      {...input}
      {...rest}
    />
  );
};

export default StringField;
