import {accountFormStyle} from '_assets/projectSpecific/jss/components/Forms/accountFormStyle';
import Button from '_components/global/Buttons/Button';
import {
  FormCheckbox,
  FormSelectField,
  FormSwitcher,
  FormTextField,
} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {
  email,
  maxLength256,
  required,
  validatePasswordPolicies,
  validateRepeatingPassword,
} from '_helpers/global/FieldLevelValidation';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {withStyles} from '@material-ui/core/styles';
import {Person, Visibility, VisibilityOff} from '@material-ui/icons';

const AccountForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  submitName,
  form,
  branchesOptions,
  roleOptions,
  classes,
  authType,
}) => {
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  return (
    <form onSubmit={handleSubmit} className={classes.userForm}>
      <GridContainer justify="center">
        <GridItem xs={12} md={9}>
          <Field
            name="name"
            label={<Trans>Name</Trans>}
            id="name"
            component={FormTextField}
            validate={maxLength256}
          />
        </GridItem>
        <Can I="changeAuthType" a="Accounts">
          <GridItem xs={12} md={9}>
            <Field
              name="auth_type"
              component={FormSwitcher}
              helpText={<Trans>Help text for setting auth type</Trans>}
              valueA="Local type"
              valueB="Oracle type"
            />
          </GridItem>
        </Can>
        <GridItem xs={12} md={9}>
          <Field
            name="username"
            label={<Trans>Login name</Trans>}
            id="username"
            additionalInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled aria-label="username">
                    <Person />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            component={FormTextField}
            validate={form === 'createAccount' ? required : []}
            required={form === 'createAccount'}
          />
        </GridItem>
        {!authType ? (
          <>
            <GridItem xs={12} md={9}>
              <Field
                name="password"
                label={<Trans>Password</Trans>}
                type={values.showPassword ? 'text' : 'password'}
                additionalInputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={FormTextField}
                validate={form === 'createAccount' ? validatePasswordPolicies : []}
                required={form === 'createAccount'}
              />
            </GridItem>
            <GridItem xs={12} md={9}>
              <Field
                name="repeatPassword"
                label={<Trans>Repeat password</Trans>}
                type={values.showPassword ? 'text' : 'password'}
                additionalInputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={FormTextField}
                validate={form === 'createAccount' ? [required, validateRepeatingPassword] : []}
                required={form === 'createAccount'}
              />
            </GridItem>
          </>
        ) : (
          ''
        )}
        <GridItem xs={12} md={9}>
          <Field
            name="email"
            label={<Trans>Email</Trans>}
            id="email"
            type="email"
            validate={[email, maxLength256]}
            component={FormTextField}
          />
        </GridItem>
        <GridItem xs={12} md={9}>
          <Field
            name="branch"
            htmlFor="branch"
            label={<Trans>Branch</Trans>}
            id="branch"
            type="select"
            component={FormSelectField}
            options={branchesOptions}
          />
        </GridItem>
        {/*<GridItem xs={12} md={9}>*/}
        {/*  <Field*/}
        {/*    name="groups"*/}
        {/*    htmlFor="role"*/}
        {/*    label={(<Trans>Role</Trans>)}*/}
        {/*    id="groups"*/}
        {/*    type="select"*/}
        {/*    component={FormSelectField}*/}
        {/*    options={roleOptions}*/}
        {/*  />*/}
        {/*</GridItem>*/}
        <GridItem xs={12} md={9}>
          <Field
            name="enable_notification"
            component={FormCheckbox}
            label={<Trans>Send Notifications</Trans>}
          />
        </GridItem>
        <GridItem xs={12}>
          <div className={classes.formActions}>
            <Button
              onClick={reset}
              className={cx(classes.button)}
              disabled={pristine || submitting}
            >
              <Trans>Reset</Trans>
            </Button>
            <Button
              className={cx(classes.button, classes.blue)}
              disabled={pristine || submitting}
              type="submit"
            >
              {submitName}
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(accountFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(AccountForm);
