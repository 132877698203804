import React, {Fragment} from 'react';
import {Trans} from '@lingui/macro';

export const required = (value) => (value ? undefined : 'Required field');

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const aol = (value) =>
  value && /.+@aol\.com/.test(value) ? 'You still use AOL for your email?' : undefined;

export const decimal = (value) =>
  value && !/^[-+]?[0-9]+\.[0-9]+$/i.test(value)
    ? 'Must be a number with two decimal places'
    : undefined;

export const numberVal = (value) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

const maxLength = (max, value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength256 = (value) => maxLength(256, value);
export const maxLength32 = (value) => maxLength(32, value);
export const maxLength128 = (value) => maxLength(128, value);
export const maxLength512 = (value) => maxLength(512, value);
export const maxLength255 = (value) => maxLength(255, value);
export const maxLength100 = (value) => maxLength(100, value);

const minMaxLength = (min, max, value) =>
  value && (value.length < min || value.length > max)
    ? `Must be from ${min} to ${max} characters`
    : undefined;

export const minMaxLengthPostCode = (value) => minMaxLength(4, 5, value);

export const validateRepeatingPassword = (value, values) =>
  value !== values.password && <Trans>Passwords do not match!</Trans>;

const minMaxValue = (min, max, value) =>
  value && isNaN(Number(value)) && (value < min || value > max)
    ? `Must be a number from ${min} to ${max}`
    : undefined;

export const DiscountGroupsValidation = (value) => minMaxValue(0, 100, value);
export const MinutesToUnlockValidation = (value) => minMaxValue(0, 1000, value);

export const validatePasswordPolicies = (value) => {
  if (!value || value?.length < 8) {
    return <Trans>Password must be at least 8 characters long!</Trans>;
  }

  // There is plus, so we can show all string characters '"` in one line
  const specialCharacters = '!@#$%^&*()_+|~-=\\`{}[]:"' + ";'<>?,./";

  // Errors to show as field validation
  const errors = [];

  const containsUppercase = /^(?=.*[A-Z]).*$/;
  if (!containsUppercase.test(value)) {
    errors.push(<Trans>at least one uppercase character</Trans>);
  }

  const containsLowercase = /^(?=.*[a-z]).*$/;
  if (!containsLowercase.test(value)) {
    errors.push(<Trans>at least one lowercase character</Trans>);
  }

  const containsNumber = /^(?=.*[0-9]).*$/;
  if (!containsNumber.test(value)) {
    errors.push(<Trans>at least one number</Trans>);
  }

  // Escape all necessary characters
  const containsSpecialChar = /^(?=.*[!@#$%^&*()_+|~\-=\\`{}\[\]:";'<>?,.\/]).*$/;
  if (!containsSpecialChar.test(value)) {
    errors.push(<Trans>at least one special character ({specialCharacters})</Trans>);
  }

  return (
    // Unfortunately this show key Warning, but I was not able to prevent it
    // If more than one missing policy then show error
    errors.length > 1 && (
      <>
        {/* Beginning of password sentence */}
        {errors.length && <Trans>Password must contain</Trans>}{' '}
        {/* If two errors, show OR, otherwise AND*/}
        {errors.length === 2 ? (
          <>
            {errors[0]} {<Trans>or</Trans>} {errors[1]}
            {'!'}
          </>
        ) : (
          errors.map((e, i) => (
            <Fragment key={i}>
              {e}
              {i !== errors.length - 1 ? (
                i === 1 ? (
                  <>
                    {' '}
                    <Trans>and</Trans>{' '}
                  </>
                ) : (
                  ', '
                )
              ) : (
                '!'
              )}
            </Fragment>
          ))
        )}
      </>
    )
  );
};
