import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {
  getWholesalerAvailabilityHistory,
  getWholesalerAvailabilityHistoryDownload,
  getWholesalerAvailabilityTemplateFile,
  postWholesalerAvailability,
} from '_actions/projectSpecific/wholesalerAvailabilityActions';
import {wholesalerAvailabilityPageStyle} from '_assets/projectSpecific/jss/views/wholesalerAvailabilityPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import Dialog from '_components/global/Dialogs/Dialog';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import WholesalerAvailabilityChangeForm from '_components/projectSpecific/Forms/WholesalerAvailabilityChangeForm';
import {DATE_TIME_FORMAT} from '_config/configConstants';
import {
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
} from '_helpers/global/functions';
import cx from 'classnames';
import download from 'downloadjs';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import {Trans} from '@lingui/macro';
import {Grid, withStyles} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {AccountTree, Lens} from '@material-ui/icons';
import {CloudDownload} from '@material-ui/icons';

const WholesalerAvailabilityPage = (props) => {
  const {
    classes,
    postWholesalerAvailability,
    getWholesalerAvailabilityHistory,
    wholesalerFilter,
    wholesalerHistoryData,
    wholesalerHistoryTotal,
    globalSettings,
    updateGlobalSettings,
    updateLoader,
    getWholesalerAvailabilityHistoryDownload,
    getWholesalerAvailabilityTemplateFile,
  } = props;

  const [importErrors, setImportErrors] = useState({visible: false, errors: []});
  const [order, setOrder] = useState({
    order: 'asc',
    orderBy: wholesalerFilter?.orderBy,
  });
  const [page, setPage] = useStateWithCallbackLazy(0);
  const [changeDialog, setChangeDialog] = useState({visible: false, data: []});

  const handleCloseChangeDialog = () => {
    setChangeDialog({visible: false, data: []});
  };
  const handleOpenChangeDialog = () => {
    setChangeDialog({visible: true, data: [{product_number: '', quantity: ''}]});
  };

  const fileInput = useRef(null);

  const rows = [
    {
      id: 'timestamp',
      label: <Trans>Timestamp</Trans>,
      sortable: true,
    },
    {
      id: 'type',
      label: <Trans>Type</Trans>,
      sortable: true,
    },
    {
      id: 'validation_passed',
      label: <Trans>Validation passed</Trans>,
      sortable: true,
    },
    {
      id: 'file_composed',
      label: <Trans>File composed</Trans>,
      sortable: true,
    },
    {
      id: 'sent_to_vpom',
      label: <Trans>Sent to VPOM</Trans>,
      sortable: true,
    },
    {
      id: 'completed_in_vpom',
      label: <Trans>Completed in VPOM</Trans>,
      sortable: true,
    },
    {
      id: 'vpom_import_status',
      label: <Trans>VPOM import status</Trans>,
    },
    {
      id: 'vpom_import_id',
      label: <Trans>VPOM import id</Trans>,
    },
    {
      id: 'vpom_import_date',
      label: <Trans>VPOM import date</Trans>,
    },
    {
      id: 'filename',
      label: <Trans>Filename</Trans>,
    },
    {
      id: 'actions',
      label: <Trans>Actions</Trans>,
    },
  ];

  const handleChangeFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    let file = e.target.files[0];
    file && reader.readAsDataURL(file);

    reader.onloadend = () => {
      return postWholesalerAvailability(file)
        .then((res) => {
          fetchWholesalerAvailabilityHistory();
          fireSuccessToast(<Trans>File was uploaded.</Trans>);
        })
        .catch((err) => {
          fireErrorToast(<Trans>File upload failed.</Trans>);
          if (err.response?.data?.error_list) {
            setImportErrors({
              visible: true,
              errors: err.response?.data?.wholeselleravailabilitychange,
            });
          }
        });
    };
    reader.onerror = (error) => fireErrorToast(error);
  };

  const handleImportClick = () => {
    fileInput.current.click();
  };

  const handleCloseErrorImportDialog = () => {
    setImportErrors({visible: false, errors: []});
  };

  const fetchWholesalerAvailabilityHistory = () => {
    updateLoader({globalLoading: true});
    getWholesalerAvailabilityHistory(
      page * globalSettings.itemsPerPage,
      globalSettings.itemsPerPage,
      getComputedOrderBy(order.orderBy, order.order)
    )
      .catch((err) => console.error(err))
      .finally(() => {
        updateLoader({globalLoading: false});
      });
  };

  const handleRequestSort = (event, property) => {
    const newOrder = prepareSortingState(order.orderBy, order.order, property);
    setOrder(newOrder);
  };
  const handleChangePage = (event, page, value) => {
    setPage(page, () => fetchWholesalerAvailabilityHistory());
  };

  const handleChangeRowsPerPage = (event) => {
    updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      setPage(0, () => fetchWholesalerAvailabilityHistory());
    });
  };

  const handleDownloadWAHistory = (id) => {
    return getWholesalerAvailabilityHistoryDownload(id).then((res) => {
      download(res.data, res.headers['x-filename'], res.headers['content-type']);
    });
  };

  const addRowToSelectedTable = () => {
    setChangeDialog((prevState) => ({
      ...prevState,
      data: [...prevState.data, {product_number: '', quantity: 0}],
    }));
  };

  const removeSelectedFromTable = (index) => {
    setChangeDialog((prevState) => ({
      ...prevState,
      data: [...prevState.data.filter((change, i) => i !== index)],
    }));
  };

  const handleDownloadTemplate = () => {
    return getWholesalerAvailabilityTemplateFile().then((res) => {
      download(res.data, res.headers['x-filename'], res.headers['content-type']);
    });
  };

  useEffect(() => {
    fetchWholesalerAvailabilityHistory();
  }, [page, globalSettings, order]);

  return (
    <GridContainer>
      {/* IMPORT ERROR REPORT */}
      <Dialog
        open={importErrors.visible}
        handleClose={handleCloseErrorImportDialog}
        title={<Trans>Errors which occurred during the import</Trans>}
        maxWidth={false}
        content={
          <div>
            {importErrors.errors.length > 0 &&
              Object.entries(importErrors.errors).forEach(([key, value], index) => {
                return <div key={index}>{`${key} - ${value}`}</div>;
              })}
          </div>
        }
      />

      {/* CHANGE AVAILABILITY */}
      <Dialog
        open={changeDialog.visible}
        handleClose={handleCloseChangeDialog}
        title={<Trans>Change selected availabilities</Trans>}
        maxWidth={'sm'}
        fullWidth={true}
        content={
          <WholesalerAvailabilityChangeForm
            fetchWholesalerAvailabilityHistory={fetchWholesalerAvailabilityHistory}
            handleClose={handleCloseChangeDialog}
            form={'wholesalerAvailabilityChange'}
            data={changeDialog.data}
            addRowToSelectedTable={addRowToSelectedTable}
            removeSelectedFromTable={removeSelectedFromTable}
          />
        }
      />

      <GridItem xs={12}>
        <Card>
          <CardHeader color="blue" icon>
            <CardIcon color="blue">
              <AccountTree />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <Trans>Wholesaler availability</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={12}>
                <div className={cx(classes.fieldPadding, classes.title)}>
                  <Trans>Change availabilities</Trans>
                </div>
              </Grid>

              <Grid item xs={10} container alignItems={'center'} className={classes.gridRowBorder}>
                <Trans>Replace all availabilities with a file</Trans>
                <sup>*</sup>&nbsp;(
                <a onClick={handleDownloadTemplate} className={classes.downloadTemplateLink}>
                  <Trans>download template file</Trans>
                </a>
                )
              </Grid>

              <Grid item xs={2} container justify={'flex-end'} className={classes.gridRowBorder}>
                <>
                  <Tooltip title={<Trans>Import file with wholesaler availability</Trans>}>
                    <Button
                      className={classes.minimalButtonLength}
                      size={'sm'}
                      onClick={handleImportClick}
                    >
                      <Trans>Import</Trans>
                    </Button>
                  </Tooltip>
                  <input
                    style={{display: 'none'}}
                    onChange={handleChangeFile}
                    onClick={(e) => (e.target.value = null)}
                    ref={fileInput}
                    type="file"
                    accept="text/csv,
                            application/vnd.ms-excel,
                            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </>
              </Grid>

              <Grid item xs={10} container alignItems={'center'} className={classes.gridRowBorder}>
                <Trans>Change selected availabilities</Trans>
              </Grid>
              <Grid item xs={2} container justify={'flex-end'} className={classes.gridRowBorder}>
                <Button
                  className={classes.minimalButtonLength}
                  size={'sm'}
                  onClick={handleOpenChangeDialog}
                >
                  <Trans>Change</Trans>
                </Button>
              </Grid>

              <Grid item xs={12} className={classes.dividerTop}>
                <sup>*</sup> -{' '}
                <Trans>
                  The file should not exceed 1MB of size and should not contain records with
                  quantity less or equal to 0.
                </Trans>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <CardHeader color="blue" icon>
            <CardIcon color="blue">
              <b>
                <Trans>History</Trans>
              </b>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <SimpleTableHead
                  order={order.order}
                  orderBy={order.orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={wholesalerHistoryData.length}
                  rows={rows}
                />
                <TableBody>
                  {wholesalerHistoryData.map((history, index) => {
                    const {
                      filename,
                      file_composed,
                      sent_to_vpom,
                      vpom_import_data,
                      validation_passed,
                      type,
                      timestamp,
                      id,
                      completed_in_vpom,
                    } = history;
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell classes={{root: classes.cell}}>
                          {moment(timestamp).format(DATE_TIME_FORMAT)}
                        </TableCell>
                        <TableCell classes={{root: classes.cell}}>{type}</TableCell>
                        <TableCell
                          classes={{root: classes.cell}}
                          className={classes.textAlignCenter}
                        >
                          <Lens
                            className={
                              validation_passed ? classes.activeIcon : classes.nonActiveIcon
                            }
                          />
                        </TableCell>
                        <TableCell
                          classes={{root: classes.cell}}
                          className={classes.textAlignCenter}
                        >
                          <Lens
                            className={file_composed ? classes.activeIcon : classes.nonActiveIcon}
                          />
                        </TableCell>
                        <TableCell
                          classes={{root: classes.cell}}
                          className={classes.textAlignCenter}
                        >
                          <Lens
                            className={sent_to_vpom ? classes.activeIcon : classes.nonActiveIcon}
                          />
                        </TableCell>
                        <TableCell
                          classes={{root: classes.cell}}
                          className={classes.textAlignCenter}
                        >
                          <Lens
                            className={
                              completed_in_vpom ? classes.activeIcon : classes.nonActiveIcon
                            }
                          />
                        </TableCell>
                        <TableCell classes={{root: classes.cell}}>
                          {vpom_import_data?.vpom_import_status}
                        </TableCell>
                        <TableCell classes={{root: classes.cell}}>
                          {vpom_import_data?.vpom_import_id}
                        </TableCell>
                        <TableCell classes={{root: classes.cell}}>
                          {vpom_import_data?.vpom_import_date &&
                            moment(vpom_import_data.vpom_import_date).format(DATE_TIME_FORMAT)}
                        </TableCell>
                        <TableCell classes={{root: classes.cell}}>{filename}</TableCell>
                        <TableCell>
                          {file_composed && (
                            <div className={cx('actions-right', classes.actions)}>
                              <BaseActionsButton
                                color="info"
                                onClick={() => handleDownloadWAHistory(id)}
                              >
                                <Tooltip title={<Trans>Download</Trans>}>
                                  <CloudDownload />
                                </Tooltip>
                              </BaseActionsButton>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <CustomTablePagination
              rowsPerPageOptions={globalSettings.rowsPerPageOptions}
              count={wholesalerHistoryTotal}
              rowsPerPage={globalSettings.itemsPerPage}
              page={page}
              changePage={handleChangePage}
              changeRowsPerPage={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

WholesalerAvailabilityPage.propTypes = {
  classes: PropTypes.object,
  postWholesalerAvailability: PropTypes.func,
  getWholesalerAvailabilityHistory: PropTypes.func,
  wholesalerFilter: PropTypes.object,
  wholesalerHistoryLoading: PropTypes.bool,
  wholesalerHistoryData: PropTypes.array,
  wholesalerHistoryTotal: PropTypes.number,
  globalSettings: PropTypes.object,
  updateGlobalSettings: PropTypes.func,
  updateLoader: PropTypes.func,
  getWholesalerAvailabilityHistoryDownload: PropTypes.func,
  getWholesalerAvailabilityTemplateFile: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postWholesalerAvailability,
      getWholesalerAvailabilityHistory,
      updateGlobalSettings,
      updateLoader,
      getWholesalerAvailabilityHistoryDownload,
      getWholesalerAvailabilityTemplateFile,
    },
    dispatch
  );
};

export default compose(
  withStyles(wholesalerAvailabilityPageStyle),
  connect((store) => {
    return {
      wholesalerFilter: store.AppFilterStates.wholesalerAvailabilityFilter,
      wholesalerHistoryLoading: store.WholesalerAvailability.loading,
      wholesalerHistoryData: store.WholesalerAvailability.data,
      wholesalerHistoryTotal: store.WholesalerAvailability.total,
      globalSettings: store.GlobalSettings,
    };
  }, mapDispatchToProps)
)(WholesalerAvailabilityPage);
