import {createMuiTheme} from '@material-ui/core';

export const textFieldTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '14px',
      },
    },
    MuiFormControl: {
      root: {
        margin: '10px auto',
      },
    },
  },
});

export const formFieldStyle = () => ({
  textClearButton: {
    top: '20px',
  },
});
