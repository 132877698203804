import {
  TableHeadStyle,
  tableSelectTheme,
} from '_assets/global/jss/components/Tables/tableHeadStyle';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';

class CustomTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {onSelectAllClick, order, numSelected, rowCount, orderBy, rows, classes} = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell classes={{root: classes.cell}}>
            <MuiThemeProvider theme={tableSelectTheme}>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </MuiThemeProvider>
          </TableCell>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                sortDirection={order}
                classes={{root: cx({[classes.actions]: row.id === 'actions'}, classes.cell)}}
              >
                {row.sortable && (
                  <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                    <TableSortLabel
                      active={row.order_key ? orderBy === row.order_key : orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.order_key ? row.order_key : row.id)}
                    >
                      <strong className={classes.sorting}>{row.label}</strong>
                    </TableSortLabel>
                  </Tooltip>
                )}
                {!row.sortable && row.label}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

CustomTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
};

export default withStyles(TableHeadStyle)(CustomTableHead);
