import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import footerStyle from '_assets/global/jss/components/Footer/footerStyle';
import footerSVG from '_assets/global/svg/footer.svg';
import {LANGUAGES} from '_config/configConstants';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {parse} from 'query-string';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import withStyles from '@material-ui/core/styles/withStyles';

function Footer({...props}) {
  const {classes, fluid, white, updateGlobalSettings, globalSettings} = props;
  let container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  let anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white,
    });

  const removeSearchParam = () => {
    const search = window.location.search;
    let {language} = parse(search);
    if (language) {
      const params = new URLSearchParams(search);
      params.delete('language');
      const newSearch = params.toString()?.length ? `?${params.toString()}` : '';
      window.history.pushState({}, '', `${window.location.pathname}${newSearch}`);
    }
  };

  const switchLang = (lng) => {
    updateGlobalSettings({currentLanguage: lng});
    removeSearchParam();
  };

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p className={classes.footerCenter}>
          <a
            href="https://www.eluvia.com"
            className={anchor}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <b>
              <Trans>Launched from land to cloud by Eluvians</Trans>
            </b>
            <img style={{height: 18, padding: '0 10px'}} src={footerSVG} alt={'footer'} />
          </a>
        </p>
        <p className={classes.footerCenter}>
          {LANGUAGES.map((item) => (
            <span
              key={item.value}
              className={cx(classes.langSwitch, {
                [classes.langSwitchActive]: item.value === globalSettings.currentLanguage,
              })}
              onClick={() => switchLang(item.value)}
            >
              {item.label}
            </span>
          ))}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalSettings,
    },
    dispatch
  );
};

export default compose(
  connect((store) => {
    return {
      globalSettings: store.GlobalSettings,
    };
  }, mapDispatchToProps),
  withStyles(footerStyle)
)(Footer);
