import authStyle from '_assets/global/jss/layouts/authStyle';
import Footer from '_components/global/Footer/Footer';
import withAdvancedRouter from '_components/global/hoc/withAdvancedRouter';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Switch} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

const AuthLayout = ({classes, getRoutesForLayoutSwitch}) => {
  useEffect(() => {
    document.body.style.overflow = 'unset';
  }, []);
  return (
    <div className={classes.wrapper}>
      <div className={classes.fullPage}>
        <Switch>{getRoutesForLayoutSwitch('auth')}</Switch>
        <Footer white />
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  getRoutesForLayoutSwitch: PropTypes.func.isRequired,
};

export default withAdvancedRouter(withStyles(authStyle)(AuthLayout));
