import cx from 'classnames';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

const style = {
  grid: {
    padding: '0 15px !important',
  },
};

function GridItem({...props}) {
  const {classes, children, className, noPadding = false, ...rest} = props;
  return (
    <Grid item {...rest} className={cx(noPadding ? '' : classes.grid, className)}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridItem);
