import errorPageStyles from '_assets/projectSpecific/jss/views/errorPageStyles';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans} from '@lingui/macro';
import withStyles from '@material-ui/core/styles/withStyles';

class ErrorPage extends React.Component {
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.contentCenter}>
        <GridContainer>
          <GridItem md={12}>
            <h1 className={classes.title}>404</h1>
            <h2 className={classes.subTitle}>
              <Trans>Page not found</Trans> :(
            </h2>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ErrorPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(errorPageStyles)(ErrorPage);
