import {
  closeIconTheme,
  removableTagStyle,
} from '_assets/global/jss/components/Tags/removableTagStyle';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {Close} from '@material-ui/icons';

const RemovableTag = ({classes, key, content, title, onClick, deletableObject}) => {
  const insideBlock = (
    <Tooltip title={title}>
      <IconButton role="button" aria-label="delete-group-membership" onClick={onClick}>
        <Close />
      </IconButton>
    </Tooltip>
  );

  return (
    <div className={classes.customGroupTag} key={key}>
      <span className={classes.customGroup}>{content}</span>
      <MuiThemeProvider theme={closeIconTheme}>
        {deletableObject !== undefined ? (
          <Can I="delete" a={deletableObject}>
            {insideBlock}
          </Can>
        ) : (
          insideBlock
        )}
      </MuiThemeProvider>
    </div>
  );
};

export default withStyles(removableTagStyle)(RemovableTag);
