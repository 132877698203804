import Dialog from '_components/global/Dialogs/Dialog';
import AccountForm from '_components/projectSpecific/Forms/AccountForm';
import React from 'react';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {Trans} from '@lingui/macro';

const InsertAccountDialog = (props) => {
  const {handleClose, open, onSubmit, branchesOptions, roleOptions} = props;

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={<Trans>Insert new user account</Trans>}
      content={
        <AccountForm
          form="createAccount"
          branchesOptions={branchesOptions}
          roleOptions={roleOptions}
          submitName={<Trans>Insert account</Trans>}
          onSubmit={onSubmit}
          authType={props.authType && props.authType}
        />
      }
    />
  );
};

const selector = formValueSelector('createAccount');

export default connect((store) => {
  return {
    authType: selector(store, 'auth_type'),
  };
}, null)(InsertAccountDialog);
