import {updateLoader} from '_actions/global/loaderActions';
import {createCustomer} from '_actions/projectSpecific/customersActions';
import {
  addCustomerDeliveryOptions,
  getDeliveryOptions,
} from '_actions/projectSpecific/deliveryOptionsActions';
import {getDiscountGroups} from '_actions/projectSpecific/discountGroupsActions';
import {createCustomerPageStyle} from '_assets/projectSpecific/jss/views/createCustomerPageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card.jsx';
import CardBody from '_components/global/Card/CardBody.jsx';
import CardHeader from '_components/global/Card/CardHeader.jsx';
import CardIcon from '_components/global/Card/CardIcon.jsx';
import GridContainer from '_components/global/Grid/GridContainer.jsx';
import GridItem from '_components/global/Grid/GridItem.jsx';
import HorizontalStepper from '_components/global/Steppers/HorizontalStepper';
import CustomerForm from '_components/projectSpecific/Forms/CustomerForm';
import UserAccountForm from '_components/projectSpecific/Forms/UserAccountForm';
import authorizedAbility from '_helpers/global/Authorization';
import {actualUserSelector, fireErrorToast, fireSuccessToast} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {formValueSelector, getFormValues, isValid} from 'redux-form';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {ArrowBack, GroupAdd} from '@material-ui/icons';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      createCustomer,
      getDeliveryOptions,
      addCustomerDeliveryOptions,
      getDiscountGroups,
    },
    dispatch
  );
};

class CreateCustomerPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      openPermissionsDialog: false,
      alert: null,
      userId: null,
      listUserPermissions: [],
      activeStep: 0,
      formData: {
        customer: {},
        user: {},
      },
    };
    if (authorizedAbility.cannot('create', 'Customers')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  setActiveStep(step) {
    this.setState({activeStep: step});
  }

  componentDidMount = () => {
    this.fetchDeliveryOptions();
    this.fetchDiscountGroups();
  };

  fetchDeliveryOptions = () => {
    return this.props.getDeliveryOptions(0, 0);
  };

  fetchDiscountGroups = () => {
    return this.props.getDiscountGroups(0, 0);
  };

  addCustomer = () => {
    const data = this.state.formData;
    delete data.user.repeatPassword;
    if (data.customer.discount_group) {
      data.customer.discount_group = `/api/v1/discount-groups/${data.customer.discount_group}/`;
    }
    if (data.user.main_user_auth_type === true) {
      data.user.main_user_auth_type = 'oracle';
    } else {
      data.user.main_user_auth_type = 'local';
    }
    const customerData = {...data.customer, ...data.user};

    this.props.updateLoader({globalLoading: true});
    return this.props
      .createCustomer(customerData)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Customer was successfully created.</Trans>);
        browserHistoryAccessor.push('/admin/customers');
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  getSteps = () => {
    return [<Trans>Insert customer</Trans>, <Trans>User account</Trans>];
  };

  getDiscountGroups = (data) => {
    return data.map((item) => {
      return {value: item.id, label: item.name};
    });
  };

  getStepContent = () => {
    const reseller = this.props.currentUser;
    switch (this.state.activeStep) {
      case 0:
        return (
          <CustomerForm
            initialValues={this.state.formData.customer}
            customerNo2={this.props.customerNo ? `${this.props.customerNo}@${reseller.id}` : null}
            form="createCustomer"
            discountGroups={this.getDiscountGroups(this.props.discountGroups)}
          />
        );
      case 1:
        return (
          <UserAccountForm
            initialValues={this.state.formData.user}
            form="createUserAccount"
            authType={this.props.authType && this.props.authType}
          />
        );
      default:
        return <Trans>Unknown step</Trans>;
    }
  };

  getNextAction = (isFinish = false) => {
    switch (this.state.activeStep) {
      case 0:
        this.setState({
          formData: {
            ...this.state.formData,
            customer: this.props.customerFormData,
          },
        });
        break;
      case 1:
        this.setState(
          {
            formData: {
              ...this.state.formData,
              user: this.props.userFormData,
            },
          },
          () => {
            if (isFinish) this.addCustomer();
          }
        );

        break;
      default:
        return true;
    }
  };

  getCanGoNext = () => {
    switch (this.state.activeStep) {
      case 0:
        return this.props.isCustomerFormValid;
      case 1:
        return this.props.isUserFormValid;
      default:
        return true;
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <GridContainer justify="center">
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon className={classes.root}>
              <CardIcon color="blue">
                <GroupAdd />
              </CardIcon>
              <div className={classes.backBtn}>
                <Tooltip title={<Trans>Back to list</Trans>}>
                  <Button
                    className={classes.btnIcon}
                    onClick={() => browserHistoryAccessor.goBack()}
                  >
                    <ArrowBack />
                  </Button>
                </Tooltip>
              </div>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <HorizontalStepper
                getStepContent={this.getStepContent}
                nextAction={this.getNextAction}
                canGoNext={this.getCanGoNext}
                steps={this.getSteps()}
                setActiveStep={(step) => this.setActiveStep(step)}
                activeStep={this.state.activeStep}
                canFinish={[1, 2]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const selector = formValueSelector('createCustomer');
const selector2 = formValueSelector('createUserAccount');

export default compose(
  withStyles(createCustomerPageStyle),
  connect((store) => {
    return {
      customerNo: selector(store, 'customer_no'),
      deliveryOptions: store.DeliveryOptions.data,
      customerFormData: getFormValues('createCustomer')(store),
      userFormData: getFormValues('createUserAccount')(store),
      deliveryTermsFormData: getFormValues('addCustomerDeliveryTerms')(store),
      isCustomerFormValid: isValid('createCustomer')(store),
      isUserFormValid: isValid('createUserAccount')(store),
      authType: selector2(store, 'main_user_auth_type'),
      discountGroups: store.DiscountGroups.data,
      currentUser: actualUserSelector(store.Auth),
    };
  }, mapDispatchToProps)
)(CreateCustomerPage);
