const customSwitcherStyle = () => ({
  checked: {},
  helperTextMargin: {
    marginTop: '0px !important',
  },
  formControl: {
    position: 'relative',
    width: '100%',
  },
  switcher: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export default customSwitcherStyle;
