import {blackColor, hexToRgb, whiteColor} from '_assets/global/jss/styleHelpers';
import {lighten} from '@material-ui/core/styles/colorManipulator';

const cartsTableToolbarStyle = (theme) => ({
  root: {
    padding: 0,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
          paddingLeft: '10px',
          paddingRight: '10px',
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    position: 'relative',
    '& button': {
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: '15px',
        right: '-80px',
      },
    },
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  smallIcons: {
    width: '80%',
  },
  customIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  },
  popperResponsive: {
    zIndex: '1200',
    [theme.breakpoints.down('sm')]: {
      zIndex: '1060',
      position: 'static',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black',
    },
  },
  dropDown: {
    borderRadius: '3px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(' + hexToRgb(blackColor) + ', 0.26)',
    top: '100%',
    zIndex: '1000',
    minWidth: '160px',
    padding: '20px',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: whiteColor,
    backgroundClip: 'padding-box',
  },
  select: {
    marginRight: '5px',
  },
});

export default cartsTableToolbarStyle;
