import {toastStyle} from '_assets/global/jss/components/Toasts/toastStyle';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {CheckCircle, Error, Info, Warning} from '@material-ui/icons';

const Toast = ({message, type, classes}) => {
  const switchIcons = (toastType) => {
    switch (toastType) {
      case 'success':
        return <CheckCircle className={classes.toastIcon} />;
      case 'warning':
        return <Warning className={classes.toastIcon} />;
      case 'info':
        return <Info className={classes.toastIcon} />;
      case 'error':
        return <Error className={classes.toastIcon} />;
      default:
        return;
    }
  };

  return (
    <div className={classes.toast}>
      {switchIcons(type)}
      <strong>{message}</strong>
    </div>
  );
};

export default withStyles(toastStyle)(Toast);
