export const discountGroupsListFormStyle = (theme) => ({
  table: {
    minWidth: 1020,
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  width20: {
    width: '20%',
  },
  newGroup: {
    background: '#FF5050',
  },
});
