import cardIconStyle from '_assets/global/jss/components/Card/cardIconStyle';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

function CardIcon({...props}) {
  const {classes, className, children, color, ...rest} = props;
  const cardIconClasses = cx({
    [classes.cardIcon]: true,
    [classes[color + 'CardHeader']]: color,
    [className]: className !== undefined,
  });
  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
}

CardIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['warning', 'success', 'danger', 'info', 'primary', 'rose', 'blue']),
};

export default withStyles(cardIconStyle)(CardIcon);
