import {cardTitle} from '_assets/global/jss/styleHelpers';

export const createCustomerPageStyle = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  actionButtons: {
    position: 'absolute',
    top: '40px',
    right: '20px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      top: '60px',
    },
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '20px',
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '20px',
    right: '10px',
  },
  btnIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
