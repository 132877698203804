import {discountGroupsListFormStyle} from '_assets/projectSpecific/jss/components/Forms/discountGroupsListFormStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import {FormTextField} from '_components/global/Form/FormFields';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {DiscountGroupsValidation} from '_helpers/global/FieldLevelValidation';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Edit, Save} from '@material-ui/icons';

const DiscountGroupsListForm = (props) => {
  const {
    classes,
    handleSubmit,
    discountCategories,
    rows,
    inputLocked,
    handleSingleLineEdit,
    saveSingleLineEdit,
    activeLineEdit,
    order,
    orderBy,
    handleRequestSort,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Table className={classes.table} aria-labelledby="tableTitle">
        <SimpleTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rows={rows}
        />
        <TableBody>
          {discountCategories.map((category) => {
            const {id, category_id, category_description, discount_groups} = category;

            return (
              <TableRow hover tabIndex={-1} key={id}>
                <TableCell classes={{root: classes.cell}}>{id}</TableCell>
                {saveSingleLineEdit && (
                  <Can I="update" a="DiscountGroup">
                    <TableCell classes={{root: classes.cell}}>
                      <div className={classes.actions}>
                        {activeLineEdit === id ? (
                          <BaseActionsButton color="danger" onClick={saveSingleLineEdit}>
                            <Tooltip title={<Trans>Save discount groups</Trans>}>
                              <Save />
                            </Tooltip>
                          </BaseActionsButton>
                        ) : (
                          <BaseActionsButton
                            color="success"
                            onClick={() => handleSingleLineEdit(id)}
                            disabled={activeLineEdit !== null}
                          >
                            <Tooltip title={<Trans>Edit discount groups</Trans>}>
                              <Edit />
                            </Tooltip>
                          </BaseActionsButton>
                        )}
                      </div>
                    </TableCell>
                  </Can>
                )}
                <TableCell classes={{root: classes.cell}}>{category_id}</TableCell>
                <TableCell classes={{root: classes.cell}}>{category_description}</TableCell>
                {discount_groups && discount_groups.length
                  ? discount_groups.map((group) => (
                      <TableCell
                        key={group.id}
                        classes={{
                          root: cx({[classes.newGroup]: group.is_new === true}, classes.cell),
                        }}
                      >
                        <Field
                          name={`items[${id}][${group.id}]`}
                          id={`items[${id}][${group.id}]`}
                          component={FormTextField}
                          type="number"
                          disabled={inputLocked && activeLineEdit !== id}
                          validate={DiscountGroupsValidation}
                        />
                      </TableCell>
                    ))
                  : ''}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </form>
  );
};

export default compose(
  withStyles(discountGroupsListFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(DiscountGroupsListForm);
