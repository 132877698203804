import customSwitcherStyle from '_assets/global/jss/components/Fields/customSwitcherStyle';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import {withStyles} from '@material-ui/core/styles';

const CustomSwitcher = ({
  label,
  helpText,
  classes,
  disabled,
  checked,
  onChange,
  valueA,
  valueB,
  rootClass,
}) => {
  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      <div className={classes.switcher}>
        <span>{valueA}</span>
        <Switch
          classes={{root: rootClass && rootClass}}
          checked={checked}
          onChange={onChange}
          color="primary"
        />
        <span>{valueB}</span>
      </div>
      <FormHelperText className={classes.helperTextMargin}>{helpText}</FormHelperText>
    </FormControl>
  );
};

export default withStyles(customSwitcherStyle)(CustomSwitcher);
