import {customerDetailTableStyle} from '_assets/projectSpecific/jss/components/Tables/customerDetailTableStyle';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const CustomerDetailTable = ({classes, data}) => {
  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table} aria-labelledby="tableTitle">
        <TableBody>
          {data.map((item, index) => {
            const {field, value} = item;

            return (
              <TableRow hover tabIndex={-1} key={index}>
                <TableCell classes={{root: classes.cell}}>
                  <strong>{field}</strong>
                </TableCell>
                <TableCell classes={{root: classes.cell}}>{value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(customerDetailTableStyle)(CustomerDetailTable);
