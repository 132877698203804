export const filterCustomersDTMembershipFormStyle = (theme) => ({
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cartFormFields: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  cartButton: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'end',
      margin: '20px 25px 0 0',
    },
  },
});
