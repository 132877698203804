import {removableTagStyle} from '_assets/global/jss/components/Tags/removableTagStyle';
import cx from 'classnames';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const Tag = ({classes, key, content, customClasses}) => {
  return (
    <div className={classes.customGroupTag} key={key}>
      <span className={cx(classes.customGroup, customClasses)}>{content}</span>
    </div>
  );
};

export default withStyles(removableTagStyle)(Tag);
