import {
  FETCH_DELIVERY_OPTIONS_FAIL,
  FETCH_DELIVERY_OPTIONS_REQUEST,
  FETCH_DELIVERY_OPTIONS_SUCCESS,
  FETCH_MY_DELIVERY_OPTIONS_FAIL,
  FETCH_MY_DELIVERY_OPTIONS_REQUEST,
  FETCH_MY_DELIVERY_OPTIONS_SUCCESS,
} from '_actions/projectSpecific/types';

const initDeliveryOptionsState = {
  data: [],
  loading: true,
};

const initMyDeliveryOptionsState = {
  data: [],
  loading: true,
};

export const DeliveryOptionsReducer = (state = initDeliveryOptionsState, action = null) => {
  switch (action.type) {
    case FETCH_DELIVERY_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_DELIVERY_OPTIONS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        loading: false,
      };
    case FETCH_DELIVERY_OPTIONS_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const MyDeliveryOptionsReducer = (state = initMyDeliveryOptionsState, action = null) => {
  switch (action.type) {
    case FETCH_MY_DELIVERY_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_MY_DELIVERY_OPTIONS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        loading: false,
      };
    case FETCH_MY_DELIVERY_OPTIONS_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
