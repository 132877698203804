import PrivateRoute from '_helpers/global/PrivateRoute';
import {AppRoutes} from 'App/routes';
import ErrorPage from 'App/views/projectSpecific/ErrorPage';
import React from 'react';
import {Route} from 'react-router-dom';

const withAdvancedRouter = (WrappedComponent) => {
  const getRoutesForLayoutSwitch = (layoutIdentifier, routes = AppRoutes) => {
    const routesMapping = routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutesForLayoutSwitch(layoutIdentifier, prop.views);
      }
      if (prop.layout === '/' + layoutIdentifier) {
        const RouteComponent = prop.private === true ? PrivateRoute : Route;
        return (
          <RouteComponent
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact}
          />
        );
      } else {
        return null;
      }
    });
    routesMapping.push(<Route path="*" key="error" component={ErrorPage} />);
    return routesMapping;
  };

  const getRoutesForLayoutSidebar = (layoutIdentifier, routes = AppRoutes) => {
    let visibleRoutes = [];

    routes.forEach((route) => {
      const canSee = route.visible !== undefined ? route.visible === true : true;

      if (canSee && route.layout === '/' + layoutIdentifier) {
        visibleRoutes.push(route);
      }
    });
    return visibleRoutes;
  };

  const getActiveRouteName = (routes = AppRoutes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRouteName(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  return (props) => (
    <WrappedComponent
      getRoutesForLayoutSwitch={getRoutesForLayoutSwitch}
      getRoutesForLayoutSidebar={getRoutesForLayoutSidebar}
      getActiveRouteName={getActiveRouteName}
      {...props}
    />
  );
};

export default withAdvancedRouter;
