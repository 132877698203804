import {vehicleReferencesFormStyle} from '_assets/projectSpecific/jss/components/Forms/vehicleReferencesFormStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import {FormSelectField, FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {required} from '_helpers/global/FieldLevelValidation';
import React from 'react';
import {compose} from 'recompose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Delete} from '@material-ui/icons';

const SEARCH_TYPES = [
  {value: 'typenscheinnummer', label: <Trans>Typenscheinnummer</Trans>},
  {value: 'stammnummer', label: <Trans>Stammnummer</Trans>},
];

const VEHICLE_TYPES = [
  {value: 10, label: <Trans>Personal car (PC)</Trans>},
  {value: 20, label: <Trans>Light commercial vehicle (LCV)</Trans>},
  {value: 60, label: <Trans>Commercial vehicle (CV)</Trans>},
  {value: 99, label: <Trans>Motorcycle (MC)</Trans>},
];

const VehicleReferencesForm = ({classes, handleAdd, handleRemove, form, reset, data, formData}) => {
  const allFieldsFilled =
    formData?.type && formData?.number && formData?.k_type && formData?.vehicle_type;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAdd();
          reset();
        }}
        className={classes.form}
      >
        <GridContainer justify="center" alignItems="flex-start">
          <GridItem xs={6} md={3}>
            <Field
              name={'type'}
              id={'type'}
              type={'select'}
              label={<Trans>Search type</Trans>}
              component={FormSelectField}
              options={SEARCH_TYPES}
              required
              reduxName="type"
              formName={form}
            />
          </GridItem>

          <GridItem xs={6} md={3}>
            <Field
              name="number"
              label={<Trans>Number</Trans>}
              component={FormTextField}
              required
              validate={required}
            />
          </GridItem>

          <GridItem xs={6} md={3}>
            <Field
              name="k_type"
              type="number"
              label={<Trans>TecDoc Ktyp/Ntyp</Trans>}
              component={FormTextField}
              required
              validate={required}
            />
          </GridItem>

          <GridItem xs={6} md={3}>
            <Field
              name="vehicle_type"
              id="vehicle_type"
              type={'select'}
              label={<Trans>Type of vehicle</Trans>}
              component={FormSelectField}
              options={VEHICLE_TYPES}
              required
              reduxName="vehicle_type"
              formName={form}
            />
          </GridItem>

          <GridItem xs={12}>
            <button type="submit" className={classes.addButton} disabled={!allFieldsFilled}>
              <Trans>Add reference</Trans>
            </button>
          </GridItem>

          {data.length > 0 && (
            <>
              <GridItem xs={12} container>
                <GridItem noPadding={true} xs={12} container className={classes.referenceTableHead}>
                  <GridItem xs={3} style={{fontWeight: 'bold'}}>
                    <Trans>Search type</Trans>
                  </GridItem>
                  <GridItem xs={3} style={{fontWeight: 'bold'}}>
                    <Trans>Number</Trans>
                  </GridItem>
                  <GridItem xs={2} style={{fontWeight: 'bold'}}>
                    <Trans>TecDoc Ktyp/Ntyp</Trans>
                  </GridItem>
                  <GridItem xs={3} style={{fontWeight: 'bold'}}>
                    <Trans>Type of vehicle</Trans>
                  </GridItem>
                  <GridItem xs={1}></GridItem>
                </GridItem>
              </GridItem>
              <GridItem xs={12} container>
                {data.map((item, index) => (
                  <GridItem
                    noPadding={true}
                    xs={12}
                    container
                    key={index}
                    style={{
                      borderTop: '1px solid gray',
                      minHeight: '34px',
                      alignItems: 'center',
                      backgroundColor: index % 2 ? '#e3e3e3' : 'white',
                    }}
                  >
                    <GridItem xs={3}>
                      {item.type ? SEARCH_TYPES.find((t) => t.value === item.type)?.label : ''}
                    </GridItem>
                    <GridItem xs={3}>{item.number}</GridItem>
                    <GridItem xs={2}>{item.k_type}</GridItem>
                    <GridItem xs={3}>
                      {item.vehicle_type
                        ? VEHICLE_TYPES.find((t) => t.value === item.vehicle_type)?.label
                        : ''}
                    </GridItem>
                    <GridItem xs={1}>
                      <BaseActionsButton color="danger" onClick={() => handleRemove(index)}>
                        <Tooltip title={<Trans>Delete</Trans>}>
                          <Delete />
                        </Tooltip>
                      </BaseActionsButton>
                    </GridItem>
                  </GridItem>
                ))}
              </GridItem>
            </>
          )}
        </GridContainer>
      </form>
    </>
  );
};

export default compose(
  withStyles(vehicleReferencesFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(VehicleReferencesForm);
