import {cardTitle} from '_assets/global/jss/styleHelpers';

export const discountGroupsPageStyle = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  tabBar: {
    marginTop: '30px',
  },
});
