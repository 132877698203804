import {
  customerFormStyle,
  selectFieldTheme,
} from '_assets/projectSpecific/jss/components/Forms/customFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormCheckbox, FormSelectField, FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {PHONE_PREFIX} from '_config/configConstants';
import {
  email,
  maxLength32,
  maxLength256,
  minMaxLengthPostCode,
  numberVal,
  required,
} from '_helpers/global/FieldLevelValidation';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const CustomerForm = ({
  classes,
  handleSubmit,
  pristine,
  reset,
  submitting,
  form,
  customerNo,
  customerNo2,
  discountGroups,
}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.margin}>
      <GridContainer justify="center">
        {form === 'createCustomer' ? (
          <GridItem xs={12} md={6}>
            <Field
              name="customer_no"
              label={<Trans>Customer ID</Trans>}
              id="customer_no"
              component={FormTextField}
              validate={[required, maxLength256]}
              required
            />
          </GridItem>
        ) : (
          <GridItem xs={12} md={6}>
            <div className={classes.fixFields}>
              <span>
                <Trans>Customer ID</Trans> :
              </span>
              {customerNo ? customerNo : null}
            </div>
          </GridItem>
        )}
        <GridItem xs={12} md={6}>
          <Field
            name="address_1"
            label={<Trans>Street</Trans>}
            id="address_1"
            component={FormTextField}
            validate={maxLength256}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <div className={classes.fixFields}>
            <span>
              <Trans>Customer No.</Trans> :
            </span>
            {customerNo2 ? customerNo2 : null}
          </div>
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="address_2"
            label={<Trans>House number</Trans>}
            id="address_2"
            component={FormTextField}
            validate={maxLength256}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="name_1"
            label={<Trans>Name</Trans>}
            id="name_1"
            component={FormTextField}
            validate={[required, maxLength256]}
            required
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="address_3"
            label={<Trans>District</Trans>}
            id="address_3"
            component={FormTextField}
            validate={maxLength256}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="name_2"
            label={<Trans>Company name</Trans>}
            id="name_2"
            component={FormTextField}
            validate={maxLength256}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="post_code"
            label={<Trans>Zip code</Trans>}
            id="post_code"
            validate={minMaxLengthPostCode}
            component={FormTextField}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="email"
            label={<Trans>Email</Trans>}
            id="email"
            type="email"
            validate={[email, maxLength256]}
            component={FormTextField}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="city"
            label={<Trans>City</Trans>}
            id="city"
            component={FormTextField}
            validate={maxLength256}
          />
        </GridItem>
        <GridItem xs={3} md={2}>
          <MuiThemeProvider theme={selectFieldTheme}>
            <Field
              name="phone_prefix"
              label={<Trans>Prefix</Trans>}
              id="phone_prefix"
              type="select"
              component={FormSelectField}
              options={PHONE_PREFIX}
            />
          </MuiThemeProvider>
        </GridItem>
        <GridItem xs={9} md={4}>
          <Field
            name="phone"
            label={<Trans>Phone number</Trans>}
            id="phone"
            component={FormTextField}
            validate={[numberVal, maxLength32]}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="discount_group"
            label={<Trans>Discount group</Trans>}
            id="discount_group"
            type="select"
            component={FormSelectField}
            options={discountGroups}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="website"
            label={<Trans>Website</Trans>}
            id="website"
            component={FormTextField}
            validate={maxLength256}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <Field
            name="is_coco"
            component={FormCheckbox}
            label={<Trans>Cooperation concept</Trans>}
          />
        </GridItem>

        {form === 'editCustomer' ? (
          <GridItem xs={12}>
            <div className={classes.formActions}>
              <Button
                onClick={reset}
                className={cx(classes.button)}
                disabled={pristine || submitting}
              >
                <Trans>Reset</Trans>
              </Button>
              <Button
                className={cx(classes.button, classes.blue)}
                disabled={pristine || submitting}
                type="submit"
              >
                <Trans>Save changes</Trans>
              </Button>
            </div>
          </GridItem>
        ) : (
          ''
        )}
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(customerFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(CustomerForm);
