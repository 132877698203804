import commonTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/commonTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans} from '@lingui/macro';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import {Add, LocalShipping} from '@material-ui/icons';

const SettingsDeliveryOptionsTableToolbar = ({classes, handlePDeliveryTermInsert}) => {
  return (
    <Can I="create" a="DeliveryOptions">
      <Toolbar className={cx(classes.root)}>
        <Tooltip title={<Trans>Insert delivery term</Trans>}>
          <Button aria-label="InsertNew" onClick={() => handlePDeliveryTermInsert()}>
            <Add />
            <LocalShipping />
          </Button>
        </Tooltip>
      </Toolbar>
    </Can>
  );
};

SettingsDeliveryOptionsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(commonTableToolbarStyle)(SettingsDeliveryOptionsTableToolbar);
