import {updateLoader} from '_actions/global/loaderActions';
import {
  editLogoSettings,
  editMyCustomerSettings,
  getMyCustomerSettings,
} from '_actions/projectSpecific/settingsActions';
import defaultAvatar from '_assets/global/img/default-avatar.png';
import defaultImage from '_assets/global/img/image_placeholder.jpg';
import {companySettingsPageStyle} from '_assets/projectSpecific/jss/views/companySettingsPageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card.jsx';
import CardBody from '_components/global/Card/CardBody.jsx';
import CardHeader from '_components/global/Card/CardHeader.jsx';
import CardIcon from '_components/global/Card/CardIcon.jsx';
import GridContainer from '_components/global/Grid/GridContainer.jsx';
import GridItem from '_components/global/Grid/GridItem.jsx';
import SwipeableView from '_components/global/Tabs/SwipeableViews';
import TabContainer from '_components/global/Tabs/TabContainer';
import CompanySettingsForm from '_components/projectSpecific/Forms/CompanySettingsForm';
import authorizedAbility from '_helpers/global/Authorization';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {fireErrorToast, fireSuccessToast} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import {diff} from 'deep-object-diff';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {withStyles} from '@material-ui/core/styles';
import {SettingsApplications} from '@material-ui/icons';

class CompanySettingsPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      tabValue: 0,
      newPreview: false,
      imageFile: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
    };
    if (authorizedAbility.cannot('read', 'MyCustomer')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchCustomerSettings();
  };

  fetchCustomerSettings = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props.getMyCustomerSettings().then(() => {
      this.props.updateLoader({globalLoading: false});
    });
  };

  handleSettingsTabChange = (event, tabValue) => {
    this.setState({tabValue});
  };

  handleSettingsTabChangeIndex = (index) => {
    this.setState({tabValue: index});
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        newPreview: true,
        imageFile: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  handleClick = () => {
    this.refs.fileInput.click();
  };

  handleRemove = () => {
    this.setState(
      {
        newPreview: true,
        imageFile: null,
        imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      },
      () => (this.refs.fileInput.value = null)
    );
  };

  editSettings = (values, customerSettings) => {
    const editedCustomerSettings = diff(customerSettings, values);
    this.props.updateLoader({globalLoading: true});
    return this.props
      .editMyCustomerSettings(editedCustomerSettings)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Company settings successfully edited.</Trans>);
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  editLogo = (imageFile) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .editLogoSettings(imageFile)
      .then((response) => {
        if (response.data && response.data.logo !== null) {
          this.setState({
            imageFile: null,
            imagePreviewUrl: response.data.logo,
          });
        } else {
          this.setState({
            imageFile: null,
            imagePreviewUrl: defaultImage,
          });
        }
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Logo successfully saved.</Trans>);
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(
          error.errors ? error.errors._error : <Trans>Something went wrong</Trans>,
          {autoClose: false}
        );
      });
  };

  render() {
    const {classes, theme, avatar, addButtonProps, changeButtonProps, removeButtonProps} =
      this.props;

    const logoPreview =
      this.props.customerSettings.logo && this.state.newPreview === false
        ? this.props.customerSettings.logo
        : this.state.imagePreviewUrl;
    return (
      <GridContainer justify="center">
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon className={classes.root}>
              <CardIcon color="blue">
                <SettingsApplications />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Company settings</Trans>
              </h4>
              <div className={classes.tabBar}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleSettingsTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab label={<Trans>Company settings</Trans>} />
                    <Tab label={<Trans>Logo settings</Trans>} />
                  </Tabs>
                </AppBar>
              </div>
            </CardHeader>
            <CardBody>
              <SwipeableView
                index={this.state.tabValue}
                onChangeIndex={this.handleSettingsTabChangeIndex}
              >
                <TabContainer dir={theme.direction}>
                  <div className={classes.step}>
                    <CompanySettingsForm
                      form="companySettings"
                      onSubmit={(values) => {
                        return this.editSettings(values, this.props.customerSettings);
                      }}
                      submitName={<Trans>Save settings</Trans>}
                      initialValues={this.props.customerSettings}
                    />
                  </div>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                  <div className={classes.step2}>
                    <GridContainer justify="center">
                      <GridItem xs={12} md={4} className={classes.center}>
                        <div className="fileinput text-center">
                          <input
                            type="file"
                            onChange={this.handleImageChange}
                            ref="fileInput"
                            accept="image/png, image/jpeg, image/gif"
                          />
                          <div className={'thumbnail' + (avatar ? ' img-circle' : '')}>
                            <img src={logoPreview} alt="..." />
                          </div>
                          <div>
                            <p>
                              <Trans>
                                Images should be in format .JPG, .PNG or .GIF with maximum size
                                limit of 3MB.
                              </Trans>
                            </p>
                            <p>
                              <Trans>
                                Maximum size of an image is 220x105px. Images greater than these
                                proportions will be automatically resized.
                              </Trans>
                            </p>
                          </div>
                          <div>
                            {this.state.file === null ? (
                              <Button {...addButtonProps} onClick={() => this.handleClick()}>
                                {avatar ? <Trans>Add Photo</Trans> : <Trans>Select image</Trans>}
                              </Button>
                            ) : (
                              <span>
                                <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                                  <Trans>Change</Trans>
                                </Button>
                                {avatar ? <br /> : null}
                                <Button {...removeButtonProps} onClick={() => this.handleRemove()}>
                                  <i className="fas fa-times" /> Remove
                                </Button>
                              </span>
                            )}
                          </div>
                        </div>
                      </GridItem>
                      <Can I="update" a="MyCustomer">
                        <GridItem xs={12}>
                          <div className={classes.formActions}>
                            <Button
                              color="blue"
                              onClick={() => this.editLogo(this.state.imageFile)}
                            >
                              <Trans>Save logo</Trans>
                            </Button>
                          </div>
                        </GridItem>
                      </Can>
                    </GridContainer>
                  </div>
                </TabContainer>
              </SwipeableView>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      getMyCustomerSettings,
      editMyCustomerSettings,
      editLogoSettings,
    },
    dispatch
  );
};

export default compose(
  withStyles(companySettingsPageStyle, {withTheme: true}),
  connect((store) => {
    return {
      customerSettings: store.MyCustomerSettings.data,
    };
  }, mapDispatchToProps)
)(CompanySettingsPage);
