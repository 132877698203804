import {
  customCheckboxStyle,
  tableSelectTheme,
} from '_assets/global/jss/components/Fields/customCheckboxStyle';
import cx from 'classnames';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';

const CustomCheckbox = ({
  label,
  helpText,
  classes,
  disabled,
  checked,
  onChange,
  typeTable,
  helpTextMargin = true,
}) => {
  return (
    <>
      {typeTable ? (
        <FormControl className={classes.formControlTable} disabled={disabled}>
          <MuiThemeProvider theme={tableSelectTheme}>
            <Checkbox color="default" checked={checked} onChange={onChange} />
          </MuiThemeProvider>
        </FormControl>
      ) : (
        <FormControl className={classes.formControl} disabled={disabled}>
          <FormControlLabel
            control={<Checkbox color="default" checked={checked} onChange={onChange} />}
            label={label}
          />
          <FormHelperText
            className={cx(classes.helperTextMargin, !helpTextMargin && classes.noHelperTextMargin)}
          >
            {helpText}
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};

export default withStyles(customCheckboxStyle)(CustomCheckbox);
