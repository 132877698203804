import {UPDATE_FILTERS_STATE} from '_actions/global/types';

const initState = {
  cartListFilter: {
    orderBy: null,
    customerNo: null,
    from: null,
    to: null,
    status: 'new,partly_done',
    ordered: false,
  },
  customersListFilter: {
    orderBy: null,
    CustomerNameNo: null,
  },
  customersDeliveryOptionsListFilter: {
    orderBy: null,
    CustomerNameNo: null,
    deliveryOptionId: 'all',
  },
  lostSaleReasonListFilter: {
    orderBy: null,
    username: null,
    productNo: null,
  },
  lastLoginListFilter: {
    orderBy: null,
    username: null,
    createdAt: null,
  },
  discountCategoriesListFilter: {
    orderBy: null,
    categoryId: null,
  },
  customersInDiscountGroupsListFilter: {
    orderBy: null,
    discountGroupId: null,
  },
  discountLimitCodesListFilter: {
    orderBy: null,
  },
  usersListFilter: {
    orderBy: null,
    q: null,
  },
  wholesalerAvailabilityFilter: {
    orderBy: null,
  },
};

export const AppFilterStatesReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_FILTERS_STATE) {
    return {
      ...state,
      ...action.data,
    };
  }
  return state;
};
