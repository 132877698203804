import {
  dateTimePickerStyle,
  materialDateTimeDesktopTheme,
} from '_assets/global/jss/components/Pickers/dateTimePickerStyle';
import ClearButton from '_components/global/Buttons/ClearButton';
import cx from 'classnames';
import {TimePicker} from 'material-ui-pickers';
import moment from 'moment';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {MuiThemeProvider} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles from '@material-ui/core/styles/withStyles';

const CustomTimePicker = ({
  classes,
  timeValue,
  onTimeChange,
  helpText,
  id,
  clearButton,
  formName,
  reduxName,
  dispatch,
}) => {
  const [selectedTime] = useState(moment());

  return (
    <div>
      <MuiThemeProvider theme={materialDateTimeDesktopTheme}>
        <FormControl className={classes.formControl}>
          <div className={cx('picker', classes.dateTimePicker)}>
            <div className={classes.endIcon}>
              <TimePicker
                keyboard
                autoOk={true}
                clearable
                format={'hh:mm'}
                mask={(value) =>
                  // handle clearing outside if value can be changed outside of the component
                  value ? [/\d/, /\d/, ':', /\d/, /\d/] : []
                }
                value={timeValue ? timeValue : selectedTime}
                onChange={onTimeChange}
                disableOpenOnEnter
                // animateYearScrolling={false}
              />
            </div>
            {clearButton === true && (
              <ClearButton formName={formName} reduxName={reduxName} dispatch={dispatch} />
            )}
          </div>
          {helpText !== undefined ? (
            <FormHelperText id={id + '-text'}>{helpText}</FormHelperText>
          ) : null}
        </FormControl>
      </MuiThemeProvider>
    </div>
  );
};

export default compose(withStyles(dateTimePickerStyle), connect())(CustomTimePicker);
