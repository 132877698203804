import {createMuiTheme} from '@material-ui/core';

export const CustomChipSelectFieldStyle = (theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '90%',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  formControl: {
    margin: '10px auto',
    minWidth: 120,
    width: '100%',
  },
  multipleSelectIcon: {
    '& svg': {
      right: '10px',
    },
  },
});

export const SelectField = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiInputBase: {
      root: {
        width: '100%',
      },
    },
    MuiChip: {
      root: {
        fontSize: '0.7rem',
      },
    },
    MuiSelect: {
      selectMenu: {
        width: '100%',
      },
    },
  },
});
