import {
  FETCH_DISCOUNT_LIMIT_CODES_FAIL,
  FETCH_DISCOUNT_LIMIT_CODES_REQUEST,
  FETCH_DISCOUNT_LIMIT_CODES_SUCCESS,
} from '_actions/projectSpecific/types';

const initDiscountLimitCodesState = {
  data: [],
  initialFormValues: {},
  loading: true,
};

const getInitialValuesMap = (data) => {
  let initials = {};
  data.forEach((item) => {
    item.discount_limits.forEach((limit) => {
      initials[limit.id] = limit.value;
    });
  });
  return {items: initials};
};

export const DiscountLimitCodesReducer = (state = initDiscountLimitCodesState, action = null) => {
  switch (action.type) {
    case FETCH_DISCOUNT_LIMIT_CODES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_DISCOUNT_LIMIT_CODES_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        initialFormValues: getInitialValuesMap(action.data.objects),
        loading: false,
      };
    case FETCH_DISCOUNT_LIMIT_CODES_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
