import {configurationFormStyle} from '_assets/projectSpecific/jss/components/Forms/configurationFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormSwitcher} from '_components/global/Form/FormFields';
import GridItem from '_components/global/Grid/GridItem';
import NumberField from '_components/projectSpecific/Configuration/NumberField';
import StringField from '_components/projectSpecific/Configuration/StringField';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const ConfigurationForm = (props) => {
  const {handleSubmit, data, classes, reset, pristine, submitting, submitName} = props;

  const getFieldsByCategory = () => {
    const fieldsByCategory = {};
    data.forEach((field) => {
      if (Object.keys(fieldsByCategory).includes(field.category)) {
        fieldsByCategory[field.category].push(field);
      } else {
        fieldsByCategory[field.category] = [field];
      }
    });
    return fieldsByCategory;
  };

  const renderCategories = (category, index) => {
    return (
      <div key={category[0]}>
        <div className={cx(classes.fieldPadding, classes.title)}>{category[0]}</div>
        <Grid container>
          {category[1].map((field, index) => (
            <Grid item key={index} xs={12} md={6} className={classes.fieldPadding}>
              {renderField(field)}
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'str': {
        return <Field name={'field.' + field.id} component={StringField} {...field} />;
      }
      case 'int': {
        return (
          <Field type="number" name={'field.' + field.id} component={NumberField} {...field} />
        );
      }
      case 'bool': {
        return (
          <Field
            name={'field.' + field.id}
            helpText={field.help_text}
            component={FormSwitcher}
            helpTextMargin={false}
            valueB={field.label}
            rootClass={classes.rootSwitchClass}
            {...field}
          />
        );
      }
      default:
        return;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.configuration}>
        {data && Object.entries(getFieldsByCategory()).map(renderCategories)}
        <GridItem xs={12}>
          <div className={classes.formActions}>
            <Button
              onClick={reset}
              className={cx(classes.button)}
              disabled={pristine || submitting}
            >
              <Trans>Reset</Trans>
            </Button>
            <Button
              className={cx(classes.button, classes.blue)}
              disabled={pristine || submitting}
              type="submit"
            >
              {submitName}
            </Button>
          </div>
        </GridItem>
      </div>
    </form>
  );
};

export default compose(
  withStyles(configurationFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(ConfigurationForm);
