import cartsTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/cartsTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import FormatForm from '_components/projectSpecific/Forms/FormatForm';
import {fireWarningToast} from '_helpers/global/functions';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import compose from 'recompose/compose';
import {reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {CloudDownload} from '@material-ui/icons';

class CartsTableToolbar2 extends Component {
  toastId = null;

  warningSelect = () => {
    this.toastId = fireWarningToast(<Trans>Select at least one basket!</Trans>, {autoClose: false});
  };

  render() {
    const {numSelected, classes, handleExport} = this.props;

    const Actions = (
      <div>
        <Hidden mdDown implementation="css">
          <div className={classes.flexEnd}>
            <FormatForm form="exportForm" initialValues={{export: 'text/plain'}} />
            <Tooltip title={<Trans>Download selected baskets</Trans>}>
              <Button ref="export" onClick={numSelected < 1 ? this.warningSelect : handleExport}>
                <Trans>Download</Trans>
              </Button>
            </Tooltip>
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div className={cx(classes.smallIcons, classes.flexStart)}>
            <FormatForm form="exportForm" initialValues={{export: 'text/plain'}} />
            <Tooltip title={<Trans>Download selected baskets</Trans>}>
              <IconButton onClick={numSelected < 1 ? this.warningSelect : handleExport}>
                <CloudDownload />
              </IconButton>
            </Tooltip>
          </div>
        </Hidden>
      </div>
    );

    return (
      <div>
        {/*view for Mobile*/}
        <Hidden mdUp implementation="css">
          <Toolbar
            className={cx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            <div className={classes.title}>
              {numSelected > 0 ? (
                <>
                  <div>{Actions}</div>
                  <Typography color="inherit" variant="subtitle1">
                    <Trans>{numSelected} selected</Trans>
                  </Typography>
                </>
              ) : (
                <div className={cx(classes.actions)}>{Actions}</div>
              )}
            </div>
          </Toolbar>
        </Hidden>

        {/*view for Desktop*/}
        <Hidden mdDown implementation="css">
          <Toolbar
            className={cx(classes.root, {
              [classes.highlight]: numSelected > 0,
            })}
          >
            <div className={classes.title}>
              {numSelected > 0 && (
                <>
                  <Typography color="inherit" variant="subtitle1">
                    <Trans>{numSelected} selected</Trans>
                  </Typography>
                </>
              )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>{Actions}</div>
          </Toolbar>
        </Hidden>
      </div>
    );
  }
}

CartsTableToolbar2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(cartsTableToolbarStyle),
  reduxForm({
    form: 'exportCart',
  })
)(CartsTableToolbar2);
