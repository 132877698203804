import commonTableToolbarStyle from '_assets/projectSpecific/jss/components/Toolbars/commonTableToolbarStyle';
import Button from '_components/global/Buttons/Button';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Trans} from '@lingui/macro';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';

class CustomersInDiscountGroupsTableToolbar extends Component {
  render() {
    const {classes, openInsertCustomers, openDeleteCustomers, disabled, deleteDisabled} =
      this.props;

    return (
      <Toolbar className={cx(classes.root)}>
        {!disabled && (
          <>
            <Tooltip title={<Trans>Insert customers</Trans>}>
              <Button
                aria-label="InsertCustomers"
                onClick={openInsertCustomers}
                disabled={disabled}
              >
                <Trans>Insert customers</Trans>
              </Button>
            </Tooltip>
            {!deleteDisabled && (
              <Tooltip title={<Trans>Delete customers from discount group</Trans>}>
                <Button
                  aria-label="DeleteCustomers"
                  onClick={openDeleteCustomers}
                  disabled={disabled}
                >
                  <Trans>Delete customers</Trans>
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </Toolbar>
    );
  }
}

CustomersInDiscountGroupsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(commonTableToolbarStyle)(CustomersInDiscountGroupsTableToolbar);
