import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
  useEffect(() => {
    document.getElementById('content-wrapper').scrollTop = 0;
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('loggedUser') ? (
          <Component {...props} />
        ) : (
          <Redirect to={{pathname: '/auth/sign-in', state: {from: props.location}}} />
        )
      }
    />
  );
};

export default PrivateRoute;
