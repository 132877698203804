import {globalPermissionsFormStyle} from '_assets/projectSpecific/jss/components/Forms/globalPermissionsFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import {maxLength100, maxLength255, required} from '_helpers/global/FieldLevelValidation';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {withStyles} from '@material-ui/core/styles';

const GlobalPermissionsForm = ({classes, handleSubmit, pristine, reset, submitting, form}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.permissionForm}>
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Field
            name="codename"
            label={<Trans>Permission</Trans>}
            id="codename"
            component={FormTextField}
            validate={[required, maxLength100]}
            required
          />
        </GridItem>
        <GridItem xs={12}>
          <Field
            name="name"
            label={<Trans>Description</Trans>}
            id="name"
            component={FormTextField}
            validate={[required, maxLength255]}
            required
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className={classes.formActions}>
            <Button
              onClick={reset}
              className={cx(classes.button)}
              disabled={pristine || submitting}
            >
              <Trans>Reset</Trans>
            </Button>
            <Button
              className={cx(classes.button, classes.blue)}
              disabled={pristine || submitting}
              type="submit"
            >
              <Trans>Save</Trans>
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(globalPermissionsFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(GlobalPermissionsForm);
