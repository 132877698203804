import {
  FETCH_CUSTOMERS_DELIVERY_OPTIONS_FAIL,
  FETCH_CUSTOMERS_DELIVERY_OPTIONS_REQUEST,
  FETCH_CUSTOMERS_DELIVERY_OPTIONS_SUCCESS,
  FETCH_CUSTOMERS_FAIL,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_FAIL,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_FAIL,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_REQUEST,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_SUCCESS,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_REQUEST,
  FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_SUCCESS,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMER_FAIL,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
} from '_actions/projectSpecific/types';

const initCustomersState = {
  data: [],
  total: 0,
};

const initCustomersDeliveryOptionsState = {
  data: [],
  total: 0,
};
const initCustomersInDiscountGroups = {
  data: [],
  total: 0,
  loading: true,
};

const initCustomerState = {
  data: {},
  loading: true,
};

export const CustomersReducer = (state = initCustomersState, action = null) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_REQUEST:
      return {
        ...state,
      };

    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
      };
    case FETCH_CUSTOMERS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export const CustomersDeliveryOptionsReducer = (
  state = initCustomersDeliveryOptionsState,
  action = null
) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_DELIVERY_OPTIONS_REQUEST:
      return {
        ...state,
      };

    case FETCH_CUSTOMERS_DELIVERY_OPTIONS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
      };
    case FETCH_CUSTOMERS_DELIVERY_OPTIONS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export const CustomerReducer = (state = initCustomerState, action = null) => {
  switch (action.type) {
    case FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_CUSTOMER_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const CustomersInDiscountGroupsReducer = (
  state = initCustomersInDiscountGroups,
  action = null
) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
        loading: false,
      };
    case FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const CustomersInDiscountGroupsInsertReducer = (
  state = initCustomersInDiscountGroups,
  action = null
) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
        loading: false,
      };
    case FETCH_CUSTOMERS_IN_DISCOUNT_GROUPS_INSERT_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
