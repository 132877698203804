import Dialog from '_components/global/Dialogs/Dialog';
import GlobalPermissionsForm from '_components/projectSpecific/Forms/GlobalPermissionsForm';
import React from 'react';
import {Trans} from '@lingui/macro';

const EditPermissionsDialog = ({handleClose, open, onSubmit, initValues}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={<Trans>Edit permission: {initValues.codename}</Trans>}
      content={
        <GlobalPermissionsForm
          form="editPermission"
          initialValues={initValues}
          onSubmit={onSubmit}
        />
      }
    />
  );
};

export default EditPermissionsDialog;
