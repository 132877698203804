import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {getLastLogin} from '_actions/projectSpecific/lastLoginActions';
import {lastLoginPageStyle} from '_assets/projectSpecific/jss/views/lastLoginPageStyle';
import Card from '_components/global/Card/Card.jsx';
import CardBody from '_components/global/Card/CardBody.jsx';
import CardHeader from '_components/global/Card/CardHeader.jsx';
import CardIcon from '_components/global/Card/CardIcon.jsx';
import GridContainer from '_components/global/Grid/GridContainer.jsx';
import GridItem from '_components/global/Grid/GridItem.jsx';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import FilterLastLoginForm from '_components/projectSpecific/Forms/FilterLastLoginForm';
import {DATE_TIME_FORMAT} from '_config/configConstants';
import authorizedAbility from '_helpers/global/Authorization';
import {getComputedOrderBy, prepareSortingState} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import moment from 'moment';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';
import {HowToReg} from '@material-ui/icons';

const rows = [
  {
    id: 'user__username',
    label: <Trans>Username</Trans>,
    sortable: true,
  },
  {
    id: 'ip_address',
    label: <Trans>IP address</Trans>,
    sortable: true,
  },
  {
    id: 'created_at',
    label: <Trans>Date of last login</Trans>,
    sortable: true,
  },
  {
    id: 'browser_identification',
    label: <Trans>Identification</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      updateGlobalSettings,
      getLastLogin,
    },
    dispatch
  );
};

class LastLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      page: 0,
      orderBy: this.props.LastLoginListFilter.orderBy,
      username: this.props.LastLoginListFilter.username,
      createdAt: this.props.LastLoginListFilter.createdAt,
    };
    if (authorizedAbility.cannot('read', 'LastLogin')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchLastLogin();
  };

  fetchLastLogin = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getLastLogin(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order),
        this.state.username,
        this.state.createdAt
      )
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  handleRequestSort = (event, property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchLastLogin()
    );
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchLastLogin());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchLastLogin());
    });
  };

  filterLastLogin = (values) => {
    this.setState(
      {
        username: values.username,
        createdAt: values.createdAt,
      },
      () => this.fetchLastLogin()
    );
  };

  handleResetLastLoginFiltering = () => {
    this.setState(
      {
        username: null,
        createdAt: null,
      },
      () => this.fetchLastLogin()
    );
  };

  render() {
    const {classes} = this.props;
    const {order, page, orderBy} = this.state;
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <b>
                  <Trans>Filter</Trans>
                </b>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <FilterLastLoginForm
                form="filterLastLoginForm"
                initialValues={this.props.LastLoginListFilter}
                onSubmit={this.filterLastLogin}
                handleReset={this.handleResetLastLoginFiltering}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <HowToReg />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Last login</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <SimpleTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.lastLogin.length}
                    rows={rows}
                  />
                  <TableBody>
                    {this.props.lastLogin.map((item) => {
                      const {id, created_at, username, ip_address, browser_identification} = item;

                      return (
                        <TableRow hover tabIndex={-1} key={id}>
                          <TableCell classes={{root: classes.cell}}>{username}</TableCell>
                          <TableCell classes={{root: classes.cell}}>{ip_address}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {moment(created_at).format(DATE_TIME_FORMAT)}
                          </TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            {browser_identification}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <CustomTablePagination
                rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                count={this.props.total}
                rowsPerPage={this.props.globalSettings.itemsPerPage}
                page={page}
                changePage={this.handleChangePage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(lastLoginPageStyle),
  connect((store) => {
    return {
      LastLoginListFilter: store.AppFilterStates.lastLoginListFilter,
      lastLogin: store.LastLogin.data,
      total: store.LastLogin.total,
      globalSettings: store.GlobalSettings,
    };
  }, mapDispatchToProps)
)(LastLoginPage);
