import {blackColor} from '_assets/global/jss/styleHelpers';

export const customSimpleSelectStyle = (theme) => ({
  formControl: {
    margin: '10px auto',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
    minWidth: 120,
    width: '100%',
    position: 'relative',
    '& button': {
      position: 'absolute',
      top: '10px',
      right: '-32px',
      '&:hover': {
        backgroundColor: 'transparent',
        color: blackColor,
      },
    },
  },
  inputLabel: {
    fontSize: '14px',
  },
});
