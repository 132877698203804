import {filterCustomerFormStyle} from '_assets/projectSpecific/jss/components/Forms/filterCustomerFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Replay, Search} from '@material-ui/icons';

const FilterUserForm = ({
  classes,
  handleSubmit,
  pristine,
  submitting,
  form,
  handleReset,
  reset,
}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.flex}>
      <GridContainer className={classes.cartFormFields}>
        <GridItem xs={12} md={3}>
          <Field
            name="q"
            label={<Trans>Username</Trans>}
            id="q"
            component={FormTextField}
            clearButton={true}
            reduxName="q"
            formName={form}
          />
        </GridItem>
        <GridItem xs={12} md={9}>
          <div className={classes.cartButtons}>
            <Tooltip title={<Trans>Find</Trans>}>
              <Button color="primary" type="submit">
                <Search />
              </Button>
            </Tooltip>
            <Tooltip title={<Trans>Reset filter</Trans>}>
              <Button
                onClick={() => {
                  reset();
                  handleReset();
                }}
                color="primary"
              >
                <Replay />
              </Button>
            </Tooltip>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(filterCustomerFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(FilterUserForm);
