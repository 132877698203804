import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {FETCH_BRANCHES_REQUEST, FETCH_BRANCHES_SUCCESS} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/global/functions';

export function getBranches() {
  return function (dispatch) {
    dispatch({type: FETCH_BRANCHES_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.branches)
      .then((response) => {
        dispatch({
          type: FETCH_BRANCHES_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}
