import {
  clearButtonStyle,
  clearButtonTheme,
} from '_assets/global/jss/components/Buttons/clearButtonStyle';
import cx from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {change} from 'redux-form';
import {MuiThemeProvider} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import {HighlightOff} from '@material-ui/icons';

const ClearButton = ({classes, formName, reduxName, dispatch, clearButtonClasses}) => {
  return (
    <MuiThemeProvider theme={clearButtonTheme}>
      <IconButton
        aria-label="Reset input"
        className={cx(classes.clearButton, clearButtonClasses)}
        onClick={() => {
          dispatch(change(formName, reduxName, ''));
        }}
      >
        <HighlightOff />
      </IconButton>
    </MuiThemeProvider>
  );
};

export default compose(withStyles(clearButtonStyle), connect())(ClearButton);
