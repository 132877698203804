import faviconMiddleware from 'redux-favicon';

const faviconConfig = {
  animation: 'none',
  position: 'down',
  type: 'circle',
  bgColor: '#e81d24',
  textColor: '#ffffff',
};
export const loadedFaviconMiddleware = faviconMiddleware(faviconConfig);
