import {UPDATE_GLOBAL_SETTINGS_SUCCESS} from '_actions/global/types';

const initState = localStorage.getItem('globalSettings')
  ? JSON.parse(localStorage.getItem('globalSettings'))
  : {
      itemsPerPage: 20,
      rowsPerPageOptions: [5, 10, 20, 50],
      currentLanguage: 'en',
    };

export const globalSettingsReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_GLOBAL_SETTINGS_SUCCESS) {
    const settings = {
      ...state,
      ...action.data,
    };

    localStorage.setItem('globalSettings', JSON.stringify(settings));
    return settings;
  }
  return state;
};
