import Dialog from '_components/global/Dialogs/Dialog';
import GlobalSettingsForm from '_components/projectSpecific/Forms/GlobalSettingsForm';
import React from 'react';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {Trans} from '@lingui/macro';

const GlobalSettingsDialog = ({handleClose, open, onSubmit, initValues, ...props}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={<Trans>Default settings for {initValues && initValues.username}</Trans>}
      content={
        <GlobalSettingsForm
          form="changeSettings"
          initialValues={initValues}
          onSubmit={onSubmit}
          submitName={<Trans>Save changes</Trans>}
          authType={props.authType && props.authType}
        />
      }
    />
  );
};

const selector = formValueSelector('changeSettings');

export default connect((store) => {
  return {
    authType: selector(store, 'auth_type'),
  };
})(GlobalSettingsDialog);
