import {UPDATE_NOTIFICATIONS} from '_actions/global/types';

const initState = {
  carts: 0,
};

export const NotificationReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_NOTIFICATIONS) {
    return {
      ...state,
      ...action.data,
    };
  }
  return state;
};
