import Dialog from '_components/global/Dialogs/Dialog';
import GlobalDeliveryOptionsForm from '_components/projectSpecific/Forms/GlobalDeliveryOptionsForm';
import React from 'react';
import {Trans} from '@lingui/macro';

const EditDeliveryOptionsDialog = ({handleClose, open, onSubmit, initValues}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={<Trans>Edit delivery term</Trans>}
      content={
        <GlobalDeliveryOptionsForm
          form="editDeliveryOption"
          initialValues={initValues}
          onSubmit={onSubmit}
          submitName={<Trans>Save changes</Trans>}
        />
      }
    />
  );
};

export default EditDeliveryOptionsDialog;
