import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {UPDATE_FILTERS_STATE} from '_actions/global/types';
import {FETCH_LAST_LOGIN_REQUEST, FETCH_LAST_LOGIN_SUCCESS} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/global/functions';
import moment from 'moment/moment';

export function getLastLogin(
  offset = 0,
  limit = 20,
  orderBy = null,
  username = null,
  createdAt = null
) {
  return function (dispatch) {
    const lastLoginFilter = {
      lastLoginListFilter: {
        orderBy,
        username,
        createdAt,
      },
    };
    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: lastLoginFilter,
    });
    let filterFrom = null;
    if (createdAt instanceof moment) {
      filterFrom = createdAt.clone();
      filterFrom.hours(0);
      filterFrom.minutes(0);
      filterFrom.seconds(0);
      filterFrom.utc();
    }
    let filterTo = null;
    if (createdAt instanceof moment) {
      filterTo = createdAt.clone();
      filterTo.hours(23);
      filterTo.minutes(59);
      filterTo.seconds(59);
      filterTo.utc();
    }
    dispatch({type: FETCH_LAST_LOGIN_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.lastLogin, {
      offset: offset,
      limit: limit,
      order_by: orderBy ? orderBy : null,
      user__username__startswith: username ? username : null,
      created_at__gte: createdAt instanceof moment ? filterFrom.format() : null,
      created_at__lte: createdAt instanceof moment ? filterTo.format() : null,
    })
      .then((response) => {
        dispatch({
          type: FETCH_LAST_LOGIN_SUCCESS,
          data: response.data,
          total: response.data.meta.total_count,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}
