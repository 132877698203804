import {dialogStyle} from '_assets/global/jss/components/Dialogs/dialogStyle';
import DialogActions from '_components/global/Dialogs/DialogActions';
import DialogTitle from '_components/global/Dialogs/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const Dialog = (props) => {
  const {
    handleClose,
    handleSubmit,
    submitName,
    open,
    classes,
    fullScreen,
    title,
    content,
    maxWidth,
    dialogActions,
    ...rest
  } = props;

  return (
    <MuiDialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="permission_dialog"
      open={open}
      {...rest}
    >
      <DialogTitle id="permission_dialog" onClose={handleClose}>
        {title}
      </DialogTitle>

      <DialogContent className={classes.root}>{content}</DialogContent>

      {dialogActions && (
        <DialogActions
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          submitName={submitName}
        />
      )}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool,
  fullScreen: PropTypes.bool,
  dialogActions: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  content: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  submitName: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
};

export default compose(withMobileDialog(), withStyles(dialogStyle))(Dialog);
