const tabsStyle = (theme) => ({
  tabs: {
    padding: '10px 0px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
});

export default tabsStyle;
