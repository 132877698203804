import {
  deliveryOptionsFormStyle,
  tableHeadLightTheme,
  tableHeadTheme,
} from '_assets/projectSpecific/jss/components/Forms/deliveryOptionsFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormCheckbox} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import cx from 'classnames';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';
import {Replay} from '@material-ui/icons';

const DeliveryOptionsForm = (props) => {
  const {handleSubmit, pristine, submitting, form, rows, classes, options, reset} = props;
  return (
    <GridContainer>
      <GridItem xs={12}>
        <form onSubmit={handleSubmit} className={classes.margin}>
          <div className={cx(classes.tableWrapper, classes.marginBottom)}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              {form === 'addCustomerDeliveryTerms' ? (
                <MuiThemeProvider theme={tableHeadLightTheme}>
                  <SimpleTableHead rows={rows} />
                </MuiThemeProvider>
              ) : (
                <MuiThemeProvider theme={tableHeadTheme}>
                  <SimpleTableHead rows={rows} />
                </MuiThemeProvider>
              )}
              <TableBody>
                {options.map((option, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell>
                        <Field
                          name={`options.${option.id}`}
                          id={`options.${option.id}`}
                          component={FormCheckbox}
                          typeTable={true}
                        />
                      </TableCell>
                      <TableCell>{option.name}</TableCell>
                      <TableCell>{option.description}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          {form === 'editCustomerDeliveryTerms' ? (
            <div className={classes.formActions}>
              <Button
                onClick={reset}
                className={cx(classes.button)}
                disabled={pristine || submitting}
              >
                <Trans>Reset</Trans>
              </Button>
              <Button
                className={cx(classes.button, classes.blue)}
                disabled={pristine || submitting}
                type="submit"
              >
                <Trans>Save changes</Trans>
              </Button>
            </div>
          ) : (
            <GridItem xs={12} className={classes.flex}>
              <IconButton onClick={reset} disabled={pristine || submitting}>
                <Replay />
              </IconButton>
            </GridItem>
          )}
        </form>
      </GridItem>
    </GridContainer>
  );
};

export default compose(
  withStyles(deliveryOptionsFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(DeliveryOptionsForm);
