import Dialog from '_components/global/Dialogs/Dialog';
import GlobalDeliveryOptionsForm from '_components/projectSpecific/Forms/GlobalDeliveryOptionsForm';
import React from 'react';
import {Trans} from '@lingui/macro';

const InsertDeliveryOptionsDialog = ({handleClose, open, onSubmit}) => {
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={<Trans>Insert new delivery term</Trans>}
      content={
        <GlobalDeliveryOptionsForm
          form="insertDeliveryOption"
          initialValues={{currency: 'CHF'}}
          onSubmit={onSubmit}
          submitName={<Trans>Save</Trans>}
        />
      }
    />
  );
};

export default InsertDeliveryOptionsDialog;
