import dashboardStyle from '_assets/projectSpecific/jss/views/dashboardStyle';
import Card from '_components/global/Card/Card';
import CardBody from '_components/global/Card/CardBody';
import CardHeader from '_components/global/Card/CardHeader';
import CardIcon from '_components/global/Card/CardIcon';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans} from '@lingui/macro';
import withStyles from '@material-ui/core/styles/withStyles';
import {Dashboard as DashboardIcon} from '@material-ui/icons';

class Dashboard extends React.Component {
  state = {
    value: 0,
  };
  handleChange = (event, value) => {
    this.setState({value});
  };
  handleChangeIndex = (index) => {
    this.setState({value: index});
  };

  render() {
    const {classes} = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Card>
              <CardHeader color="blue" icon>
                <CardIcon color="blue">
                  <DashboardIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <Trans>Dashboard</Trans>
                </h4>
              </CardHeader>
              <CardBody>
                <div className={classes.dashboard}>
                  <h3>Space for display</h3>
                  <ul>
                    <li>Summary info</li>
                    <li>Statistics</li>
                    <li>...</li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Dashboard);
