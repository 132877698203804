import {
  FETCH_GROUPS_FAIL,
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
} from '_actions/projectSpecific/types';

const initGroupsState = {
  data: [],
};

export const GroupsReducer = (state = initGroupsState, action) => {
  switch (action.type) {
    case FETCH_GROUPS_REQUEST:
      return {
        ...state,
      };

    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
      };
    case FETCH_GROUPS_FAIL:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
