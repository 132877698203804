import {
  FETCH_DISCOUNT_GROUPS_FAIL,
  FETCH_DISCOUNT_GROUPS_REQUEST,
  FETCH_DISCOUNT_GROUPS_SUCCESS,
} from '_actions/projectSpecific/types';

const initDiscountGroupsState = {
  data: [],
  total: 0,
  loading: true,
};

export const DiscountGroupsReducer = (state = initDiscountGroupsState, action = null) => {
  switch (action.type) {
    case FETCH_DISCOUNT_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_DISCOUNT_GROUPS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        total: action.total,
        loading: false,
      };
    case FETCH_DISCOUNT_GROUPS_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
