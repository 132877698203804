import {discountGroupsListFormStyle} from '_assets/projectSpecific/jss/components/Forms/discountGroupsListFormStyle';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import cx from 'classnames';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const ImportDiscountGroupsList = (props) => {
  const {classes, rows, order, orderBy, data} = props;

  const lengthOfGroups = data.map((d) => d.discount_groups.length);
  const maxGroups = Math.max(...lengthOfGroups);

  const renderEmptyCells = () => {
    const emptyCells = [];

    for (let i = 0; i < maxGroups; i++) {
      emptyCells.push(
        <TableCell key={'empty' + i} className={classes.cell}>
          {0}
        </TableCell>
      );
    }

    return emptyCells;
  };

  return (
    <Table className={classes.table} aria-labelledby="tableTitle">
      <SimpleTableHead order={order} orderBy={orderBy} rows={rows} />
      <TableBody>
        {data.map((category) => {
          const {category_id, category_description, discount_groups} = category;
          return (
            <TableRow key={category_id} hover tabIndex={-1}>
              <TableCell className={cx(classes.cell, classes.width20)}>{category_id}</TableCell>
              <TableCell className={cx(classes.cell, classes.width20)}>
                {category_description}
              </TableCell>
              {discount_groups && discount_groups.length
                ? discount_groups.map((group, index) => (
                    <TableCell key={index} className={classes.cell}>
                      {group.value}
                    </TableCell>
                  ))
                : renderEmptyCells()}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default withStyles(discountGroupsListFormStyle)(ImportDiscountGroupsList);
