import {
  detailCartFormStyle,
  selectFieldTheme,
} from '_assets/projectSpecific/jss/components/Forms/detailCartFormStyle';
import {FormDateField, FormSelectField, FormTextField} from '_components/global/Form/FormFields';
import {STATUS_1} from '_config/configConstants';
import cx from 'classnames';
import React, {useState} from 'react';
import {Field} from 'redux-form';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Launch} from '@material-ui/icons';

const CartItemRow = ({cartItem, cartIndex, deliveryOptions, classes}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElKey, setAnchorElKey] = useState(null);

  const handlePopoverOpen = (event, cartIndex) => {
    setAnchorEl(event.currentTarget);
    setAnchorElKey(cartIndex);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setAnchorElKey(null);
  };

  const {
    product_no,
    description,
    homologation,
    vehicle,
    availability,
    reseller_price,
    ordered,
    delivered,
    discount,
    discount_code,
    list_price,
    customer_price,
    reference,
    catalogue_url,
    active_no,
  } = cartItem;

  return (
    <TableRow hover tabIndex={-1} key={cartIndex}>
      <TableCell classes={{root: classes.cell}}>
        <div className={cx(classes.firstRow, classes.flexSpace)}>
          <Tooltip title={catalogue_url ? catalogue_url : ''}>
            <a
              href={catalogue_url}
              target="blank"
              className={cx(classes.smallIcon, classes.margin)}
            >
              <div className={classes.activeNumber}>
                <span>{active_no}</span>
                <span>{product_no}</span>
              </div>
              <Launch fontSize="small" />
            </a>
          </Tooltip>
        </div>
        <div className={classes.lastRow}>
          {description && description.length > 30 ? (
            <div className={classes.description}>
              <Typography
                aria-owns={
                  cartIndex === anchorElKey ? `mouse-over-popover-${cartIndex}` : undefined
                }
                aria-haspopup="true"
                onMouseEnter={(event) => handlePopoverOpen(event, cartIndex)}
                onMouseLeave={handlePopoverClose}
              >
                {description.slice(0, 30)}...
              </Typography>
              <Popover
                id={`mouse-over-popover-${cartIndex}`}
                className={classes.popover}
                open={cartIndex === anchorElKey}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>{description}</Typography>
              </Popover>
            </div>
          ) : (
            <span>{description}</span>
          )}
        </div>
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div className={classes.hidden}>
          <Field
            name={`items[${cartIndex}].pk`}
            label={<Trans>Product id</Trans>}
            id={`items[${cartIndex}].pk`}
            component={FormTextField}
          />
        </div>
        <Field
          name={`items[${cartIndex}].delivery_date`}
          dateLabel={<Trans>Delivery date</Trans>}
          id={`items[${cartIndex}].delivery_date`}
          component={FormDateField}
        />
        <MuiThemeProvider theme={selectFieldTheme}>
          <Field
            name={`items[${cartIndex}].delivery_option_id`}
            label={<Trans>Delivery option</Trans>}
            id={`items[${cartIndex}].delivery_option_id`}
            type="select"
            component={FormSelectField}
            options={deliveryOptions}
          />
        </MuiThemeProvider>
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div className={classes.firstRow}>{homologation}</div>
        <div className={classes.lastRow}>{vehicle}</div>
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div className={classes.firstRow}>{discount ? `${discount} %` : ''}</div>
        <div className={classes.lastRow}>
          {discount_code ? discount_code : <strong>&mdash;</strong>}
        </div>
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div className={classes.firstRow}>{list_price}</div>
        <div className={classes.lastRow}>{customer_price}</div>
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div className={classes.firstRow}>{availability}</div>
        <div className={classes.lastRow}>
          <strong>{reseller_price}</strong>
        </div>
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div className={classes.firstRow}>{delivered}</div>
        <div className={classes.lastRow}>{ordered}</div>
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div className={classes.deliverField}>
          <Field
            name={`items[${cartIndex}].deliver_qty`}
            label={<Trans>Deliver qty</Trans>}
            id={`items[${cartIndex}].deliver_qty`}
            type="number"
            component={FormTextField}
            additionalInputProps={{step: 1}}
          />
          <div className={classes.orderField}>
            <Field
              name={`items[${cartIndex}].order_qty`}
              label={<Trans>Order qty RHIAG</Trans>}
              id={`items[${cartIndex}].order_qty`}
              type="number"
              component={FormTextField}
              additionalInputProps={{step: 1}}
            />
          </div>
        </div>
      </TableCell>
      <TableCell classes={{root: classes.cell}}>
        <div className={classes.rowWithSelect}>{reference}</div>
        <MuiThemeProvider theme={selectFieldTheme}>
          <Field
            name={`items[${cartIndex}].status`}
            label={<Trans>Status</Trans>}
            id={`items[${cartIndex}].status`}
            type="select"
            component={FormSelectField}
            options={STATUS_1}
          />
        </MuiThemeProvider>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(detailCartFormStyle)(CartItemRow);
