import {HttpClient} from '_api/httpClient';
import {API_ENDPOINT_MAP} from '_api/endpoints';
import {
  FETCH_DELIVERY_OPTIONS_REQUEST,
  FETCH_DELIVERY_OPTIONS_SUCCESS,
  FETCH_MY_DELIVERY_OPTIONS_REQUEST,
  FETCH_MY_DELIVERY_OPTIONS_SUCCESS,
} from '_actions/projectSpecific/types';
import {globalApiErrorHandler} from '_helpers/global/functions';
import {SubmissionError} from 'redux-form';

export function getDeliveryOptions() {
  return function (dispatch) {
    dispatch({type: FETCH_DELIVERY_OPTIONS_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.deliveryOptions)
      .then((response) => {
        dispatch({
          type: FETCH_DELIVERY_OPTIONS_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}

export function addCustomerDeliveryOptions(customer_ids, delivery_option_ids, isSet, isDelete) {
  return function () {
    return HttpClient.post(API_ENDPOINT_MAP.deliveryOptionMemberships, {
      customer_ids: customer_ids,
      delivery_option_ids: delivery_option_ids,
      is_set: isSet,
      is_delete: isDelete,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function getCustomersDeliveryOptionsMembershipExport(
  CustomerNameNo = null,
  deliveryTerms = null,
  format = null
) {
  return function () {
    return HttpClient.get(
      API_ENDPOINT_MAP.deliveryOptionMemberships,
      {
        q: CustomerNameNo,
        delivery_terms: deliveryTerms,
        format: format,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/csv',
        },
      }
    )
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function bulkExportDeliveryOptionsMembership(customerIds) {
  return function () {
    return HttpClient.post(
      `${API_ENDPOINT_MAP.deliveryOptionMemberships}/export/`,
      {
        customer_ids: customerIds,
        format: 'text/csv',
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/csv',
        },
      }
    )
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function insertDeliveryOption(formParams) {
  return function () {
    return HttpClient.post(API_ENDPOINT_MAP.deliveryOptions, {
      ...formParams,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function editDeliveryOption(delivery_option_id, formParams) {
  return function () {
    return HttpClient.patch(`${API_ENDPOINT_MAP.deliveryOptions}${delivery_option_id}/`, {
      ...formParams,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function deleteDeliveryOption(delivery_option_id) {
  return function () {
    return HttpClient.del(`${API_ENDPOINT_MAP.deliveryOptions}${delivery_option_id}/`)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        if (error.response) {
          throw new SubmissionError({
            ...error.response,
            _error: error.response.data.error_message,
          });
        }
      });
  };
}

export function getMyDeliveryOptions() {
  return function (dispatch) {
    dispatch({type: FETCH_MY_DELIVERY_OPTIONS_REQUEST});
    return HttpClient.get(API_ENDPOINT_MAP.myDeliveryOptions)
      .then((response) => {
        dispatch({
          type: FETCH_MY_DELIVERY_OPTIONS_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        globalApiErrorHandler(error);
      });
  };
}
