import {login} from '_actions/global/authActions';
import signInPageStyle from '_assets/global/jss/views/signInPageStyle';
import SignInForm from '_components/global/Form/SignInForm';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  );
};

const SignInPage = (props) => {
  const handleSignInFormSubmit = (values) => {
    return props.login(values);
  };

  const {classes} = props;
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} md={4}>
          <SignInForm onSubmit={handleSignInFormSubmit} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default compose(
  withStyles(signInPageStyle),
  connect(() => {
    return {};
  }, mapDispatchToProps)
)(SignInPage);
