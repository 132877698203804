import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {
  deletePermission,
  editPermission,
  getPermissions,
  insertPermission,
} from '_actions/projectSpecific/permissionsActions';
import {permissionsPageStyle} from '_assets/projectSpecific/jss/views/permissionsPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import Card from '_components/global/Card/Card.jsx';
import CardBody from '_components/global/Card/CardBody.jsx';
import CardHeader from '_components/global/Card/CardHeader.jsx';
import CardIcon from '_components/global/Card/CardIcon.jsx';
import GridContainer from '_components/global/Grid/GridContainer.jsx';
import GridItem from '_components/global/Grid/GridItem.jsx';
import WarningAlert from '_components/global/SweetAlerts/WarningSweetAlert';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import EditPermissionsDialog from '_components/projectSpecific/Dialogs/EditPermissionsDialog';
import InsertPermissionsDialog from '_components/projectSpecific/Dialogs/InsertPermissionsDialog';
import PermissionsTableToolbar from '_components/projectSpecific/Toolbars/PermissionsTableToolbar';
import authorizedAbility from '_helpers/global/Authorization';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import cx from 'classnames';
import {diff} from 'deep-object-diff';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {Trans} from '@lingui/macro';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Delete, Edit, VpnKey} from '@material-ui/icons';

const rows = [
  {
    id: 'codename',
    label: <Trans>Name</Trans>,
    sortable: true,
  },
  {
    id: 'name',
    label: <Trans>Description</Trans>,
    sortable: true,
  },
  {
    id: 'actions',
    label: <Trans>Actions</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPermissions,
      updateLoader,
      deletePermission,
      insertPermission,
      editPermission,
      updateGlobalSettings,
    },
    dispatch
  );
};

class PermissionsPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      page: 0,
      orderBy: null,
      alert: null,
      openInsert: false,
      openEdit: false,
      currentPermission: null,
    };
    if (authorizedAbility.cannot('read', 'Permissions')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchPermissions();
  };

  fetchPermissions = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getPermissions(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderBy, this.state.order)
      )
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  handleRequestSort = (property) => {
    this.setState(prepareSortingState(this.state.orderBy, this.state.order, property), () =>
      this.fetchPermissions()
    );
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchPermissions());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchPermissions());
    });
  };

  warningDeletePermission = (codename, id) => {
    this.setState({
      alert: (
        <WarningAlert
          title={<Trans>Are you sure?</Trans>}
          confirmCallback={() => this.confirmDeletePermission(codename, id)}
          closeCallback={() => this.hideAlert()}
          confirmBtnText={<Trans>Yes, delete!</Trans>}
          showCancel={true}
        >
          <Trans>You will delete permission {codename}!</Trans>
        </WarningAlert>
      ),
    });
  };

  confirmDeletePermission = (codename, id) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .deletePermission(id)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(
          <Trans>Permission: {codename} was successfully deleted.</Trans>
        );
        this.hideAlert();
        this.fetchPermissions();
      })
      .catch((error) => {
        this.hideAlert();
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error);
      });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  handleOpenPermissionInsert = () => {
    this.setState({openInsert: true});
  };

  handleClosePermissionInsert = () => {
    this.setState({openInsert: false});
  };

  insertNewPermissions = (values) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .insertPermission(values)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Permission was successfully created.</Trans>, {
          autoClose: false,
        });
        this.handleClosePermissionInsert();
        this.fetchPermissions();
      })
      .catch((error) => {
        this.handleClosePermissionInsert();
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error);
      });
  };

  handleOpenPermissionEdit = (permission) => {
    this.setState({
      openEdit: true,
      currentPermission: permission,
    });
  };

  handleClosePermissionEdit = () => {
    this.setState({
      openEdit: false,
      currentPermission: null,
    });
  };

  updatePermissions = (values) => {
    let id = this.state.currentPermission.id;
    this.props.updateLoader({globalLoading: true});
    return this.props
      .editPermission(id, diff(this.state.currentPermission, values))
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Permission was successfully edited.</Trans>);
        this.handleClosePermissionInsert();
        this.fetchPermissions();
      })
      .catch((error) => {
        this.handleClosePermissionInsert();
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error);
      });
  };

  render() {
    const {classes} = this.props;
    const initValues = {num_of_amount: 999, default_value: 0};
    const wholeInitValues = {...this.state.currentPermission, ...initValues};

    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <VpnKey />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Permissions for wholeselers</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <PermissionsTableToolbar
                handlePermissionInsert={() => this.handleOpenPermissionInsert()}
              />
              <InsertPermissionsDialog
                open={this.state.openInsert}
                handleClose={this.handleClosePermissionInsert}
                onSubmit={(values) => {
                  return this.insertNewPermissions(values);
                }}
              />
              <EditPermissionsDialog
                open={this.state.openEdit}
                handleClose={this.handleClosePermissionEdit}
                initValues={wholeInitValues}
                onSubmit={(values) => {
                  return this.updatePermissions(values);
                }}
              />
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <SimpleTableHead
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.props.permissions.length}
                    rows={rows}
                  />
                  <TableBody>
                    {this.props.permissions.map((permission) => {
                      const {id, codename, name} = permission;

                      return (
                        <TableRow hover tabIndex={-1} key={id}>
                          <TableCell classes={{root: classes.cell}}>{codename}</TableCell>
                          <TableCell classes={{root: classes.cell}}>{name}</TableCell>
                          <TableCell classes={{root: classes.cell}}>
                            <div className={cx('actions-right', classes.actions)}>
                              <Can I="update" a="Permissions">
                                <BaseActionsButton
                                  color="success"
                                  onClick={() => this.handleOpenPermissionEdit(permission)}
                                >
                                  <Tooltip title={<Trans>Edit permission</Trans>}>
                                    <Edit />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                              <Can I="delete" a="Permissions">
                                <BaseActionsButton
                                  color="danger"
                                  onClick={() => this.warningDeletePermission(codename, id)}
                                >
                                  <Tooltip title={<Trans>Delete permission</Trans>}>
                                    <Delete />
                                  </Tooltip>
                                </BaseActionsButton>
                              </Can>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <CustomTablePagination
                rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                count={this.props.total}
                rowsPerPage={this.props.globalSettings.itemsPerPage}
                page={this.state.page}
                changePage={this.handleChangePage}
                changeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default compose(
  withStyles(permissionsPageStyle),
  connect((store) => {
    return {
      permissions: store.Permissions.data,
      total: store.Permissions.total,
      globalSettings: store.GlobalSettings,
    };
  }, mapDispatchToProps)
)(PermissionsPage);
