import {UPDATE_AUTH} from '_actions/global/types';

const initState = {
  logged: localStorage.getItem('loggedUser') ? JSON.parse(localStorage.getItem('loggedUser')) : {},
  impersonated: localStorage.getItem('impersonatedUser')
    ? JSON.parse(localStorage.getItem('impersonatedUser'))
    : {},
};

export const AuthReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_AUTH) {
    const updatedState = {
      ...state,
      ...action.data,
    };

    if (state.logged !== updatedState.logged) {
      if (updatedState.logged === {}) {
        localStorage.removeItem('loggedUser');
      } else {
        localStorage.setItem('loggedUser', JSON.stringify(updatedState.logged));
      }
    }
    if (state.impersonated !== updatedState.impersonated) {
      if (updatedState.impersonated === {}) {
        localStorage.removeItem('impersonatedUser');
      } else {
        localStorage.setItem('impersonatedUser', JSON.stringify(updatedState.impersonated));
      }
    }
    return updatedState;
  }
  return state;
};
