import {updateGlobalSettings} from '_actions/global/globalSettingsActions';
import {updateLoader} from '_actions/global/loaderActions';
import {getCustomersDeliveryTerms} from '_actions/projectSpecific/customersActions';
import {
  addCustomerDeliveryOptions,
  bulkExportDeliveryOptionsMembership,
  deleteDeliveryOption,
  editDeliveryOption,
  getCustomersDeliveryOptionsMembershipExport,
  getDeliveryOptions,
  insertDeliveryOption,
} from '_actions/projectSpecific/deliveryOptionsActions';
import {
  deliveryOptionsPageStyle,
  tableSelectTheme,
} from '_assets/projectSpecific/jss/views/deliveryOptionsPageStyle';
import BaseActionsButton from '_components/global/Buttons/BaseActionsButton';
import Card from '_components/global/Card/Card.jsx';
import CardBody from '_components/global/Card/CardBody.jsx';
import CardHeader from '_components/global/Card/CardHeader.jsx';
import CardIcon from '_components/global/Card/CardIcon.jsx';
import GridContainer from '_components/global/Grid/GridContainer.jsx';
import GridItem from '_components/global/Grid/GridItem.jsx';
import WarningAlert from '_components/global/SweetAlerts/WarningSweetAlert';
import CustomTableHead from '_components/global/Tables/CustomTableHead';
import CustomTablePagination from '_components/global/Tables/CustomTablePagination';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import SwipeableView from '_components/global/Tabs/SwipeableViews';
import TabContainer from '_components/global/Tabs/TabContainer';
import RemovableTag from '_components/global/Tags/RemovableTag';
import EditDeliveryOptionsDialog from '_components/projectSpecific/Dialogs/EditDeliveryOptionsDialog';
import InsertDeliveryOptionsDialog from '_components/projectSpecific/Dialogs/InsertDeliveryOptionsDialog';
import FilterCustomersDTMembershipForm from '_components/projectSpecific/Forms/FilterCustomersDTMembershipForm';
import CustomersDeliveryOptionsTableToolbar from '_components/projectSpecific/Toolbars/CustomersDeliveryOptionsTableToolbar';
import SettingsDeliveryOptionsTableToolbar from '_components/projectSpecific/Toolbars/SettingsDeliveryOptionsTableToolbar';
import authorizedAbility from '_helpers/global/Authorization';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import {
  fireErrorToast,
  fireSuccessToast,
  getComputedOrderBy,
  prepareSortingState,
  selectConcat,
} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import download from 'downloadjs';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {formValueSelector, getFormValues} from 'redux-form';
import {Trans} from '@lingui/macro';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {Delete, Edit, LocalShipping} from '@material-ui/icons';

const rows1 = [
  {
    id: 'customer_no',
    label: <Trans>Customer ID</Trans>,
    sortable: true,
  },
  {
    id: 'name_1',
    label: <Trans>Name</Trans>,
    sortable: true,
  },
  {
    id: 'deliveryTerms',
    label: <Trans>Delivery terms</Trans>,
    sortable: false,
  },
  {
    id: 'actions',
    label: <Trans>Actions</Trans>,
    sortable: false,
  },
];

const rows2 = [
  {
    id: 'name',
    label: <Trans>Title</Trans>,
    sortable: true,
  },
  {
    id: 'description',
    label: <Trans>Description</Trans>,
    sortable: true,
  },
  {
    id: 'price',
    label: <Trans>Price</Trans>,
    sortable: true,
  },
  {
    id: 'currency',
    label: <Trans>Currency</Trans>,
    sortable: true,
  },
  {
    id: 'customer_usages',
    label: <Trans>Usages of customers</Trans>,
    sortable: false,
  },
  {
    id: 'actions',
    label: <Trans>Actions</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoader,
      getDeliveryOptions,
      getCustomersDeliveryTerms,
      updateGlobalSettings,
      addCustomerDeliveryOptions,
      bulkExportDeliveryOptionsMembership,
      getCustomersDeliveryOptionsMembershipExport,
      insertDeliveryOption,
      editDeliveryOption,
      deleteDeliveryOption,
    },
    dispatch
  );
};

class DeliveryOptionsPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      selected: [],
      page: 0,
      orderCustomersDT: 'asc',
      orderDT: 'asc',
      orderByCustomersDT: this.props.CustomerDTListFilters.orderBy,
      CustomerNameNo: this.props.CustomerDTListFilters.CustomerNameNo,
      deliveryTerms: this.props.CustomerDTListFilters.deliveryTerms,
      orderByDT: null,
      alert: null,
      openInsert: false,
      openEdit: false,
    };
    if (authorizedAbility.cannot('read', 'DeliveryOptionMembership')) {
      browserHistoryAccessor.push('/admin/404');
    }
  }

  componentDidMount = () => {
    this.fetchDeliveryOptions().then(() => {
      this.filterCustomersDeliveryTerms(this.props.filterValues);
    });
  };

  fetchCustomersDeliveryOptions = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getCustomersDeliveryTerms(
        this.state.page * this.props.globalSettings.itemsPerPage,
        this.props.globalSettings.itemsPerPage,
        getComputedOrderBy(this.state.orderByCustomersDT, this.state.orderCustomersDT),
        this.state.CustomerNameNo,
        this.state.deliveryTerms
      )
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  fetchDeliveryOptions = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getDeliveryOptions(getComputedOrderBy(this.state.orderByDT, this.state.orderDT))
      .then(() => {
        this.props.updateLoader({globalLoading: false});
      });
  };

  handleRequestSortCustomersDT = (event, property) => {
    this.setState(
      prepareSortingState(
        this.state.orderByCustomersDT,
        this.state.orderCustomersDT,
        property,
        'CustomersDT'
      ),
      () => this.fetchCustomersDeliveryOptions()
    );
  };

  handleRequestSortDT = (event, property) => {
    this.setState(
      prepareSortingState(this.state.orderByDT, this.state.orderDT, property, 'DT'),
      () => this.fetchDeliveryOptions()
    );
  };

  handleSelectCustomersAllClick = (event) => {
    if (event.target.checked) {
      this.setState({selected: this.props.customersDeliveryOptions.map((item) => item.id)});
      return;
    }
    this.setState({selected: []});
  };

  handleClick = (event, id) => {
    this.setState({selected: selectConcat(id, this.state.selected)});
  };

  handleChangePage = (event, page, value) => {
    this.setState({page, value}, () => this.fetchCustomersDeliveryOptions());
  };

  handleChangeRowsPerPage = (event) => {
    this.props.updateGlobalSettings({itemsPerPage: event.target.value}).then(() => {
      this.setState({page: 0}, () => this.fetchCustomersDeliveryOptions());
    });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  filterCustomersDeliveryTerms = (values) => {
    this.setState(
      {
        CustomerNameNo: values.CustomerNameNo,
        deliveryTerms: values.deliveryOptionId,
      },
      () => this.fetchCustomersDeliveryOptions()
    );
  };

  handleResetCustomersDeliveryTermsFiltering = () => {
    this.setState(
      {
        CustomerNameNo: null,
        deliveryTerms: 'all',
      },
      () => this.fetchCustomersDeliveryOptions()
    );
  };

  getOptionsFilter = () => {
    const data = this.props.deliveryTerms.data;
    const listOptions = [];
    const objectOption = {};
    const objectOption2 = {};

    objectOption.value = 'all';
    objectOption.label = <Trans>All delivery conditions</Trans>;
    listOptions.push(objectOption);

    objectOption2.value = 'none';
    objectOption2.label = <Trans>Without delivery conditions</Trans>;
    listOptions.push(objectOption2);

    data.forEach((obj) => {
      const objectOption = {};
      objectOption.value = obj.id;
      objectOption.label = `${obj.name && obj.name} - ${obj.description && obj.description}`;
      listOptions.push(objectOption);
    });
    return listOptions;
  };

  getOptions = () => {
    const data = this.props.deliveryTerms.data;
    const listOptions = [];

    data.forEach((obj) => {
      const objectOption = {};
      objectOption.value = obj.id;
      objectOption.label = `${obj.name && obj.name} - ${obj.description && obj.description}`;
      listOptions.push(objectOption);
    });

    return listOptions;
  };

  getDeliveryFilterInit = () => {
    if (this.props.deliveryTerms.loading === false) {
      const storedValue1 = this.props.CustomerDTListFilters.deliveryOptionId;
      const storedValue2 = this.props.CustomerDTListFilters.CustomerNameNo;
      const terms = this.props.deliveryTerms.data;
      if (
        storedValue1 === 'none' ||
        storedValue1 === 'all' ||
        terms.some((e) => e.id === parseInt(storedValue1))
      ) {
        return {
          deliveryOptionId: storedValue1,
          CustomerNameNo: storedValue2,
        };
      }
      return {
        deliveryOptionId: 'all',
        CustomerNameNo: storedValue2,
      };
    }
    return {};
  };

  getDeliveryOptionInit = () => {
    const data = this.props.deliveryTerms.data;
    const initValues = {};
    if (data.length) {
      initValues.term = data[0].id;
    }
    return initValues;
  };

  handleAddDeliveryOptionMembership = (selected) => {
    const listDeliveryOptions = [];
    listDeliveryOptions.push(this.props.deliveryTerm);
    this.props.updateLoader({globalLoading: true});
    return this.props
      .addCustomerDeliveryOptions(selected, listDeliveryOptions, false, false)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Delivery term was successfully added.</Trans>);
        this.setState(
          {
            selected: [],
          },
          () => this.fetchCustomersDeliveryOptions()
        );
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleDeleteDeliveryOptionMembership = (selectedCustomersIds, selectedDTIds) => {
    const listDeliveryOptions = [];
    listDeliveryOptions.push(selectedDTIds);
    this.props.updateLoader({globalLoading: true});
    return this.props
      .addCustomerDeliveryOptions(selectedCustomersIds, listDeliveryOptions, false, true)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Delivery term was successfully removed.</Trans>);
        this.setState(
          {
            selected: [],
          },
          () => this.fetchCustomersDeliveryOptions()
        );
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleExportCustomersWithDT = (ids) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .bulkExportDeliveryOptionsMembership(ids)
      .then((response) => {
        download(response.data, 'customers', 'text/csv');
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Successfully downloaded.</Trans>);
        this.setState({
          selected: [],
        });
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  exportFilteredCustomers = () => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .getCustomersDeliveryOptionsMembershipExport(
        this.props.filterCustomerNameNo,
        this.props.filterDeliveryTerm,
        'csv'
      )
      .then((response) => {
        download(response.data, 'customers', 'text/csv');
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Successfully downloaded.</Trans>);
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleTabChange = (event, tabValue) => {
    this.setState({tabValue});
  };

  handleTabChangeIndex = (index) => {
    this.setState({tabValue: index});
  };

  handleOpenInsertDTModal = () => {
    this.setState({openInsert: true});
  };

  handleCloseInsertDTModal = () => {
    this.setState({openInsert: false});
  };

  handleOpenEditDTModal = (deliveryTerm) => {
    this.setState({
      openEdit: true,
      currentDT: deliveryTerm,
    });
  };

  handleCloseEditDTModal = () => {
    this.setState({
      openEdit: false,
      currentDT: null,
    });
  };

  insertNewDeliveryOption = (values) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .insertDeliveryOption(values)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Delivery term was successfully created.</Trans>);
        this.handleCloseInsertDTModal();
        this.fetchDeliveryOptions();
      })
      .catch((error) => {
        this.handleCloseInsertDTModal();
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error);
      });
  };

  updateDeliveryOption = (values) => {
    let id = this.state.currentDT.id;
    this.props.updateLoader({globalLoading: true});
    return this.props
      .editDeliveryOption(id, values)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Delivery term was successfully edited.</Trans>);
        this.handleCloseEditDTModal();
        this.fetchDeliveryOptions();
      })
      .catch((error) => {
        this.handleCloseEditDTModal();
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error);
      });
  };

  warningDeleteDeliveryOption = (name, id) => {
    this.setState({
      alert: (
        <WarningAlert
          title={<Trans>Are you sure?</Trans>}
          confirmCallback={() => this.confirmDeleteDeliveryOption(name, id)}
          closeCallback={() => this.hideAlert()}
          confirmBtnText={<Trans>Yes, delete!</Trans>}
          showCancel={true}
        >
          <Trans>You will delete delivery term {name}!</Trans>
        </WarningAlert>
      ),
    });
  };

  confirmDeleteDeliveryOption = (name, id) => {
    this.props.updateLoader({globalLoading: true});
    return this.props
      .deleteDeliveryOption(id)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(
          <Trans>Delivery term: {name} was successfully deleted.</Trans>
        );
        this.hideAlert();
        this.fetchDeliveryOptions();
      })
      .catch((error) => {
        this.hideAlert();
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error);
      });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  render() {
    const {classes, theme} = this.props;
    const {selected} = this.state;

    return (
      <GridContainer>
        {this.state.alert}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="blue" icon>
              <CardIcon color="blue">
                <LocalShipping />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <Trans>Delivery terms</Trans>
              </h4>
              <div className={classes.tabBar}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab label={<Trans>Delivery terms of customers</Trans>} />
                    <Tab label={<Trans>Setting delivery terms</Trans>} />
                  </Tabs>
                </AppBar>
              </div>
            </CardHeader>
            <CardBody>
              <SwipeableView index={this.state.tabValue} onChangeIndex={this.handleTabChangeIndex}>
                <TabContainer dir={theme.direction}>
                  <Card chart className={classes.filterCard}>
                    <CardBody>
                      <FilterCustomersDTMembershipForm
                        form="filterCustomersDTMembershipForm"
                        initialValues={this.getDeliveryFilterInit()}
                        // deliveryOptions={this.getOptionsFilter()}
                        onSubmit={this.filterCustomersDeliveryTerms}
                        handleReset={this.handleResetCustomersDeliveryTermsFiltering}
                        // handleExportFiltered={() => this.exportFilteredCustomers()}
                      />
                    </CardBody>
                  </Card>
                  <CustomersDeliveryOptionsTableToolbar
                    numSelected={selected.length}
                    handleAddDeliveryTerms={() => this.handleAddDeliveryOptionMembership(selected)}
                    handleDeleteDeliveryTerms={() =>
                      this.handleDeleteDeliveryOptionMembership(selected, this.props.deliveryTerm)
                    }
                    // handleExport={() => this.handleExportCustomersWithDT(selected)}
                    deliveryOptions={this.getOptions()}
                    initDTValues={this.getDeliveryOptionInit()}
                  />
                  <div className={classes.tableWrapper}>
                    <Can I="read" a="DeliveryOptionMembership">
                      <Table className={classes.table} aria-labelledby="tableTitle">
                        <CustomTableHead
                          numSelected={selected.length}
                          order={this.state.orderCustomersDT}
                          orderBy={this.state.orderByCustomersDT}
                          onSelectAllClick={this.handleSelectCustomersAllClick}
                          onRequestSort={this.handleRequestSortCustomersDT}
                          rowCount={this.props.customersDeliveryOptions.length}
                          rows={rows1}
                        />
                        <TableBody>
                          {this.props.customersDeliveryOptions.map((customer) => {
                            const isSelected = this.isSelected(customer.id);
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isSelected}
                                tabIndex={-1}
                                key={customer.id}
                                selected={isSelected}
                              >
                                <TableCell padding="checkbox" classes={{root: classes.cell}}>
                                  <MuiThemeProvider theme={tableSelectTheme}>
                                    <Checkbox
                                      color="primary"
                                      checked={isSelected}
                                      onClick={(event) => this.handleClick(event, customer.id)}
                                    />
                                  </MuiThemeProvider>
                                </TableCell>
                                <TableCell classes={{root: classes.cell}}>
                                  {customer.customer_no}
                                </TableCell>
                                <TableCell classes={{root: classes.cell}}>{`${
                                  customer.name_1 ? customer.name_1 : ''
                                } ${customer.name_2 ? customer.name_2 : ''}`}</TableCell>
                                <TableCell classes={{root: classes.cell}}>
                                  {customer.delivery_options &&
                                    customer.delivery_options.map((option) => (
                                      <RemovableTag
                                        key={option.id}
                                        content={option.name}
                                        title={<Trans>Remove delivery term from customer</Trans>}
                                        onClick={() =>
                                          this.handleDeleteDeliveryOptionMembership(
                                            [customer.id],
                                            option.id
                                          )
                                        }
                                        deletableObject="DeliveryOptionMembership"
                                      />
                                    ))}
                                </TableCell>
                                <TableCell classes={{root: classes.cell}}>
                                  <div className={classes.actions}>
                                    <Can I="update" a="Customers">
                                      <BaseActionsButton
                                        color="success"
                                        component={Link}
                                        to={`/admin/customers/${customer.id}/edit?goTo=2`}
                                      >
                                        <Tooltip
                                          title={<Trans>Edit customer delivery terms</Trans>}
                                        >
                                          <Edit />
                                        </Tooltip>
                                      </BaseActionsButton>
                                    </Can>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Can>
                  </div>
                  <CustomTablePagination
                    rowsPerPageOptions={this.props.globalSettings.rowsPerPageOptions}
                    count={this.props.total}
                    rowsPerPage={this.props.globalSettings.itemsPerPage}
                    page={this.state.page}
                    changePage={this.handleChangePage}
                    changeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TabContainer>
                <TabContainer dir={theme.direction}>
                  <SettingsDeliveryOptionsTableToolbar
                    handlePDeliveryTermInsert={() => this.handleOpenInsertDTModal()}
                  />
                  <InsertDeliveryOptionsDialog
                    open={this.state.openInsert}
                    handleClose={this.handleCloseInsertDTModal}
                    onSubmit={(values) => this.insertNewDeliveryOption(values)}
                  />
                  <EditDeliveryOptionsDialog
                    open={this.state.openEdit}
                    handleClose={this.handleCloseEditDTModal}
                    initValues={this.state.currentDT}
                    onSubmit={(values) => this.updateDeliveryOption(values)}
                  />
                  <div className={classes.tableWrapper}>
                    <Can I="read" a="DeliveryOptions">
                      <Table className={classes.table} aria-labelledby="tableTitle">
                        <SimpleTableHead
                          order={this.state.orderDT}
                          orderBy={this.state.orderByDT}
                          onRequestSort={this.handleRequestSortDT}
                          rows={rows2}
                        />
                        <TableBody>
                          {this.props.deliveryTerms.data.map((item) => {
                            const {id, name, description, price, currency, customer_usages} = item;

                            return (
                              <TableRow hover tabIndex={-1} key={id}>
                                <TableCell classes={{root: classes.cell}}>{name}</TableCell>
                                <TableCell classes={{root: classes.cell}}>{description}</TableCell>
                                <TableCell classes={{root: classes.cell}}>{price}</TableCell>
                                <TableCell classes={{root: classes.cell}}>{currency}</TableCell>
                                <TableCell classes={{root: classes.cell}}>
                                  {customer_usages}
                                </TableCell>
                                <TableCell classes={{root: classes.cell}}>
                                  <div className={classes.actions}>
                                    <Can I="update" a="DeliveryOptions">
                                      <BaseActionsButton
                                        color="success"
                                        onClick={() => this.handleOpenEditDTModal(item)}
                                      >
                                        <Tooltip title={<Trans>Edit delivery terms</Trans>}>
                                          <Edit />
                                        </Tooltip>
                                      </BaseActionsButton>
                                    </Can>
                                    <Can I="delete" a="DeliveryOptions">
                                      <BaseActionsButton
                                        color="danger"
                                        onClick={() => this.warningDeleteDeliveryOption(name, id)}
                                      >
                                        <Tooltip title={<Trans>Delete delivery terms</Trans>}>
                                          <Delete />
                                        </Tooltip>
                                      </BaseActionsButton>
                                    </Can>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Can>
                  </div>
                </TabContainer>
              </SwipeableView>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const selector = formValueSelector('customersDeliveryTermsForm');
const selector2 = formValueSelector('filterCustomersDTMembershipForm');

export default compose(
  withStyles(deliveryOptionsPageStyle, {withTheme: true}),
  connect((store) => {
    return {
      CustomerDTListFilters: store.AppFilterStates.customersDeliveryOptionsListFilter,
      deliveryTerms: store.DeliveryOptions,
      customersDeliveryOptions: store.CustomersDeliveryOptions.data,
      total: store.CustomersDeliveryOptions.total,
      globalSettings: store.GlobalSettings,
      deliveryTerm: selector(store, 'term'),
      filterCustomerNameNo: selector2(store, 'customerNameNo'),
      filterDeliveryTerm: selector2(store, 'delivery_terms'),
      filterValues: getFormValues('filterCustomersDTMembershipForm')(store),
    };
  }, mapDispatchToProps)
)(DeliveryOptionsPage);
