import {filterCustomerFormStyle} from '_assets/projectSpecific/jss/components/Forms/filterCustomerFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormSelectField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Replay, Search} from '@material-ui/icons';

const FilterCustomersInDiscountGroupsForm = ({
  classes,
  handleSubmit,
  pristine,
  submitting,
  form,
  handleReset,
  discountGroupsOptions,
  loading,
  reset,
}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.flex}>
      <GridContainer className={classes.cartFormFields}>
        <GridItem xs={12} md={3}>
          <Field
            name="discountGroupId"
            label={<Trans>Discount group</Trans>}
            id="discountGroupId"
            type="select"
            component={FormSelectField}
            options={discountGroupsOptions}
            clearButton={true}
            reduxName="discount_group"
            formName={form}
            disabled={loading}
          />
        </GridItem>
        <GridItem xs={12} md={9}>
          <div className={classes.cartButtons}>
            <Tooltip title={<Trans>Find</Trans>}>
              <Button color="primary" type="submit" disabled={loading}>
                <Search />
              </Button>
            </Tooltip>
            <Tooltip title={<Trans>Reset filter</Trans>}>
              <Button
                onClick={() => {
                  reset();
                  handleReset();
                }}
                color="primary"
                disabled={loading}
              >
                <Replay />
              </Button>
            </Tooltip>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default compose(
  withStyles(filterCustomerFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(FilterCustomersInDiscountGroupsForm);
