import {horizontalStepperStyle} from '_assets/global/jss/components/Steppers/horizontalStepperStyle';
import Button from '_components/global/Buttons/Button';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import cx from 'classnames';
import React from 'react';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import {withStyles} from '@material-ui/core/styles';

const HorizontalStepper = ({
  classes,
  steps,
  getStepContent,
  setActiveStep,
  nextAction,
  finishAction,
  activeStep,
  canGoNext,
  canFinish,
}) => {
  const handleNext = () => {
    nextAction();
    setActiveStep(activeStep + 1);
  };

  const handleFinish = () => {
    nextAction(true);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className={classes.stepper}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.step}>
        <GridItem xs={12}>{getStepContent(activeStep)}</GridItem>
      </div>
      <GridContainer justify="center">
        <div className={classes.stepperBtn}>
          <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
            Back
          </Button>

          {activeStep !== steps.length - 1 && (
            <Button
              onClick={handleNext}
              className={cx(classes.button, classes.blue)}
              disabled={!canGoNext()}
            >
              Next
            </Button>
          )}
          {canFinish.includes(activeStep) && (
            <Button
              onClick={handleFinish}
              className={cx(classes.button, classes.blue)}
              disabled={!canGoNext()}
              type="submit"
            >
              Finish
            </Button>
          )}
        </div>
      </GridContainer>
    </div>
  );
};

export default withStyles(horizontalStepperStyle)(HorizontalStepper);
