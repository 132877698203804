const selectStyle = (theme) => ({
  formControl: {
    margin: '0px auto',
    minWidth: '220px',
    width: '90%',
    padding: '5px',
    '&MuiInput': {
      marginTop: '10px',
    },
  },
  inputLabel: {
    fontSize: '14px',
    margin: '5px 0px 0px 5px',
  },
});

export default selectStyle;
