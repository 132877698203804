import Dialog from '_components/global/Dialogs/Dialog';
import AccountForm from '_components/projectSpecific/Forms/AccountForm';
import React from 'react';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {Trans} from '@lingui/macro';

const EditAccountDialog = (props) => {
  const {handleClose, open, onSubmit, initValues, branchesOptions, roleOptions} = props;

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={<Trans>Edit user account: {initValues ? initValues.name : ''}</Trans>}
      content={
        <AccountForm
          form="editAccount"
          branchesOptions={branchesOptions}
          roleOptions={roleOptions}
          initialValues={initValues}
          submitName={<Trans>Save changes</Trans>}
          onSubmit={onSubmit}
          authType={props.authType && props.authType}
        />
      }
    />
  );
};
const selector = formValueSelector('editAccount');

export default connect((store) => {
  return {
    authType: selector(store, 'auth_type'),
  };
}, null)(EditAccountDialog);
