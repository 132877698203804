import {blueColor, cardTitle, grayColor} from '_assets/global/jss/styleHelpers';

export const wholesalerAvailabilityPageStyle = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
  },
  title: {
    padding: '5px 15px',
    fontSize: '16px',
    borderBottom: '2px solid ' + blueColor[6],
    margin: '25px 0 15px 0',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: blueColor[6],
  },
  cell: {
    padding: '0px 5px',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
  minimalButtonLength: {
    boxSizing: 'border-box',
    minWidth: 85,
  },
  addButton: {
    width: '100%',
    border: '2px dashed ' + grayColor[6],
    opacity: 0.6,
    color: grayColor[6],
    textAlign: 'center',
    cursor: 'pointer',
    padding: 5,
    margin: '15px 0',
    fontWeight: 700,
    borderRadius: 3,
    transition: 'opacity 200ms',

    '&:hover': {
      transition: 'opacity 200ms',
      opacity: 0.8,
    },
  },
  submitChanges: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableFormField: {
    margin: '4px auto',
  },
  activeIcon: {
    fontSize: 10,
    color: '#76ff03',
  },
  nonActiveIcon: {
    fontSize: 10,
    color: '#f50057',
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  downloadTemplateLink: {
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  dividerTop: {
    marginTop: '6px',
    paddingTop: '4px',
    borderTop: '1px solid rgba(0, 0, 0, 0.87)',
    fontSize: 'x-small',
    fontWeight: 'bold',
  },
});
