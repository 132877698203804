import {
  detailCartFormStyle,
  tableHeadTheme,
} from '_assets/projectSpecific/jss/components/Forms/detailCartFormStyle';
import Button from '_components/global/Buttons/Button';
import SimpleTableHead from '_components/global/Tables/SimpleTableHead';
import AdditionalCartItemRow from '_components/projectSpecific/Tables/AdditionalCartItemRow';
import CartItemRow from '_components/projectSpecific/Tables/CartItemRow';
import {Can} from '_helpers/global/AuthorizedAbilityContext';
import React from 'react';
import compose from 'recompose/compose';
import {reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import {MuiThemeProvider} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';

const DetailCartForm = (props) => {
  const {
    handleSubmit,
    submitting,
    handleDeliver,
    handleDeliverEmail,
    handleSendOrderDialogOpen,
    handleClose,
  } = props;
  const {form, items, rows1, classes, condition, deliveryOptions} = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <MuiThemeProvider theme={tableHeadTheme}>
            <SimpleTableHead rowCount={items?.length} rows={rows1} />
          </MuiThemeProvider>
          <TableBody>
            {items?.map((item, index) => {
              return item.additional_item !== null ? (
                <>
                  <CartItemRow
                    cartItem={item}
                    cartIndex={index}
                    deliveryOptions={deliveryOptions}
                  />
                  <AdditionalCartItemRow cartItem={item.additional_item} cartIndex={index} />
                </>
              ) : (
                <CartItemRow
                  key={index}
                  cartItem={item}
                  cartIndex={index}
                  deliveryOptions={deliveryOptions}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.saveBtn}>
        <Button color="danger" onClick={handleClose}>
          <Trans>Close</Trans>
        </Button>
        {condition && (
          <>
            <Can I="update" a="Carts">
              <Button type="submit" color="primary" disabled={submitting}>
                <Trans>Save</Trans>
              </Button>
            </Can>
            <Can I="send" a="Carts">
              <Tooltip title={<Trans>Send order to RHIAG</Trans>}>
                <Button color="primary" onClick={handleSendOrderDialogOpen}>
                  <Trans>Send order to RHIAG</Trans>
                </Button>
              </Tooltip>
            </Can>
            <Can I="deliver" a="Carts">
              <Tooltip title={<Trans>Delivery</Trans>}>
                <Button color="primary" onClick={handleDeliver}>
                  <Trans>Delivery</Trans>
                </Button>
              </Tooltip>
              <Tooltip title={<Trans>Delivery and send E-mail</Trans>}>
                <Button color="primary" onClick={handleDeliverEmail}>
                  <Trans>Delivery & E-mail</Trans>
                </Button>
              </Tooltip>
            </Can>
          </>
        )}
      </div>
    </form>
  );
};

export default compose(
  withStyles(detailCartFormStyle),
  reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })
)(DetailCartForm);
