import {updateLoader} from '_actions/global/loaderActions';
import {editCustomer, getCustomer} from '_actions/projectSpecific/customersActions';
import {
  addCustomerDeliveryOptions,
  getDeliveryOptions,
} from '_actions/projectSpecific/deliveryOptionsActions';
import {getDiscountGroups} from '_actions/projectSpecific/discountGroupsActions';
import {
  addCustomerAccessiblePermissions,
  addCustomerPermissions,
  getPermissions,
} from '_actions/projectSpecific/permissionsActions';
import {editUser} from '_actions/projectSpecific/usersActions';
import {editCustomerPageStyle} from '_assets/projectSpecific/jss/views/editCustomerPageStyle';
import Button from '_components/global/Buttons/Button';
import Card from '_components/global/Card/Card.jsx';
import CardBody from '_components/global/Card/CardBody.jsx';
import CardHeader from '_components/global/Card/CardHeader.jsx';
import CardIcon from '_components/global/Card/CardIcon.jsx';
import GridContainer from '_components/global/Grid/GridContainer.jsx';
import GridItem from '_components/global/Grid/GridItem.jsx';
import SwipeableView from '_components/global/Tabs/SwipeableViews';
import TabContainer from '_components/global/Tabs/TabContainer';
import CustomerForm from '_components/projectSpecific/Forms/CustomerForm';
import DeliveryOptionsForm from '_components/projectSpecific/Forms/DeliveryOptionsForm';
import PermissionsForm from '_components/projectSpecific/Forms/PermissionsForm';
import authorizedAbility from '_helpers/global/Authorization';
import {fireErrorToast, fireSuccessToast} from '_helpers/global/functions';
import {browserHistoryAccessor} from 'App/history';
import {diff} from 'deep-object-diff';
import {parse} from 'query-string';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import {formValueSelector} from 'redux-form';
import {Trans} from '@lingui/macro';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {ArrowBack, Edit} from '@material-ui/icons';

const rows = [
  {
    id: '',
    label: '',
    sortable: false,
  },
  {
    id: 'name',
    label: <Trans>Title</Trans>,
    sortable: false,
  },
  {
    d: 'description',
    label: <Trans>Description</Trans>,
    sortable: false,
  },
];

const rows2 = [
  {
    id: 'empty',
    label: '',
    sortable: false,
  },
  {
    id: 'code',
    label: <Trans>Code</Trans>,
    sortable: false,
  },
  {
    id: 'Name',
    label: <Trans>Name</Trans>,
    sortable: false,
  },
];

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCustomer,
      updateLoader,
      editCustomer,
      editUser,
      getDeliveryOptions,
      getPermissions,
      addCustomerPermissions,
      addCustomerAccessiblePermissions,
      addCustomerDeliveryOptions,
      getDiscountGroups,
    },
    dispatch
  );
};

class EditCustomerPage extends Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      initDeliveryOptions: false,
      initPermissions: false,
    };
    if (authorizedAbility.cannot('update', 'Customers')) {
      browserHistoryAccessor.push('/admin/404');
    }

    const parsed = parse(window.location.search);
    if (parsed.goTo === '2') {
      this.state = {
        ...this.state,
        tabValue: 2,
        initDeliveryOptions: true,
      };
    }
  }

  componentDidMount = () => {
    this.fetchCustomer();
    this.fetchDiscountGroups();
    const parsed = parse(window.location.search);
    if (parsed.goTo === '2') {
      this.fetchDeliveryOptions();
    }
  };

  fetchCustomer = () => {
    const {id} = this.props.match.params;
    if (id !== 'new') {
      this.props.getCustomer(id);
    }
  };

  fetchDeliveryOptions = () => {
    return this.props.getDeliveryOptions(0, 0);
  };

  fetchPermission = () => {
    return this.props.getPermissions(0, 0);
  };

  fetchDiscountGroups = () => {
    return this.props.getDiscountGroups(0, 0);
  };

  getDiscountGroups = (data) => {
    return data.map((item) => {
      return {value: item.id, label: item.name};
    });
  };

  editCustomer = (values, customer) => {
    delete values.repeatPassword;
    const editedCustomer = diff(customer, values);
    if (editedCustomer.discount_group) {
      editedCustomer.discount_group = `/api/v1/discount-groups/${editedCustomer.discount_group}/`;
    }
    if (editedCustomer.main_user_auth_type === true) {
      editedCustomer.main_user_auth_type = 'oracle';
    } else {
      editedCustomer.main_user_auth_type = 'local';
    }
    this.props.updateLoader({globalLoading: true});
    return this.props
      .editCustomer(customer.id, editedCustomer)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(
          <Trans>
            Customer: {values.name_1} {values.name_2} was successfully edited.
          </Trans>
        );
        browserHistoryAccessor.push('/admin/customers');
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleTabChange = (event, tabValue) => {
    this.setState({tabValue});
  };

  handleDeliveryOptionsTabClick = (event) => {
    event.preventDefault();
    !this.state.initDeliveryOptions &&
      this.setState(
        {
          initDeliveryOptions: true,
        },
        () => {
          this.fetchDeliveryOptions();
        }
      );
  };

  handlePermissionsTabClick = (event) => {
    event.preventDefault();
    !this.state.initPermissions &&
      this.setState(
        {
          initPermissions: true,
        },
        () => {
          this.fetchPermission();
        }
      );
  };

  handleTabChangeIndex = (index) => {
    this.setState({tabValue: index});
  };

  getPermissionsInit = (permissions) => {
    const initValues = {};
    initValues.options = {};

    permissions.forEach((item) => {
      initValues.options[item.id] = true;
    });

    return initValues;
  };

  getDeliveryInit = (deliveryTerms) => {
    const initValues = {};
    initValues.options = {};
    deliveryTerms.forEach((item) => {
      initValues.options[item.id] = true;
    });

    return initValues;
  };

  getCustomerInitValues = (customer) => {
    const initValues = {...customer};
    if (customer.main_user_auth_type === 'oracle') {
      initValues.main_user_auth_type = true;
    } else {
      initValues.main_user_auth_type = false;
    }
    if (initValues.discount_group) {
      initValues.discount_group = initValues.discount_group.id;
    }
    return initValues;
  };

  handleCustomerDeliveryTerms = (id, values) => {
    const listCustomersIds = [];
    listCustomersIds.push(id);
    const listDeliveryTerms = Object.keys(values.options)
      .filter((value) => values.options[value] === true)
      .map((value) => parseInt(value));

    this.props.updateLoader({globalLoading: true});
    return this.props
      .addCustomerDeliveryOptions(listCustomersIds, listDeliveryTerms, true, false)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Delivery terms were added/edited.</Trans>);
        this.fetchCustomer();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handlePermissions = (id, values) => {
    const listIds = [];
    listIds.push(id);
    const listPermissions = Object.keys(values.options)
      .filter((value) => values.options[value] === true)
      .map((value) => parseInt(value));

    this.props.updateLoader({globalLoading: true});
    return this.props
      .addCustomerPermissions(listIds, listPermissions, true, false)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Permissions were added/edited.</Trans>);
        this.fetchCustomer();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  handleAccessiblePermissions = (id, values) => {
    const listIds = [];
    listIds.push(id);
    const listPermissions = Object.keys(values.options)
      .filter((value) => values.options[value] === true)
      .map((value) => parseInt(value));

    this.props.updateLoader({globalLoading: true});
    return this.props
      .addCustomerAccessiblePermissions(listIds, listPermissions, true, false)
      .then(() => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireSuccessToast(<Trans>Accessible permissions were added/edited.</Trans>);
        this.fetchCustomer();
      })
      .catch((error) => {
        this.props.updateLoader({globalLoading: false});
        this.toastId = fireErrorToast(error.errors._error, {autoClose: false});
      });
  };

  prepareTabs = () => {
    const tabs = [<Tab key="edit" label={<Trans>Edit customer</Trans>} />];

    if (authorizedAbility.can('update', 'CustomerPermissionMembership')) {
      tabs.push(
        <Tab
          key="permissions"
          label={<Trans>Permissions</Trans>}
          onClick={this.handlePermissionsTabClick}
        />
      );
    }
    if (authorizedAbility.can('update', 'DeliveryOptions')) {
      tabs.push(
        <Tab
          key="deliveryTerms"
          label={<Trans>Delivery terms</Trans>}
          onClick={this.handleDeliveryOptionsTabClick}
        />
      );
    }
    if (
      (this.props.customer?.main_user_groups[0]?.name === 'reseller' ||
        this.props.customer?.main_user_groups[0]?.name === 'reseller_with_availability') &&
      authorizedAbility.can('update', 'UserPermissionMembership')
    ) {
      tabs.push(
        <Tab
          key="accessiblePermissions"
          label={<Trans>Accessible permissions</Trans>}
          onClick={this.handlePermissionsTabClick}
        />
      );
    }
    return tabs;
  };

  prepareTabContainers = (theme, customer) => {
    const tabContainers = [];
    if (
      this.props.permissions.length > 0 &&
      authorizedAbility.can('update', 'CustomerPermissionMembership')
    ) {
      tabContainers.push(
        <TabContainer key={'editCustomerPermissions'} dir={theme.direction}>
          <PermissionsForm
            initialValues={this.getPermissionsInit(customer.main_user_permissions)}
            form="editCustomerPermissions"
            rows2={rows2}
            options={this.props.permissions}
            onSubmit={(values) => {
              return this.handlePermissions(customer.id, values);
            }}
          />
        </TabContainer>
      );
    }
    if (
      this.props.permissions.length > 0 &&
      (this.props.customer?.main_user_groups[0]?.name === 'reseller' ||
        this.props.customer?.main_user_groups[0]?.name === 'reseller_with_availability') &&
      authorizedAbility.can('update', 'UserPermissionMembership')
    ) {
      tabContainers.push(
        <TabContainer key={'editCustomerAccessiblePermissions'} dir={theme.direction}>
          <PermissionsForm
            initialValues={this.getPermissionsInit(customer.accessible_permissions)}
            form="editCustomerAccessiblePermissions"
            rows2={rows2}
            options={this.props.permissions}
            onSubmit={(values) => {
              return this.handleAccessiblePermissions(customer.id, values);
            }}
          />
        </TabContainer>
      );
    }
    if (
      this.props.deliveryOptions.length > 0 &&
      authorizedAbility.can('update', 'DeliveryOptions')
    ) {
      tabContainers.push(
        <TabContainer key={'editCustomerDeliveryTerms'} dir={theme.direction}>
          <DeliveryOptionsForm
            initialValues={this.getDeliveryInit(customer.delivery_options)}
            form="editCustomerDeliveryTerms"
            rows={rows}
            options={this.props.deliveryOptions}
            onSubmit={(values) => {
              return this.handleCustomerDeliveryTerms(customer.id, values);
            }}
          />
        </TabContainer>
      );
    }

    return tabContainers;
  };

  render() {
    const {classes, theme} = this.props;
    const customer = this.props.customer;

    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          {!this.props.isCustomerLoading && (
            <Card>
              <CardHeader color="blue" icon className={classes.root}>
                <CardIcon color="blue">
                  <Edit />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <Trans>Edit</Trans> <b>{customer.name}</b>
                </h4>
                <div className={classes.backBtn}>
                  <Tooltip title={<Trans>Back to list</Trans>}>
                    <Button
                      className={classes.btnIcon}
                      onClick={() => browserHistoryAccessor.goBack()}
                    >
                      <ArrowBack />
                    </Button>
                  </Tooltip>
                </div>
                <div className={classes.tabBar}>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={this.state.tabValue}
                      onChange={this.handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                    >
                      {this.prepareTabs()}
                    </Tabs>
                  </AppBar>
                </div>
              </CardHeader>
              <CardBody>
                <SwipeableView
                  index={this.state.tabValue}
                  onChangeIndex={this.handleTabChangeIndex}
                >
                  <TabContainer dir={theme.direction}>
                    <div className={classes.step}>
                      <CustomerForm
                        customerNo={customer.customer_no}
                        customerNo2={customer.customer_no_2}
                        initialValues={this.getCustomerInitValues(customer)}
                        form="editCustomer"
                        onSubmit={(values) => {
                          return this.editCustomer(values, customer);
                        }}
                        authType={this.props.authType && this.props.authType}
                        password={this.props.password && this.props.password}
                        discountGroups={this.getDiscountGroups(this.props.discountGroups)}
                      />
                    </div>
                  </TabContainer>

                  {this.prepareTabContainers(theme, customer)}
                </SwipeableView>
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const selector = formValueSelector('editCustomer');

export default compose(
  withStyles(editCustomerPageStyle, {withTheme: true}),
  connect((store) => {
    return {
      customer: store.Customer.data,
      isCustomerLoading: store.Customer.loading,
      deliveryOptions: store.DeliveryOptions.data,
      permissions: store.Permissions.data,
      authType: selector(store, 'main_user_auth_type'),
      password: selector(store, 'main_user_password'),
      discountGroups: store.DiscountGroups.data,
    };
  }, mapDispatchToProps)
)(EditCustomerPage);
