import {filterLostSaleReasonFormStyle} from '_assets/projectSpecific/jss/components/Forms/filterLostSaleReasonFormStyle';
import Button from '_components/global/Buttons/Button';
import {FormDateField, FormTextField} from '_components/global/Form/FormFields';
import GridContainer from '_components/global/Grid/GridContainer';
import GridItem from '_components/global/Grid/GridItem';
import React from 'react';
import compose from 'recompose/compose';
import {Field, reduxForm} from 'redux-form';
import {Trans} from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {Replay, Search} from '@material-ui/icons';

const FilterLastLoginForm = ({
  classes,
  handleSubmit,
  pristine,
  submitting,
  form,
  handleReset,
  reset,
}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.flex}>
      <GridContainer className={classes.formFields}>
        <GridItem xs={12} md={6} xl={4}>
          <Field
            name="username"
            label={<Trans>Username</Trans>}
            id="username"
            component={FormTextField}
            clearButton={true}
            reduxName="username"
            formName={form}
          />
        </GridItem>
        <GridItem xs={12} md={6} xl={4}>
          <Field
            name="createdAt"
            dateLabel={<Trans>Last login</Trans>}
            id="createdAt"
            component={FormDateField}
            reduxName="createdAt"
            formName={form}
            clearButton={true}
          />
        </GridItem>
      </GridContainer>
      <GridItem xs={12} md={3} xl={4}>
        <div className={classes.formButtons}>
          <Tooltip title={<Trans>Find</Trans>}>
            <Button color="primary" type="submit">
              <Search />
            </Button>
          </Tooltip>
          <Tooltip title={<Trans>Reset filter</Trans>}>
            <Button
              onClick={() => {
                reset();
                handleReset();
              }}
              color="primary"
            >
              <Replay />
            </Button>
          </Tooltip>
        </div>
      </GridItem>
    </form>
  );
};

export default compose(
  withStyles(filterLostSaleReasonFormStyle),
  reduxForm({
    enableReinitialize: true,
  })
)(FilterLastLoginForm);
