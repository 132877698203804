import {grayColor} from '_assets/global/jss/styleHelpers';

export const customerDetailTableStyle = (theme) => ({
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflow: 'overlay',
    paddingBottom: '15px',
    padding: '20px',
    background: grayColor[8],
    boxShadow: '10px 12px 0.5rem rgba(0,0,0,0.3)',
    margin: '20px 0 30px 0',
  },
  cell: {
    padding: '0px 5px',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
  },
});
