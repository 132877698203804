export const filterLostSaleReasonFormStyle = (theme) => ({
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  formFields: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-16px',
  },
});
