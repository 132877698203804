import actionFormsButtonsStyle from '_assets/global/jss/components/Buttons/actionFormsButtonsStyle';
import cx from 'classnames';
import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';

const ActionFormsButtons = ({
  classes,
  disabled,
  reset,
  resetName,
  submitName,
  handleClose,
  closeName,
}) => {
  return (
    <div className={classes.root}>
      {reset ? (
        <Button onClick={reset} className={cx(classes.button, classes.danger)} disabled={disabled}>
          {resetName}
        </Button>
      ) : (
        <Button onClick={handleClose} className={cx(classes.button, classes.danger)}>
          {closeName}
        </Button>
      )}
      <Button className={cx(classes.button, classes.primary)} disabled={disabled} type="submit">
        {submitName}
      </Button>
    </div>
  );
};

export default withStyles(actionFormsButtonsStyle)(ActionFormsButtons);
