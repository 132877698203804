import {cardTitle, grayColor} from '_assets/global/jss/styleHelpers';
import {createMuiTheme} from '@material-ui/core';

export const customerDetailPageStyle = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  activeIcon: {
    fontSize: '10px',
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-20px',
    marginRight: '10px',
  },
  btnIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editProfileButton: {
    marginLeft: '5px',
  },
});

export const tableHeadTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: `${grayColor[0]} !important`,
      },
    },
  },
});

export const iconTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    secondary: {
      main: '#76ff03',
    },
  },
});
