import {createMuiTheme} from '@material-ui/core';

export const filterCartFormStyle = (theme) => ({
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cartFormFields: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cartButton: {
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'end',
      margin: '20px 25px 0 0',
    },
  },
});

export const dateFieldTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiInputBase: {
      root: {
        paddingBottom: '10px',
      },
    },
  },
});
